import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { Link } from 'react-router-dom'
import './Home.css'
import bgPattern from '../../assets/images/bg.jpg'
import { useDarkModeContext } from '../../DarkModeContext'

import { Helmet } from 'react-helmet'
import {
	FaDownload,
	FaGoogleDrive,
	FaPlus,
	FaTools,
	FaTrash,
	FaUserFriends,
} from 'react-icons/fa'
import {
	FaAngleRight,
	FaBook,
	FaBox,
	FaComputer,
	FaGamepad,
	FaLaptop,
	FaMessage,
	FaMeta,
	FaPencil,
	FaUpload,
	FaUser,
} from 'react-icons/fa6'
import { BsBoxSeamFill, BsHeadsetVr } from 'react-icons/bs'
import { IoBag } from 'react-icons/io5'
import { MdGamepad } from 'react-icons/md'

const DashboardAdmin = () => {
	const [data_VRGames, setData_VRGames] = useState({})
	const [data_Products, setData_Products] = useState({})
	const language = localStorage.getItem('language') || 'id'
	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')
	const { isDarkMode } = useDarkModeContext()

	const [standaloneGames, setStandaloneGames] = useState([])
	const [pcvrGames, setPcvrGames] = useState([])
	const [products, setProducts] = useState([])
	const [standaloneUsers, setStandaloneUsers] = useState([])
	const [pcvrUsers, setPcvrUsers] = useState([])
	const [visitors, setVisitors] = useState([])
	const [requests, setRequests] = useState([])
	const [orders, setOrders] = useState([{}])

	const translations = {
		en: {
			dashboard: 'Dashboard',
			listVRGames: 'List Games VR',
			listProducts: 'List Products',
			listVRGames: 'List Games VR',
			listProducts: 'List Products',
			listUsers: 'List Users',
			listVisitors: 'List Visitors',
			listRequests: 'List Requests',
			listOrders: 'List Orders',
			uploadCover: 'Upload Cover',
			downloadGameCover: 'Download Game Cover',
			downloadProductCover: 'Download Product Cover',
			uploadToGoogleDrive: 'Upload to Google Drive',
			firmwareQuestList: 'Firmware Quest List',

			descListVRGames: 'List of VR games that have been uploaded by Admin.',
			descListProducts: 'List of products that have been uploaded by Admin.',
			descListUsers: 'List of users who have registered on the website.',
			descListVisitors: 'List of visitors who have visited the website.',
			descListRequests: 'List of game requests that have been sent by users.',
			descListOrders: 'List of orders that have been made by users.',
			descUploadCover: 'Upload a cover image for the game.',
			descDownloadGameCover: 'Download the cover image of the game.',
			descDownloadProductCover: 'Download the cover image of the product.',
			descUploadToGoogleDrive: 'Upload the game to Google Drive.',
			descFirmwareQuestList: 'List of firmware for Oculus Quest.',
		},
		id: {
			dashboard: 'Dasbor',
			listVRGames: 'Daftar Game VR',
			listProducts: 'Daftar Produk',
			listVRGames: 'Daftar Game VR',
			listProducts: 'Daftar Produk',
			listUsers: 'Daftar Pengguna',
			listVisitors: 'Daftar Pengunjung',
			listRequests: 'Daftar Permintaan',
			listOrders: 'Daftar Pesanan',
			uploadCover: 'Unggah Cover',
			downloadGameCover: 'Unduh Cover Game',
			downloadProductCover: 'Unduh Cover Produk',
			uploadToGoogleDrive: 'Unggah ke Google Drive',
			firmwareQuestList: 'Daftar Firmware Quest',

			descListVRGames: 'Daftar game VR yang telah diunggah oleh Admin.',
			descListProducts: 'Daftar produk yang telah diunggah oleh Admin.',
			descListUsers: 'Daftar pengguna yang telah mendaftar di website.',
			descListVisitors: 'Daftar pengunjung yang telah mengunjungi website.',
			descListRequests:
				'Daftar permintaan game yang telah dikirim oleh pengguna.',
			descListOrders: 'Daftar pesanan yang telah dibuat oleh pengguna.',
			descUploadCover: 'Unggah gambar cover untuk game.',
			descDownloadGameCover: 'Unduh gambar cover game.',
			descDownloadProductCover: 'Unduh gambar cover produk.',
			descUploadToGoogleDrive: 'Unggah game ke Google Drive.',
			descFirmwareQuestList: 'Daftar firmware untuk Oculus Quest.',
		},
	}

	useEffect(() => {
		fireDb.ref(`vrGames/standalone`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				setData_VRGames({ ...snapshot.val() })
			} else {
				setData_VRGames({})
			}
		})

		fireDb.ref(`products`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				setData_Products({ ...snapshot.val() })
			} else {
				setData_Products({})
			}
		})

		return () => {
			fireDb.ref(`vrGames/standalone`).off('value')
			setData_VRGames({})
			setData_Products({})
		}
	}, [])

	useEffect(() => {
		fireDb.ref(`vrGames/standalone`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val()).filter(
					(game) => game.gameTitle && game.gameTitle.trim() !== ''
				)
				setStandaloneGames(gamesData)
			} else {
				setStandaloneGames([])
			}
		})

		fireDb.ref(`vrGames/pcvr`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val()).filter(
					(game) => game.gameTitle && game.gameTitle.trim() !== ''
				)
				setPcvrGames(gamesData)
			} else {
				setPcvrGames([])
			}
		})

		fireDb.ref(`products`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const productsData = Object.values(snapshot.val())
				setProducts(productsData)
			} else {
				setProducts([])
			}
		})

		fireDb.ref(`eligibleUsers/standalone`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const usersData = Object.values(snapshot.val())
				setStandaloneUsers(usersData)
			} else {
				setStandaloneUsers([])
			}
		})

		fireDb.ref(`eligibleUsers/pcvr`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const usersData = Object.values(snapshot.val())
				setPcvrUsers(usersData)
			} else {
				setPcvrUsers([])
			}
		})

		fireDb.ref(`visitors`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const visitorsData = Object.values(snapshot.val())
				setVisitors(visitorsData)
			} else {
				setVisitors([])
			}
		})

		fireDb.ref(`requestedVRGames`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const requestsData = Object.values(snapshot.val())
				setRequests(requestsData)
			} else {
				setRequests([])
			}
		})

		fireDb.ref('orders').on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const ordersData = snapshot.val()
				// Mengonversi objek orders menjadi array
				const ordersArray = Object.keys(ordersData)
					.map((userId) => {
						const userOrders = ordersData[userId]
						return Object.keys(userOrders).map((orderId) => {
							// Menambahkan orderId ke dalam data order
							const order = userOrders[orderId]
							order.orderId = orderId
							return order
						})
					})
					.flat() // Menggabungkan array berbagai order menjadi satu array

				// Mendapatkan panjang dari array ordersArray
				const ordersLength = ordersArray.length

				setOrders(ordersArray)
			} else {
				setOrders([])
			}
		})

		return () => {
			fireDb.ref(`vrGames/standalone`).off('value')
			fireDb.ref(`vrGames/pcvr`).off('value')
			fireDb.ref(`products`).off('value')
			fireDb.ref(`eligibleUsers/standalone`).off('value')
			fireDb.ref(`eligibleUsers/pcvr`).off('value')
			fireDb.ref(`visitors`).off('value')
			fireDb.ref(`requestedVRGames`).off('value')
			fireDb.ref(`orders`).off('value')
		}
	})

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 4000) {
				setGridColsVRGamesMenu('grid-cols-10')
			} else if (window.innerWidth >= 3675) {
				setGridColsVRGamesMenu('grid-cols-9')
			} else if (window.innerWidth >= 3275) {
				setGridColsVRGamesMenu('grid-cols-8')
			} else if (window.innerWidth >= 2875) {
				setGridColsVRGamesMenu('grid-cols-7')
			} else if (window.innerWidth >= 2475) {
				setGridColsVRGamesMenu('grid-cols-6')
			} else if (window.innerWidth >= 2075) {
				setGridColsVRGamesMenu('grid-cols-5')
			} else if (window.innerWidth >= 1675) {
				setGridColsVRGamesMenu('grid-cols-4')
			} else if (window.innerWidth >= 1275) {
				setGridColsVRGamesMenu('grid-cols-3')
			} else if (window.innerWidth >= 875) {
				setGridColsVRGamesMenu('grid-cols-2')
			} else {
				setGridColsVRGamesMenu('grid-cols-1')
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const isMobile = window.innerWidth < 1200

	const cardVariants = {
		hidden: { opacity: 0, scale: 0.9 },
		visible: { opacity: 1, scale: 1 },
	}

	return (
		<div
			className={`pt-5 mx-5 pb-5 w-full ${!isMobile && 'pt-[100px]'} min-h-screen`}
		>
			<div>
				<div className={`grid ${gridColsVRGamesMenu} gap-7 w-full h-full`}>
					<Link to="/dba/list-vr-games">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<MdGamepad
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold items-center flex`}
									>
										{translations[language].listVRGames}
										<span className="text-white px-2 py-1 bg-teal-500 text-base font-semibold ml-2 rounded-lg inline-block">
											<BsHeadsetVr size={20} className="inline-block mr-1" />
											{standaloneGames.length} Games
										</span>
										<span className="text-white px-2 py-1 bg-teal-700 text-base font-semibold ml-2 rounded-lg inline-block">
											<FaComputer size={20} className="inline-block mr-1" />
											{pcvrGames.length} Games
										</span>
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descListVRGames}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/list-products">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<BsBoxSeamFill
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].listProducts}
										<span className="text-white px-2 py-1 bg-teal-500 text-base font-semibold ml-2 rounded-lg inline-block">
											{products.length} Products
										</span>
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descListProducts}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/list-users">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaUser
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold flex items-center`}
									>
										{translations[language].listUsers}
										<span className="text-white px-2 py-1 bg-teal-500 text-base font-semibold ml-2 rounded-lg inline-block">
											<BsHeadsetVr size={20} className="inline-block mr-1" />
											{standaloneUsers.length} Users
										</span>
										<span className="text-white px-2 py-1 bg-teal-700 text-base font-semibold ml-2 rounded-lg inline-block">
											<FaComputer size={20} className="inline-block mr-1" />
											{pcvrUsers.length} Users
										</span>
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descListUsers}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/list-requests">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaMessage
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].listRequests}
										<span className="text-white px-2 py-1 bg-teal-500 text-base font-semibold ml-2 rounded-lg inline-block">
											{requests.length} Requests
										</span>
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descListRequests}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/list-orders">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<IoBag
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].listOrders}
										<span className="text-white px-2 py-1 bg-teal-500 text-base font-semibold ml-2 rounded-lg inline-block">
											{orders.length} Orders
										</span>
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descListOrders}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/upload-cover">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaUpload
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].uploadCover}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descUploadCover}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/download-games-cover">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaDownload
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].downloadGameCover}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descDownloadGameCover}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/download-products-cover">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaDownload
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].downloadProductCover}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descDownloadProductCover}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/upload-to-drive">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaGoogleDrive
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].uploadToGoogleDrive}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descUploadToGoogleDrive}
									</span>
								</span>
							</div>
						</div>
					</Link>

					<Link to="/dba/firmware-quest">
						<div
							className={`h-80 ${isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'} rounded-xl border-2 h-full flex flex-col justify-end ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							style={{
								background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
							}}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'}`}
							>
								<FaMeta
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
									className="text-[#0081FB]"
								/>
								<span className="flex flex-col gap-1">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].firmwareQuestList}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descFirmwareQuestList}
									</span>
								</span>
							</div>
						</div>
					</Link>
				</div>
			</div>
			<Helmet>
				<title>Dasbor Admin | HyperTopia</title>
			</Helmet>
			<style>
				{`
          @keyframes scroll {
            0% {
              transform: translateX(250%);
            }
            100% {
              transform: translateX(-250%);
            }
          }

          .animate-scroll-wrapper {
            overflow: hidden;
            display: inline-block;
          }

          .animate-scroll {
            display: inline-block;
            white-space: nowrap;
            animation: scroll 15s linear infinite;
          }

          .search-box {
            width: 100px;
          }
        `}
			</style>
		</div>
	)
}

export default DashboardAdmin
