import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { storage } from '../../firebase'
import { ref, getMetadata } from 'firebase/storage'
import { Link } from 'react-router-dom'
import './Home.css'

import { Helmet } from 'react-helmet'
import {
	FaAngleDown,
	FaAngleUp,
	FaPlus,
	FaSortAlphaDown,
	FaSortAlphaDownAlt,
	FaSortNumericDown,
	FaSortNumericDownAlt,
	FaTrash,
} from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import { FiSearch, FiX } from 'react-icons/fi'
import toast, { Toaster } from 'react-hot-toast'

const ListVRGames_Admin = () => {
	const [dataStandaloneGames, setDataStandaloneGames] = useState({})
	const [dataPCVRGames, setDataPCVRGames] = useState({})

	const [showStandaloneGames, setShowStandaloneGames] = useState(false)
	const [showPCVRGames, setShowPCVRGames] = useState(false)

	const [searchTerm, setSearchTerm] = useState('')
	const [sortBy, setSortBy] = useState('added')
	const [sortOrder, setSortOrder] = useState('desc')
	const [isDarkMode, setIsDarkMode] = useState(false)
	const language = localStorage.getItem('language') || 'id'

	const [isGameImageAvailable, setIsGameImageAvailable] = useState(false)

	const translations = {
		id: {
			searchGames: 'Cari Game',
			sortBy: 'Urutkan: ',
			name: 'Nama',
			newest: 'Terbaru',
			added: 'Ditambahkan',
		},
		en: {
			searchGames: 'Search Games',
			sortBy: 'Sort By: ',
			name: 'Name',
			newest: 'Newest',
			added: 'Added',
		},
	}

	useEffect(() => {
		fireDb.ref(`vrGames/standalone`).once('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = snapshot.val()
				const sortedGamesData = Object.keys(gamesData).sort((a, b) => {
					const timeA = new Date(gamesData[a].timeAdded).getTime()
					const timeB = new Date(gamesData[b].timeAdded).getTime()
					return sortOrder === 'asc' ? timeB - timeA : timeA - timeB
				})
				const sortedDataObject = {}
				sortedGamesData.forEach((gameTitle) => {
					sortedDataObject[gameTitle] = gamesData[gameTitle]
				})
				setDataStandaloneGames(sortedDataObject)
			} else {
				setDataStandaloneGames({})
			}
		})
	}, [sortOrder, dataStandaloneGames])

	useEffect(() => {
		fireDb.ref(`vrGames/pcvr`).once('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = snapshot.val()
				const sortedGamesData = Object.keys(gamesData).sort((a, b) => {
					const timeA = new Date(gamesData[a].timeAdded).getTime()
					const timeB = new Date(gamesData[b].timeAdded).getTime()
					return sortOrder === 'asc' ? timeB - timeA : timeA - timeB
				})
				const sortedDataObject = {}
				sortedGamesData.forEach((gameTitle) => {
					sortedDataObject[gameTitle] = gamesData[gameTitle]
				})
				setDataPCVRGames(sortedDataObject)
			} else {
				setDataPCVRGames({})
			}
		})
	}, [sortOrder, dataPCVRGames])

	const onDeleteStandaloneGame = (gameTitle) => {
		if (window.confirm('Are you sure that you want to delete this game?')) {
			fireDb.ref(`vrGames/standalone/${gameTitle}`).remove((err) => {
				if (err) {
					toast.error(err)
				} else {
					toast.success('Game Deleted Successfully!')
				}
			})
		}
	}

	const onDeletePCVRGame = (gameTitle) => {
		if (window.confirm('Are you sure that you want to delete this game?')) {
			fireDb.ref(`vrGames/pcvr/${gameTitle}`).remove((err) => {
				if (err) {
					toast.error(err)
				} else {
					toast.success('Game Deleted Successfully!')
				}
			})
		}
	}

	const limitStringLength = (str, maxLength) => {
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...'
		}
		return str
	}

	const handleAddStandaloneGamesButton = () => {
		localStorage.setItem('dataTypeAddEdit', 'vrGames')
		window.location.href = '/dba/list-vr-games/standalone/add'
	}

	const handleAddPCVRGamesButton = () => {
		localStorage.setItem('dataTypeAddEdit', 'vrGames')
		window.location.href = '/dba/list-vr-games/pcvr/add'
	}

	const handleUpdateStandaloneGamesButton = (gameTitle) => {
		localStorage.setItem('dataTypeAddEdit', 'vrGames')
		window.location.href = `/dba/list-vr-games/standalone/update/${gameTitle}`
	}

	const handleUpdatePCVRGamesButton = (gameTitle) => {
		localStorage.setItem('dataTypeAddEdit', 'vrGames')
		window.location.href = `/dba/list-vr-games/pcvr/update/${gameTitle}`
	}

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value)
	}

	const handleSearchBarNonActiveClick = () => {
		setSearchTerm('')
	}

	const handleSortByChange = (e) => {
		setSortBy(e.target.value)
	}

	const toggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
	}

	const sortedDataStandaloneGames = Object.keys(dataStandaloneGames).sort(
		(a, b) => {
			if (sortBy === 'name') {
				return sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
			} else if (sortBy === 'added') {
				const timeA = Date.parse(dataStandaloneGames[a].timeAdded)
				const timeB = Date.parse(dataStandaloneGames[b].timeAdded)
				return sortOrder === 'asc' ? timeA - timeB : timeB - timeA
			}
		}
	)

	const filteredDataStandaloneGames = sortedDataStandaloneGames.filter(
		(gameTitle) => {
			const game = dataStandaloneGames[gameTitle]
			return (
				game &&
				game.gameTitle &&
				game.gameTitle.toLowerCase().includes(searchTerm.toLowerCase())
			)
		}
	)

	const sortedDataPCVRGames = Object.keys(dataPCVRGames).sort((a, b) => {
		if (sortBy === 'name') {
			return sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
		} else if (sortBy === 'added') {
			const timeA = Date.parse(dataPCVRGames[a].timeAdded)
			const timeB = Date.parse(dataPCVRGames[b].timeAdded)
			return sortOrder === 'asc' ? timeA - timeB : timeB - timeA
		}
	})

	const filteredDataPCVRGames = sortedDataPCVRGames.filter((gameTitle) => {
		const game = dataPCVRGames[gameTitle]
		return (
			game &&
			game.gameTitle &&
			game.gameTitle.toLowerCase().includes(searchTerm.toLowerCase())
		)
	})

	const handleStandaloneGames = () => {
		setShowStandaloneGames(!showStandaloneGames)
	}

	const handlePCVRGames = () => {
		setShowPCVRGames(!showPCVRGames)
	}

	useEffect(() => {
		const gameTitle = 'BONELAB' // Gantilah "Judul Game" dengan nilai judul game yang sesuai
		const checkGameImageAvailability = async () => {
			try {
				if (dataStandaloneGames[gameTitle].gameTitle) {
					const gameImageRef = ref(
						storage,
						`coverGamesImages/${dataStandaloneGames[gameTitle].gameTitle}`
					)
					const gameImageMetadata = await getMetadata(gameImageRef)
					setIsGameImageAvailable(!!gameImageMetadata)
				} else {
					setIsGameImageAvailable(false)
				}
			} catch (error) {
				console.error('Error checking game image availability:', error)
				setIsGameImageAvailable(false)
			}
		}

		checkGameImageAvailability()
	}, [dataStandaloneGames])

	const isMobile = window.innerWidth < 1200

	return (
		<div className={`px-6 mb-20 pt-6 w-full ${!isMobile && 'pt-[100px]'}`}>
			<Toaster />
			<div>
				<button
					onClick={handleStandaloneGames}
					className={`w-full rounded-xl py-2 mb-2 bg-[#0081FB] text-white flex flex-row justify-between items-center px-4`}
				>
					<span>Standalone Games</span>
					{showStandaloneGames ? <FaAngleUp /> : <FaAngleDown />}
				</button>
				<button
					onClick={handleAddStandaloneGamesButton}
					className={`${showStandaloneGames ? '' : 'hidden'} text-center w-full py-2 mb-5 rounded-lg text-white font-semibold bg-[#0081FB] hover:bg-[#4083c2] transition-all duration-300`}
				>
					<FaPlus className="inline-block mr-2" />
					<span>Add Games</span>
				</button>
				<div
					className={`flex flex-row gap-2 items-center justify-center ${showStandaloneGames ? '' : 'hidden'}`}
				>
					<div className="relative w-full">
						<input
							type="text"
							placeholder={translations[language].searchGames}
							value={searchTerm}
							onChange={handleSearchChange}
							className="p-2 pl-4 border text-sm border-gray-300 rounded-xl outline-[#0081FB] w-full h-10 mb-4 pr-10 placeholder:text-zinc-800"
						/>
						{searchTerm && (
							<button
								onClick={handleSearchBarNonActiveClick}
								className="absolute top-0 right-0 mr-3 mt-2 text-gray-500 hover:text-gray-700 cursor-pointer"
							>
								<FiX size={26} />
							</button>
						)}
						{!searchTerm && (
							<span className="absolute top-0 right-0 mr-3 mt-2 text-gray-500">
								<FiSearch size={23} />
							</span>
						)}
					</div>
					<div className="flex items-center gap-2 mb-2">
						<span
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].sortBy}
						</span>
						<select
							onChange={handleSortByChange}
							value={sortBy}
							className="bg-gray-200 rounded px-2 py-1 w-fit outline-none cursor-pointer hover:bg-gray-300 transition-all duration-300"
						>
							<option value="name">{translations[language].name}</option>
							<option value="added">{translations[language].added}</option>
						</select>
						<button
							onClick={toggleSortOrder}
							className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition-all duration-300"
						>
							{sortBy === 'name' ? (
								sortOrder === 'asc' ? (
									<FaSortAlphaDown />
								) : (
									<FaSortAlphaDownAlt />
								)
							) : sortOrder === 'asc' ? (
								<FaSortNumericDown />
							) : (
								<FaSortNumericDownAlt />
							)}
						</button>
					</div>
				</div>
				<div
					className={`flex flex-col mt-4 ${showStandaloneGames ? '' : 'hidden'}`}
				>
					<div className="overflow-x-auto">
						<table className="styled-table min-w-full rounded-2xl">
							<thead>
								<tr>
									<th>No</th>
									<th>Game Title</th>
									<th>Cover</th>
									<th>Status</th>
									<th>Link Meta Store</th>
									<th>Link Download</th>
									<th>Game Version</th>
									<th>Number of Downloads</th>
									<th>Time Added</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{filteredDataStandaloneGames.map((gameTitle, index) => (
									<tr key={gameTitle}>
										<td className="border border-gray-300 py-2 px-4">
											{index + 1}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneGames[gameTitle].gameTitle}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{isGameImageAvailable[gameTitle] ? (
												<span>&#10003;</span>
											) : (
												<span>&#10005;</span>
											)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneGames[gameTitle].gameTitle.includes(
												'(Update)'
											) && (
												<span className="text-red-500 font-semibold">
													Update
												</span>
											)}
											{dataStandaloneGames[gameTitle].gameTitle.includes(
												'(Coming Soon)'
											) && (
												<span className="text-blue-500 font-semibold">
													Coming Soon
												</span>
											)}
											{!dataStandaloneGames[gameTitle].gameTitle.includes(
												'(Coming Soon)'
											) &&
												!dataStandaloneGames[gameTitle].gameTitle.includes(
													'(Update)'
												) && (
													<span className="text-green-500 font-semibold">
														New
													</span>
												)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{limitStringLength(
												dataStandaloneGames[gameTitle].linkMetaStore,
												10
											)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{limitStringLength(
												dataStandaloneGames[gameTitle].linkDownload,
												10
											)}
										</td>
										{/* <td>{data[gameTitle].gameSize}</td>
                    <td>{data[gameTitle].releaseDate}</td> */}
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneGames[gameTitle].gameVersion}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneGames[gameTitle].downloadCount}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneGames[gameTitle].timeAdded}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											<button
												className="btn btn-edit p-2"
												onClick={() =>
													handleUpdateStandaloneGamesButton(gameTitle)
												}
											>
												<FaPencil />
											</button>
											<button
												className="btn btn-delete p-2"
												onClick={() => onDeleteStandaloneGame(gameTitle)}
											>
												<FaTrash />
											</button>
											{/* Add link to view if needed */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Helmet>
						<title>Dashboard | Admin</title>
					</Helmet>
				</div>
			</div>

			<div>
				<button
					onClick={handlePCVRGames}
					className={`w-full rounded-xl py-2 mb-2 bg-[#0081FB] text-white flex flex-row justify-between items-center px-4`}
				>
					<span>PCVR Games</span>
					{showPCVRGames ? <FaAngleUp /> : <FaAngleDown />}
				</button>
				<button
					onClick={handleAddPCVRGamesButton}
					className={`${showPCVRGames ? '' : 'hidden'} text-center w-full py-2 mb-5 rounded-lg text-white font-semibold bg-[#0081FB] hover:bg-[#4083c2] transition-all duration-300`}
				>
					<FaPlus className="inline-block mr-2" />
					<span>Add Games</span>
				</button>
				<div
					className={`flex flex-row gap-2 items-center justify-center ${showPCVRGames ? '' : 'hidden'}`}
				>
					<div className="relative w-full">
						<input
							type="text"
							placeholder={translations[language].searchGames}
							value={searchTerm}
							onChange={handleSearchChange}
							className="p-2 pl-4 border text-sm border-gray-300 rounded-xl outline-[#0081FB] w-full h-10 mb-4 pr-10 placeholder:text-zinc-800"
						/>
						{searchTerm && (
							<button
								onClick={handleSearchBarNonActiveClick}
								className="absolute top-0 right-0 mr-3 mt-2 text-gray-500 hover:text-gray-700 cursor-pointer"
							>
								<FiX size={26} />
							</button>
						)}
						{!searchTerm && (
							<span className="absolute top-0 right-0 mr-3 mt-2 text-gray-500">
								<FiSearch size={23} />
							</span>
						)}
					</div>
					<div className="flex items-center gap-2 mb-2">
						<span
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].sortBy}
						</span>
						<select
							onChange={handleSortByChange}
							value={sortBy}
							className="bg-gray-200 rounded px-2 py-1 w-fit outline-none cursor-pointer hover:bg-gray-300 transition-all duration-300"
						>
							<option value="name">{translations[language].name}</option>
							<option value="added">{translations[language].added}</option>
						</select>
						<button
							onClick={toggleSortOrder}
							className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition-all duration-300"
						>
							{sortBy === 'name' ? (
								sortOrder === 'asc' ? (
									<FaSortAlphaDown />
								) : (
									<FaSortAlphaDownAlt />
								)
							) : sortOrder === 'asc' ? (
								<FaSortNumericDown />
							) : (
								<FaSortNumericDownAlt />
							)}
						</button>
					</div>
				</div>
				<div className={`flex flex-col mt-4 ${showPCVRGames ? '' : 'hidden'}`}>
					<div className="overflow-x-auto">
						<table className="styled-table min-w-full rounded-2xl">
							<thead>
								<tr>
									<th>No</th>
									<th>Game Title</th>
									<th>Status</th>
									<th>Link Meta Store</th>
									<th>Link Download</th>
									<th>Game Version</th>
									<th>Number of Downloads</th>
									<th>Time Added</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{filteredDataPCVRGames.map((gameTitle, index) => (
									<tr key={gameTitle}>
										<td className="border border-gray-300 py-2 px-4">
											{index + 1}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRGames[gameTitle].gameTitle}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRGames[gameTitle].gameTitle.includes(
												'(Update)'
											) && (
												<span className="text-red-500 font-semibold">
													Update
												</span>
											)}
											{dataPCVRGames[gameTitle].gameTitle.includes(
												'(Coming Soon)'
											) && (
												<span className="text-blue-500 font-semibold">
													Coming Soon
												</span>
											)}
											{!dataPCVRGames[gameTitle].gameTitle.includes(
												'(Coming Soon)'
											) &&
												!dataPCVRGames[gameTitle].gameTitle.includes(
													'(Update)'
												) && (
													<span className="text-green-500 font-semibold">
														New
													</span>
												)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{limitStringLength(
												dataPCVRGames[gameTitle].linkMetaStore,
												10
											)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{limitStringLength(
												dataPCVRGames[gameTitle].linkDownload,
												10
											)}
										</td>
										{/* <td>{data[gameTitle].gameSize}</td>
                    <td>{data[gameTitle].releaseDate}</td> */}
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRGames[gameTitle].gameVersion}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRGames[gameTitle].downloadCount}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRGames[gameTitle].timeAdded}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											<button
												className="btn btn-edit p-2"
												onClick={() => handleUpdatePCVRGamesButton(gameTitle)}
											>
												<FaPencil />
											</button>
											<button
												className="btn btn-delete p-2"
												onClick={() => onDeletePCVRGame(gameTitle)}
											>
												<FaTrash />
											</button>
											{/* Add link to view if needed */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Helmet>
						<title>Dashboard | Admin</title>
					</Helmet>
				</div>
			</div>
		</div>
	)
}

export default ListVRGames_Admin
