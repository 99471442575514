import React, { useState, useEffect } from 'react'
import { gapi } from 'gapi-script'
import toast, { Toaster } from 'react-hot-toast'
import ProgressBar from '@ramonak/react-progress-bar'
import { FaCheckCircle, FaCopy, FaFolder, FaFileAlt } from 'react-icons/fa'
import { Helmet } from 'react-helmet'

const CLIENT_ID =
	'878081046606-1qeq8n4vo2tamn5tk8v61ooh999j6tj7.apps.googleusercontent.com'
const API_KEY = 'AIzaSyBHcPPuCbD3ZDAiA2C8O3hF_cWKx-zsk8I' // Gantilah dengan API Key yang Anda dapatkan
const SCOPE = 'https://www.googleapis.com/auth/drive.file'
const DISCOVERY_DOCS = [
	'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
]

// ID folder Google Drive
const FOLDER_ID_PCVR = '1z86OOEKEewyx82YM4Mn0LYQWbNsyDg2v' // PCVR Folder
const FOLDER_ID_STANDALONE = '16D5cHbmdgDVvxbl_5Y6CZ7WNFNkhyIfD' // Standalone Folder

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function UploadToDrive() {
	const [files, setFiles] = useState([])
	const [isSignedIn, setIsSignedIn] = useState(false)
	const [selectedFolder, setSelectedFolder] = useState('pcvr') // Default to PCVR folder
	const [uploadProgress, setUploadProgress] = useState({})
	const [uploadComplete, setUploadComplete] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [folderFiles, setFolderFiles] = useState([])

	useEffect(() => {
		function start() {
			gapi.client
				.init({
					apiKey: API_KEY,
					clientId: CLIENT_ID,
					discoveryDocs: DISCOVERY_DOCS,
					scope: SCOPE,
				})
				.then(() => {
					const authInstance = gapi.auth2.getAuthInstance()
					authInstance.isSignedIn.listen(updateSignInStatus)
					updateSignInStatus(authInstance.isSignedIn.get())
				})
				.catch((error) => {
					console.error('Error initializing GAPI client:', error)
				})
		}
		gapi.load('client:auth2', start)
	}, [])

	const updateSignInStatus = (isSignedIn) => {
		setIsSignedIn(isSignedIn)
		if (isSignedIn) {
			loadFolderFiles(
				selectedFolder === 'pcvr' ? FOLDER_ID_PCVR : FOLDER_ID_STANDALONE
			)
		}
	}

	const handleAuthClick = () => {
		gapi.auth2
			.getAuthInstance()
			.signIn()
			.catch((error) => {
				console.error('Error signing in:', error)
			})
	}

	const handleSignOutClick = () => {
		gapi.auth2
			.getAuthInstance()
			.signOut()
			.catch((error) => {
				console.error('Error signing out:', error)
			})
	}

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files)
		const filesWithProgress = selectedFiles.map((file) => ({
			file,
			progress: 0,
			startTime: null, // Start time for calculating speed
		}))
		setFiles(filesWithProgress)
		setUploadComplete(false) // Reset upload complete status
	}

	const handleUpload = () => {
		if (files.length === 0) {
			toast.error('Please select files to upload')
			return
		}

		let folderId =
			selectedFolder === 'pcvr' ? FOLDER_ID_PCVR : FOLDER_ID_STANDALONE

		files.forEach((fileObj, index) => {
			const { file } = fileObj

			const metadata = {
				name: file.name,
				mimeType: file.type,
				parents: [folderId],
			}

			const formData = new FormData()
			formData.append(
				'metadata',
				new Blob([JSON.stringify(metadata)], { type: 'application/json' })
			)
			formData.append('file', file)

			const xhr = new XMLHttpRequest()
			xhr.open(
				'POST',
				'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart'
			)
			xhr.setRequestHeader(
				'Authorization',
				`Bearer ${gapi.auth.getToken().access_token}`
			)

			const startTime = new Date().getTime()
			fileObj.startTime = startTime // Store start time for speed calculation

			xhr.upload.onprogress = (event) => {
				if (event.lengthComputable) {
					const progress = Math.round((event.loaded / event.total) * 100)
					setUploadProgress((prevProgress) => ({
						...prevProgress,
						[file.name]: {
							progress,
							size: formatBytes(file.size), // Store file size
							uploadedBytes: event.loaded,
							startTime: startTime,
						},
					}))
				}
			}

			xhr.onload = () => {
				if (xhr.status === 200) {
					const response = JSON.parse(xhr.responseText)
					const fileId = response.id
					const fileLink = `https://drive.google.com/file/d/${fileId}/view`
					setUploadedFiles((prevFiles) => [
						...prevFiles,
						{ name: file.name, link: fileLink },
					])
					if (index === files.length - 1) {
						setUploadComplete(true) // Set upload complete status after last file
						loadFolderFiles(folderId)
						toast.success('All files uploaded successfully')
					}
				} else {
					toast.error('Error uploading file')
					console.error('Error uploading file:', xhr.responseText)
				}
			}

			xhr.send(formData)
		})
	}

	const loadFolderFiles = (folderId) => {
		gapi.client.drive.files
			.list({
				q: `'${folderId}' in parents`,
				fields: 'files(id, name, webViewLink, mimeType)',
			})
			.then((response) => {
				setFolderFiles(response.result.files)
			})
			.catch((error) => {
				console.error('Error loading folder files:', error)
			})
	}

	const handleCopyLink = (link) => {
		navigator.clipboard
			.writeText(link)
			.then(() => {
				toast.success('Link copied to clipboard')
			})
			.catch((error) => {
				console.error('Error copying link:', error)
				toast.error('Failed to copy link')
			})
	}

	const handleFolderChange = (e) => {
		const newFolder = e.target.value
		setSelectedFolder(newFolder)
		loadFolderFiles(
			newFolder === 'pcvr' ? FOLDER_ID_PCVR : FOLDER_ID_STANDALONE
		)
	}

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`pt-6 mx-5 w-full h-full flex justify-center items-center ${!isMobile && 'pt-[100px]'} min-h-screen`}
		>
			<Toaster position="top-center" />

			{isSignedIn ? (
				<div className="w-full bg-white p-6 rounded shadow-lg text-center">
					<h2 className="text-2xl font-bold mb-4">
						Upload Files to Google Drive
					</h2>
					<div className="mb-4 flex items-center gap-3 justify-center">
						<label className="block text-sm font-medium text-gray-700 mb-2 text-nowrap">
							Select Folder:
						</label>
						<select
							className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
							value={selectedFolder}
							onChange={handleFolderChange}
						>
							<option value="pcvr">PCVR</option>
							<option value="standalone">Standalone</option>
						</select>
					</div>
					<div className="flex flex-row items-center justify-center gap-3 w-full">
						<input
							type="file"
							multiple
							onChange={handleFileChange}
							className="mt-4 mb-4 p-2 border border-gray-300 rounded-md"
						/>
						<button
							onClick={handleUpload}
							className="bg-blue-500 text-white px-4 py-2 rounded"
						>
							Upload
						</button>
					</div>
					{files.length > 0 && (
						<span className="ml-2 text-sm text-gray-500 text-nowrap">
							Total Size:{' '}
							{formatBytes(
								files.reduce((acc, curr) => acc + curr.file.size, 0)
							)}
						</span>
					)}
					{files.length > 0 && (
						<div className="mt-6">
							<h3 className="text-xl font-semibold mb-2">Upload Progress:</h3>
							<ul className="list-none">
								{files.map((fileObj) => (
									<li key={fileObj.file.name} className="mb-2">
										<div className="flex justify-between items-center">
											<span className="text-gray-700">{fileObj.file.name}</span>
											<span className="text-gray-500">
												{uploadProgress[fileObj.file.name]?.size || '0 Bytes'}{' '}
												{/* Displaying file size */}
											</span>
										</div>
										<ProgressBar
											completed={
												uploadProgress[fileObj.file.name]?.progress || 0
											}
											// Adding estimated time remaining
											labelColor="white"
											baseBgColor="#e0e0df"
											bgColor="#3b5998"
											transitionDuration="0.3s"
											className="mt-1"
										/>
										{uploadProgress[fileObj.file.name]?.progress === 100 && (
											<span className="text-green-600 flex items-center">
												<FaCheckCircle className="mr-2" /> Upload complete
											</span>
										)}
										{uploadProgress[fileObj.file.name]?.progress > 0 && (
											<div className="text-sm text-gray-600">
												Estimated Time Remaining:{' '}
												{calculateEstimatedTime(fileObj)}
											</div>
										)}
									</li>
								))}
							</ul>
						</div>
					)}

					<div className="mt-6">
						<h3 className="text-xl font-semibold mb-2">
							Files in {selectedFolder === 'pcvr' ? 'PCVR' : 'Standalone'}{' '}
							Folder:
						</h3>
						{folderFiles.length > 0 ? (
							<ul className="space-y-2">
								{folderFiles.map((file) => (
									<li
										key={file.id}
										className="flex items-center p-2 border border-gray-300 rounded-md justify-between"
									>
										<div className="flex flex-row gap-1 items-center">
											{file.mimeType ===
											'application/vnd.google-apps.folder' ? (
												<FaFolder className="text-yellow-500 mr-2" />
											) : (
												<FaFileAlt className="text-[#0081FB] mr-2" />
											)}
											<a
												href={file.webViewLink}
												target="_blank"
												rel="noopener noreferrer"
												className="text-blue-500"
											>
												{file.name}
											</a>
										</div>
										<button
											onClick={() => handleCopyLink(file.webViewLink)}
											className="ml-2 p-1 border border-gray-400 rounded-md text-gray-600 hover:bg-gray-200"
										>
											<FaCopy />
										</button>
									</li>
								))}
							</ul>
						) : (
							<p>No files found in this folder.</p>
						)}
					</div>
				</div>
			) : (
				<div className="w-full bg-white p-6 rounded shadow-lg text-center">
					<h2 className="text-2xl font-bold mb-4">Google Drive File Upload</h2>
					<button
						onClick={handleAuthClick}
						className="bg-blue-500 text-white px-4 py-2 rounded"
					>
						Sign In with Google
					</button>
				</div>
			)}
		</div>
	)

	// Function to calculate estimated time remaining
	function calculateEstimatedTime(fileObj) {
		const uploadDetails = uploadProgress[fileObj.file.name]
		if (!uploadDetails) return 'N/A'

		const { uploadedBytes, size, startTime } = uploadDetails
		const elapsedTime = (new Date().getTime() - startTime) / 1000 // seconds
		const totalBytes = fileObj.file.size
		const uploadSpeed = uploadedBytes / elapsedTime // bytes per second
		const remainingBytes = totalBytes - uploadedBytes

		if (uploadSpeed > 0) {
			const estimatedTime = remainingBytes / uploadSpeed // seconds
			const minutes = Math.floor(estimatedTime / 60)
			const seconds = Math.floor(estimatedTime % 60)
			return `${minutes}m ${seconds}s`
		}

		return 'Calculating...'
	}
}

export default UploadToDrive
