import React from 'react'

const Footer = ({ translations, language }) => (
	<footer className="bg-gray-900 text-white pt-10">
		<div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-center md:text-left px-5">
			{/* Logo and Description */}
			<div className="mb-8 md:mb-0">
				<h2 className="text-2xl font-semibold mb-3">HyperTopia</h2>
				<p className="text-gray-400 text-sm max-w-xs">
					{translations[language].footerDescription ||
						'Discover the best collection of VR and Steam games to elevate your gaming experience.'}
				</p>
			</div>

			{/* Menu Links */}
			<div className="flex flex-col md:flex-row gap-4 md:gap-8">
				<a
					href="/"
					className="hover:underline text-gray-400 hover:text-gray-200"
				>
					{translations[language].home || 'Home'}
				</a>
				<a
					href="/products"
					className="hover:underline text-gray-400 hover:text-gray-200"
				>
					{translations[language].products || 'Products'}
				</a>
				<a
					href="/vr-games"
					className="hover:underline text-gray-400 hover:text-gray-200"
				>
					{translations[language].vrGames || 'VR Games'}
				</a>
				<a
					href="/profile"
					className="hover:underline text-gray-400 hover:text-gray-200"
				>
					{translations[language].profile || 'Profile'}
				</a>
			</div>
		</div>

		{/* Copyright Section */}
		<div className="border-t border-gray-700 mt-8 py-4 text-gray-400 text-xs text-center">
			&copy; {new Date().getFullYear()} HyperTopia. All rights reserved.
		</div>
	</footer>
)

export default Footer
