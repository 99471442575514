import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDarkModeContext } from '../../DarkModeContext'
import fireDb from '../../firebase'
import { auth } from '../../firebase'
import {
	FaBagShopping,
	FaMinus,
	FaPlus,
	FaShop,
	FaTrash,
} from 'react-icons/fa6'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'
import toast, { Toaster } from 'react-hot-toast'

const Cart = () => {
	const { isDarkMode } = useDarkModeContext()
	const storedLanguage = localStorage.getItem('language') || 'id'
	const [language, setLanguage] = useState(storedLanguage)
	const [cartItems, setCartItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const [user, setUser] = useState(null)
	const [showTotalPriceMobile, setShowTotalPriceMobile] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		localStorage.setItem('language', language)

		if (user) {
			const cartRef = fireDb.ref(`cart/${user.uid}`)

			// Listen for changes in the cart
			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const cartItemList = items ? Object.values(items) : []
				setCartItems(cartItemList)
			}

			cartRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				cartRef.off('value', handleSnapshot)
			}
		}
	}, [language, user])

	const handleDeleteItem = (itemName) => {
		const cartRef = fireDb.ref(`cart/${user.uid}`)
		Swal.fire({
			title: 'Remove item from cart?',
			text: 'The item will be permanently removed from your cart.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Remove',
			cancelButtonText: 'Cancel',
		}).then((result) => {
			if (result.isConfirmed) {
				toast.success('Produk berhasil dihapus dari keranjang!')
				cartRef.child(itemName).remove()
			}
		})
	}

	const stringToNumber = (string) => {
		return Number(string.replace(/[^0-9.-]+/g, ''))
	}

	const calculateTotalPrice = () => {
		const selectedItemsTotalPrice = cartItems
			.filter((item) => selectedItems.includes(item.productName))
			.reduce((total, item) => total + item.productPrice * item.quantity, 0)
			.toLocaleString()

		return selectedItemsTotalPrice
	}

	const calculateTotalPrice_WithAdmin = () => {
		const selectedItemsTotalPrice = cartItems
			.filter((item) => selectedItems.includes(item.productName))
			.reduce((total, item) => total + item.productPrice * item.quantity, 0)
			.toLocaleString()

		return (
			stringToNumber(selectedItemsTotalPrice) +
			Math.ceil(0.005 * stringToNumber(selectedItemsTotalPrice))
		).toLocaleString()
	}

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 1000) {
				setShowTotalPriceMobile(true)
			} else {
				setShowTotalPriceMobile(false)
			}
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const isMobile = window.innerWidth < 1200

	const translations = {
		id: {
			account: 'Akun',
			cart: 'Keranjang',
			emptyCart: 'Keranjangmu kosong!',
			emptyCartDescription:
				'Sepertinya kamu belum menambahkan apapun ke keranjangmu.',
			startShopping: 'Mulai Belanja',
			totalPrice: 'Total Harga',
			pay: 'Bayar',
			product: 'Produk',
			createOrder: 'Buat Pesanan',
			deleteAllItemsFromCart: 'Hapus semua item dari keranjang?',
			deleteAllItemsDescription: 'Item yang dihapus tidak dapat dikembalikan.',
			delete: 'Hapus',
			cancel: 'Batal',
			areYouSureCreate: 'Anda yakin ingin membuat pesanan?',
			areYouSureDescription: 'Pastikan pesanan Anda sudah benar.',
			sure: 'Ya, saya yakin!',
			orderWithId: 'Pesanan dengan ID',
			successfullyCreated: 'berhasil dibuat!',
			checkOrder: 'Silahkan cek pesananmu di halaman Pesanan.',
			errorCreatingOrder:
				'Terjadi kesalahan saat membuat pesanan. Silakan coba lagi nanti.',
			error: 'Error',
			fillAllEmails: 'Silakan isi semua alamat email sebelum membuat pesanan.',
			afterPayment:
				'Setelah melakukan pembayaran, email secara otomatis terdaftar ke website dan mendapat akses untuk download gamenya.',
		},
		en: {
			account: 'Account',
			cart: 'Cart',
			emptyCart: 'Oops, your cart is empty!',
			emptyCartDescription:
				"Looks like you haven't added anything to your cart yet.",
			startShopping: 'Start Shopping',
			totalPrice: 'Total Price',
			pay: 'Pay',
			product: 'Product',
			createOrder: 'Create Order',
			deleteAllItemsFromCart: 'Delete all items from cart?',
			deleteAllItemsDescription: 'Items that are deleted cannot be returned.',
			delete: 'Delete',
			cancel: 'Cancel',
			areYouSureCreate: 'Are you sure you want to create an order?',
			areYouSureDescription: 'Make sure your order is correct.',
			sure: "Yes, I'm sure!",
			orderWithId: 'Order with ID',
			successfullyCreated: 'successfully created!',
			checkOrder: 'Please check your order on the Orders page.',
			errorCreatingOrder:
				'An error occurred while creating the order. Please try again later.',
			error: 'Error',
			fillAllEmails:
				'Please fill in all email addresses before creating the order.',
			afterPayment:
				'After making a payment, the email is automatically registered on the website and gets access to download the game.',
		},
	}

	const handleUpdateQuantity = (itemName, newQuantity) => {
		const cartRef = fireDb.ref(`cart/${user.uid}/${itemName}`)

		// Check if the new quantity is greater than 0 before updating
		if (newQuantity > 0) {
			cartRef.update({ quantity: newQuantity })
		} else {
			// If the new quantity is 0 or negative, remove the item from the cart
			cartRef.remove()
		}
	}

	const handleCheckboxChange = (itemName) => {
		const updatedSelection = [...selectedItems]

		if (updatedSelection.includes(itemName)) {
			// If item is already selected, remove it
			updatedSelection.splice(updatedSelection.indexOf(itemName), 1)
		} else {
			// If item is not selected, add it
			updatedSelection.push(itemName)
		}

		setSelectedItems(updatedSelection)
	}

	const handleDeleteAllItems = () => {
		const cartRef = fireDb.ref(`cart/${user.uid}`)
		Swal.fire({
			title: translations[language].deleteAllItemsFromCart,
			text: translations[language].deleteAllItemsDescription,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: translations[language].delete,
			cancelButtonText: translations[language].cancel,
		}).then((result) => {
			if (result.isConfirmed) {
				cartRef.remove()
			}
		})
	}

	// Tambahkan state untuk melacak apakah semua email telah diisi
	const [emailsValid, setEmailsValid] = useState(false)

	// Buat fungsi untuk memeriksa apakah semua email telah diisi
	const checkEmailsValid = () => {
		const cartItemsWithEmails = cartItems.filter(
			(item) => item.productName === 'Games For Oculus Meta Quest 1 2 3 dan Pro'
		)
		const allEmailsFilled = cartItemsWithEmails.every(
			(item) => item.emails && item.emails.every((email) => email)
		)
		setEmailsValid(allEmailsFilled)
	}

	// Panggil fungsi checkEmailsValid setiap kali cartItems atau selectedItems berubah
	useEffect(() => {
		checkEmailsValid()
	}, [cartItems, selectedItems])

	const handleCreateOrder = () => {
		if (!emailsValid) {
			// Jika email belum diisi, tampilkan SweetAlert
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: translations[language].fillAllEmails,
			})
			return
		}
		Swal.fire({
			title: translations[language].areYouSureCreate,
			text: translations[language].areYouSureDescription,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: translations[language].sure,
			cancelButtonText: translations[language].cancel,
		}).then((result) => {
			if (result.isConfirmed) {
				const orderId = generateOrderId() // Fungsi untuk membuat orderId sesuai format yang diinginkan
				const orderRef = fireDb.ref(`orders/${user.uid}/${orderId}`)
				const newOrder = {
					items: cartItems.filter((item) =>
						selectedItems.includes(item.productName)
					),
					totalPrice: calculateTotalPrice_WithAdmin(),
					timeCreated: new Date().toISOString(),
					status: 'Menunggu Pembayaran',
					hasPaid: false,
					verifiedPayment: false,
				}

				orderRef
					.set(newOrder)
					.then(() => {
						Swal.fire({
							icon: 'success',
							title: `${translations[language].orderWithId} ${orderId} ${translations[language].successfullyCreated}`,
							text: translations[language].checkOrder,
						})

						// Hapus produk yang dipilih dari keranjang pengguna
						const cartRef = fireDb.ref(`cart/${user.uid}`)
						selectedItems.forEach((itemName) => {
							cartRef.child(itemName).remove()
						})

						setSelectedItems([])
					})
					.catch((error) => {
						console.error('Error creating order:', error)
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: translations[language].errorCreatingOrder,
						})
					})
			}
		})
	}

	// Fungsi untuk membuat orderId sesuai format yang diinginkan
	const generateOrderId = () => {
		const currentDate = new Date()
		const year = currentDate.getFullYear()
		const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
		const date = currentDate.getDate().toString().padStart(2, '0')
		const hours = currentDate.getHours().toString().padStart(2, '0')
		const minutes = currentDate.getMinutes().toString().padStart(2, '0')
		const seconds = currentDate.getSeconds().toString().padStart(2, '0')

		const orderId = `${year}${month}${date}HTPA${hours}${minutes}${seconds}`

		return orderId
	}

	const handleEmailChange = (index, itemName, email) => {
		const cartRef = fireDb.ref(`cart/${user.uid}/${itemName}/emails/${index}`)
		cartRef.set(email)
	}

	return (
		<div
			className={`flex ${showTotalPriceMobile ? 'flex-col' : 'flex-row'} ${cartItems.length === 0 ? 'items-center' : 'items-start pt-10'} justify-center px-8 pt-10 w-full ${!isMobile && 'pt-[100px]'}`}
		>
			<Toaster />
			<div className="pb-10 w-full">
				{cartItems.length === 0 ? (
					<span className="flex flex-col items-center bg-white px-6 py-6 rounded-xl border-2">
						<h1
							className={`${isDarkMode ? 'text-black' : 'text-black'} text-xl font-semibold text-center`}
						>
							{translations[language].emptyCart}
						</h1>
						<p
							className={` ${isDarkMode ? 'text-black' : 'text-black'} text-center mt-3`}
						>
							{translations[language].emptyCartDescription}
						</p>
						<Link to="/products">
							<button className="bg-gradient-to-br from-blue-300 to-blue-600 hover:bg-blue-700 font-semibold text-white px-5 py-2 rounded-md mt-5 transition-all duration-300 flex flex-row gap-2 items-center justify-center">
								<FaBagShopping />
								{translations[language].startShopping}
							</button>
						</Link>
					</span>
				) : (
					<div>
						<div className="px-4 py-2 mb-4 bg-white rounded-xl flex flex-row gap-2 items-center justify-between border-2">
							<span className="flex flex-row gap-2">
								<input
									type="checkbox"
									checked={selectedItems.length === cartItems.length}
									onChange={() => {
										if (selectedItems.length === cartItems.length) {
											setSelectedItems([])
										} else {
											setSelectedItems(
												cartItems.map((item) => item.productName)
											)
										}
									}}
									className="h-6 w-6 rounded-md border-2 border-zinc-300"
								/>
								<span>Pilih semua item</span>
							</span>
							<button
								onClick={handleDeleteAllItems}
								className="text-[#0081FB] font-semibold rounded-xl hover:bg-zinc-200 transition-all p-2"
							>
								Hapus semua item
							</button>
						</div>
						{cartItems.map((item, index) => (
							<div
								key={index}
								className="flex flex-row gap-2 mb-2 border-2 p-4 rounded-xl bg-white w-full"
							>
								<div>
									<input
										type="checkbox"
										checked={selectedItems.includes(item.productName)}
										onChange={() => handleCheckboxChange(item.productName)}
										className="h-6 w-6 rounded-md border-2 border-zinc-300"
									/>
								</div>
								<div className="w-full flex items-start">
									{item.productPhotoUrl && (
										<img
											src={item.productPhotoUrl}
											alt={item.productName}
											className="w-24 h-24 rounded-md mr-4"
										/>
									)}
									<div>
										<span className="flex flex-col gap-2">
											<span>
												<span className="text-xl">{item.productName}</span>
												<p className="font-bold text-lg">
													Rp{item.productPrice.toLocaleString()}
												</p>
											</span>
											{item.productName ===
												'Games For Oculus Meta Quest 1 2 3 dan Pro' && (
												<span className="px-3 py-2 bg-green-500 rounded-lg text-white text-sm">
													{translations[language].afterPayment}
												</span>
											)}
										</span>
										{item.productName ===
											'Games For Oculus Meta Quest 1 2 3 dan Pro' && (
											<>
												{[...Array(item.quantity)].map((_, emailIndex) => (
													<span
														key={emailIndex}
														className="flex flex-row gap-2 items-center"
													>
														<label
															htmlFor={`email-${emailIndex}`}
															className="text-sm mt-2 w-20"
														>{`Email ${emailIndex + 1}:`}</label>
														<input
															id={`email-${emailIndex}`}
															type="email"
															placeholder={`Email ${emailIndex + 1}`}
															className="px-3 py-2 rounded-md border border-zinc-300 w-full mt-2"
															onChange={(e) =>
																handleEmailChange(
																	emailIndex,
																	item.productName,
																	e.target.value
																)
															}
														/>
													</span>
												))}
											</>
										)}
									</div>
								</div>
								<div className="flex justify-end gap-2">
									<div className="flex items-start">
										<button
											onClick={() =>
												handleUpdateQuantity(
													item.productName,
													item.quantity - 1
												)
											}
											disabled={item.quantity === 1}
											className={`hover:bg-zinc-200 text-zinc-700 font-semibold px-3 py-3 rounded-md mr-2 text-xs h-fit ${item.quantity === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
										>
											<FaMinus />
										</button>
										<input
											type="number"
											value={item.quantity}
											onChange={(e) =>
												handleUpdateQuantity(
													item.productName,
													parseInt(e.target.value)
												)
											}
											className="px-3 py-2 rounded-md border border-zinc-300 w-16"
											min="1"
											max="99"
										/>
										<button
											onClick={() =>
												handleUpdateQuantity(
													item.productName,
													item.quantity + 1
												)
											}
											className="hover:bg-zinc-200 text-zinc-700 font-semibold px-3 py-3 rounded-md ml-2 mr-2 text-xs h-fit "
										>
											<FaPlus />
										</button>
										<button
											onClick={() => handleDeleteItem(item.productName)}
											className="bg-red-500 hover:bg-red-700 text-white font-semibold p-3 rounded-md h-fit"
										>
											<FaTrash />
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			{cartItems.length === 0 ? null : (
				<div
					className={`flex flex-col gap-4 pl-4 w-96 ${showTotalPriceMobile ? 'hidden' : ''}`}
				>
					<div className="border-2 p-4 rounded-xl bg-white flex justify-between items-start">
						<span>
							<h2 className="text-lg font-bold">
								{translations[language].totalPrice}
							</h2>
							<p className=" font-light">
								{selectedItems.length} {translations[language].product}
							</p>
						</span>
						<p className="text-xl font-bold text-[#0081FB]">
							Rp{calculateTotalPrice()}
						</p>
					</div>
					<button
						onClick={handleCreateOrder}
						disabled={selectedItems.length === 0}
						className="disabled:bg-zinc-300 disabled:cursor-not-allowed w-full py-3 rounded-xl bg-[#0081FB] text-white font-semibold text-lg hover:bg-blue-700 transition-all"
					>
						{translations[language].createOrder}
					</button>
				</div>
			)}

			{cartItems.length === 0 ? null : (
				<div
					className={`flex flex-col gap-4 bottom-0 right-0 left-0 pb-8 w-full ${showTotalPriceMobile ? '' : 'hidden'}`}
				>
					<div className="border-2 p-4 rounded-xl bg-white flex justify-between items-start">
						<span>
							<h2 className="text-lg font-bold">
								{translations[language].totalPrice}
							</h2>
							<p className=" font-light">
								{selectedItems.length} {translations[language].product}
							</p>
						</span>
						<p className="text-xl font-bold text-[#0081FB]">
							Rp{calculateTotalPrice()}
						</p>
					</div>
					<button
						onClick={handleCreateOrder}
						disabled={selectedItems.length === 0}
						className="disabled:bg-zinc-300 disabled:cursor-not-allowed w-full py-3 rounded-xl bg-[#0081FB] text-white font-semibold text-lg hover:bg-blue-700 transition-all"
					>
						{translations[language].createOrder}
					</button>
				</div>
			)}

			<Helmet>
				<title>
					{translations[language].cart} | {translations[language].account} -
					HyperTopia
				</title>
			</Helmet>
		</div>
	)
}

export default Cart
