import firebase from 'firebase/compat/app'
import { getStorage } from 'firebase/storage'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import 'firebase/compat/database'

const firebaseConfig = {
	apiKey: "AIzaSyC6IY9OIpKtTN_e6IG5uff7HpeF5uubgUM",
	authDomain: "hypertopia-fourth.firebaseapp.com",
	databaseURL: "https://hypertopia-fourth-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "hypertopia-fourth",
	storageBucket: "hypertopia-fourth.appspot.com",
	messagingSenderId: "155082297633",
	appId: "1:155082297633:web:7d472a7bf8f2a2970dae60"
};

const fireDb = firebase.initializeApp(firebaseConfig)
const storage = getStorage(fireDb)
const auth = getAuth(fireDb)
const provider = new GoogleAuthProvider()
export { storage, auth, provider }
export default fireDb.database()
