import React, { useEffect, useState } from 'react'
import { listAll, getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../firebase'
import { BsVr, BsHeadsetVr, BsLaptop } from 'react-icons/bs'
import { FaMeta } from 'react-icons/fa6'
import { MdRefresh } from 'react-icons/md'
import { motion } from 'framer-motion'

const VRGamesSection = ({ games, translations, language, gamesPCVR }) => {
	const [filteredGames, setFilteredGames] = useState([])
	const [visibleGames, setVisibleGames] = useState([])
	const [isLoading, setIsLoading] = useState(true) // Loading state

	useEffect(() => {
		// Filter dan acak daftar game yang memiliki gambar
		const fetchGamesWithImages = async () => {
			const imageListRef = ref(storage, 'coverGamesImages/')
			const response = await listAll(imageListRef)

			const gamesWithImages = await Promise.all(
				games.map(async (game) => {
					if (!game.gameTitle) return null

					const gameImageName = `${game.gameTitle}`
					const userImage = response.items.find(
						(item) => item.name === gameImageName
					)

					if (userImage) {
						const url = await getDownloadURL(userImage)
						return { ...game, imageUrl: url }
					}
					return null
				})
			)

			const validGames = gamesWithImages.filter(Boolean)
			setFilteredGames(validGames)
			setIsLoading(false) // Selesai loading
		}

		fetchGamesWithImages()
	}, [games])

	// Fungsi untuk memilih 10 game secara acak
	const getRandomGames = () => {
		const shuffled = [...filteredGames].sort(() => Math.random() - 0.5)
		return shuffled.slice(0, 10)
	}

	// Set pertama kali saat komponen dimuat
	useEffect(() => {
		if (filteredGames.length > 0) {
			setVisibleGames(getRandomGames())
		}
	}, [filteredGames])

	// Handler untuk tombol Refresh
	const handleRefresh = () => {
		setVisibleGames(getRandomGames())
	}

	return (
		<section className="vr-games-section py-10 bg-white overflow-hidden px-4 md:px-8">
			<div className="flex flex-row items-center justify-center gap-2">
				<FaMeta className="text-2xl md:text-3xl text-[#0081FB]" />
				<h2 className="text-2xl md:text-3xl font-bold text-center mb-2 text-[#0081FB]">
					{translations[language].listVRGames}
				</h2>
			</div>
			<h3 className="text-md md:text-lg text-center mb-4 text-gray-700">
				{translations[language].listVRGamesSubtitle}
			</h3>
			<div className="w-full flex justify-center">
				<button
					onClick={handleRefresh}
					className="flex flex-row items-center justify-center gap-2 mb-4 px-5 py-2 bg-[#0081FB] text-white rounded-full hover:bg-[#006bbd] transition duration-300"
				>
					<MdRefresh />
					<p>Refresh</p>
				</button>
			</div>

			{/* Loading Animation */}
			{isLoading ? (
				<div className="flex justify-center items-center h-64">
					<div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
				</div>
			) : (
				// Grid Container for Games
				<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mx-auto max-w-7xl px-2 md:px-4">
					{visibleGames.map((game, index) => (
						<GameCard
							key={index}
							gameTitle={game.gameTitle}
							imageUrl={game.imageUrl}
							index={index}
						/>
					))}
				</div>
			)}

			{/* Info Section */}
			<div className="flex flex-col items-center mt-8 gap-4">
				<div className="flex justify-center gap-2">
					<p className="flex flex-row justify-center items-center gap-2 text-center bg-[#0081FB] px-4 py-2 rounded-full text-white w-fit text-sm md:text-base">
						<BsHeadsetVr />
						<span className="font-semibold">
							{games.length} Games Standalone
						</span>
					</p>
					<p className="flex flex-row justify-center items-center gap-2 text-center bg-[#0081FB] px-4 py-2 rounded-full text-white w-fit text-sm md:text-base">
						<BsLaptop />
						<span className="font-semibold">{gamesPCVR.length} Games PCVR</span>
					</p>
				</div>
			</div>
		</section>
	)
}

const GameCard = ({ gameTitle, imageUrl, index }) => {
	if (!imageUrl) return null

	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5, delay: index * 0.1 }}
			className="game-card bg-white rounded-xl shadow-lg w-full h-40 sm:h-48 md:h-56 flex-shrink-0 overflow-hidden relative"
		>
			{/* Game Cover Image */}
			<img
				src={imageUrl}
				alt={gameTitle}
				className="w-full h-full object-cover"
			/>

			{/* Game Title Overlay */}
			<div className="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center py-2 font-semibold text-sm md:text-base">
				{gameTitle}
			</div>
		</motion.div>
	)
}

export default VRGamesSection
