import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { Link } from 'react-router-dom'
import './Home.css'

import { Helmet } from 'react-helmet'
import {
	FaArrowAltCircleDown,
	FaArrowAltCircleUp,
	FaPlus,
	FaTrash,
} from 'react-icons/fa'
import { FaAngleDown, FaAngleUp, FaPencil } from 'react-icons/fa6'
import toast, { Toaster } from 'react-hot-toast'

const ListUsers_Admin = () => {
	const [dataStandaloneUsers, setDataStandaloneUsers] = useState({}) // Add state for standalone users
	const [dataPCVRUsers, setDataPCVRUsers] = useState({}) // Add state for PCVR users

	const [showStandaloneUsers, setShowStandaloneUsers] = useState(false)
	const [showPCVRUsers, setShowPCVRUsers] = useState(false)

	useEffect(() => {
		const fetchData = () => {
			fireDb.ref(`eligibleUsers/standalone`).on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					setDataStandaloneUsers({ ...snapshot.val() })
				} else {
					setDataStandaloneUsers({})
				}
			})
		}

		fetchData()

		return () => {
			fireDb.ref(`eligibleUsers/standalone`).off() // Remove the event listener when component unmounts
		}
	}, [])

	useEffect(() => {
		const fetchData = () => {
			fireDb.ref(`eligibleUsers/pcvr`).on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					setDataPCVRUsers({ ...snapshot.val() })
				} else {
					setDataPCVRUsers({})
				}
			})
		}

		fetchData()

		return () => {
			fireDb.ref(`eligibleUsers/pcvr`).off() // Remove the event listener when component unmounts
		}
	}, [])

	const onDelete_StandaloneUser = (eligibleUser) => {
		if (window.confirm('Are you sure that you want to delete this user?')) {
			fireDb.ref(`eligibleUsers/standalone/${eligibleUser}`).remove((err) => {
				if (err) {
					toast.error(err)
				} else {
					toast.success('User Deleted Successfully!')
				}
			})
		}
	}

	const onDelete_PCVRUser = (eligibleUser) => {
		if (window.confirm('Are you sure that you want to delete this user?')) {
			fireDb.ref(`eligibleUsers/pcvr/${eligibleUser}`).remove((err) => {
				if (err) {
					toast.error(err)
				} else {
					toast.success('User Deleted Successfully!')
				}
			})
		}
	}

	const handleAddUsersButton_Standalone = () => {
		localStorage.setItem('dataTypeAddEdit', 'eligibleUser')
		window.location.href = '/dba/list-users/standalone/add'
	}

	const handleEditUsersButton_Standalone = (eligibleUser) => {
		localStorage.setItem('dataTypeAddEdit', 'eligibleUser')
		window.location.href = `/dba/list-users/standalone/update/${eligibleUser}`
	}

	const handleAddUsersButton_PCVR = () => {
		localStorage.setItem('dataTypeAddEdit', 'eligibleUser')
		window.location.href = '/dba/list-users/pcvr/add'
	}

	const handleEditUsersButton_PCVR = (eligibleUser) => {
		localStorage.setItem('dataTypeAddEdit', 'eligibleUser')
		window.location.href = `/dba/list-users/pcvr/update/${eligibleUser}`
	}

	const handleStandaloneUsers = () => {
		setShowStandaloneUsers(!showStandaloneUsers)
	}

	const handlePCVRUsers = () => {
		setShowPCVRUsers(!showPCVRUsers)
	}

	const isMobile = window.innerWidth < 1200

	return (
		<div className={`pt-6 mb-20 px-6 w-full ${!isMobile && 'pt-[100px]'}`}>
			<Toaster />
			<div>
				<button
					onClick={handleStandaloneUsers}
					className={`w-full rounded-xl py-2 mb-2 bg-[#0081FB] text-white flex flex-row justify-between items-center px-4`}
				>
					<span>Standalone Users</span>
					{showStandaloneUsers ? <FaAngleUp /> : <FaAngleDown />}
				</button>
				<button
					onClick={handleAddUsersButton_Standalone}
					className={`${showStandaloneUsers ? '' : 'hidden'} text-center w-full py-2 mb-5 rounded-lg text-white font-semibold bg-[#0081FB] hover:bg-[#4083c2] transition-all duration-300`}
				>
					<FaPlus className="inline-block mr-2" />
					<span>Add Standalone Eligible Users</span>
				</button>
				<div className={`flex flex-col ${showStandaloneUsers ? '' : 'hidden'}`}>
					<div className="overflow-x-auto">
						<table className="styled-table min-w-full rounded-2xl">
							<thead>
								<tr>
									<th>No</th>
									<th>Email</th>
									<th>Time Added</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(dataStandaloneUsers).map((eligibleUser, index) => (
									<tr key={eligibleUser}>
										<td className="border border-gray-300 py-2 px-4">
											{index + 1}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneUsers[eligibleUser].email}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataStandaloneUsers[eligibleUser].timeAdded}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											<button
												className="btn btn-edit p-2"
												onClick={() =>
													handleEditUsersButton_Standalone(eligibleUser)
												}
											>
												<FaPencil />
											</button>
											<button
												className="btn btn-delete p-2"
												onClick={() => onDelete_StandaloneUser(eligibleUser)}
											>
												<FaTrash />
											</button>
											{/* Add link to view if needed */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Helmet>
						<title>List Users | Admin</title>
					</Helmet>
				</div>
			</div>

			<div>
				<button
					onClick={handlePCVRUsers}
					className={`w-full rounded-xl py-2 mb-2 bg-[#0081FB] text-white flex flex-row justify-between items-center px-4`}
				>
					<span>PCVR Users</span>
					{showPCVRUsers ? <FaAngleUp /> : <FaAngleDown />}
				</button>
				<button
					onClick={handleAddUsersButton_PCVR}
					className={`${showPCVRUsers ? '' : 'hidden'} text-center w-full py-2 mb-5 rounded-lg text-white font-semibold bg-[#0081FB] hover:bg-[#4083c2] transition-all duration-300`}
				>
					<FaPlus className="inline-block mr-2" />
					<span>Add PCVR Eligible Users</span>
				</button>
				<div className={`flex flex-col ${showPCVRUsers ? '' : 'hidden'}`}>
					<div className="overflow-x-auto">
						<table className="styled-table min-w-full rounded-2xl">
							<thead>
								<tr>
									<th>No</th>
									<th>Email</th>
									<th>Time Added</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(dataPCVRUsers).map((eligibleUser, index) => (
									<tr key={eligibleUser}>
										<td className="border border-gray-300 py-2 px-4">
											{index + 1}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRUsers[eligibleUser].email}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{dataPCVRUsers[eligibleUser].timeAdded}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											<button
												className="btn btn-edit p-2"
												onClick={() => handleEditUsersButton_PCVR(eligibleUser)}
											>
												<FaPencil />
											</button>
											<button
												className="btn btn-delete p-2"
												onClick={() => onDelete_PCVRUser(eligibleUser)}
											>
												<FaTrash />
											</button>
											{/* Add link to view if needed */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Helmet>
						<title>Daftar Pengguna - Admin | HyperTopia</title>
					</Helmet>
				</div>
			</div>
		</div>
	)
}

export default ListUsers_Admin
