import React, { createContext, useContext, useEffect, useState } from "react";

const DarkModeContext = createContext();

export function useDarkModeContext() {
  return useContext(DarkModeContext);
}

export function DarkModeProvider({ children }) {
    const [isDarkMode, setIsDarkMode] = useState(() => {
      const savedMode = localStorage.getItem("darkMode");
      return savedMode && typeof savedMode === "string" ? JSON.parse(savedMode) : false;
    });
  
    useEffect(() => {
      document.body.style.backgroundColor = isDarkMode ? '#181818' : '#f0f0f0';
    }, [isDarkMode]);
  
    const toggleDarkMode = () => {
      const newMode = !isDarkMode;
      setIsDarkMode(newMode);
      localStorage.setItem("darkMode", JSON.stringify(newMode));
    };
  
    return (
      <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
        {children}
      </DarkModeContext.Provider>
    );
  }
  