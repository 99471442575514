import React from 'react';
import axios from 'axios';

const DownloadButton = () => {
  const handleDownload = async () => {
    const fileId = '1S0MH4oY9_mh8gl9zUk4Chrs9nE-hSPZN';
    const accessToken = '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';

    try {
      const response = await axios({
        url: `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob', // untuk file binary
      });

      // Simpan file response ke dalam blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'nama-file-yang-diunduh');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <button
      onClick={handleDownload}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Download File
    </button>
  );
};

export default DownloadButton;
