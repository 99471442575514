import React, { useState, useEffect } from 'react'
import { getDatabase, ref, onValue, remove } from 'firebase/database'
import { FaPlus } from 'react-icons/fa'

const FirmwareListPage = () => {
	const [firmwareData, setFirmwareData] = useState([])
	const [selectedFilter, setSelectedFilter] = useState('Semua Quest')
	const isMobile = window.innerWidth < 1200

	useEffect(() => {
		const db = getDatabase()
		const firmwareRef = ref(db, 'firmwareQuest/')
		onValue(firmwareRef, (snapshot) => {
			const data = snapshot.val()
			const firmwareList = []

			for (const quest in data) {
				const questData = data[quest]
				for (const incremental in questData) {
					const firmware = questData[incremental]
					firmwareList.push({
						...firmware,
						quest,
						incremental,
						id: `${quest}-${incremental}`, // Buat ID gabungan
					})
				}
			}

			setFirmwareData(firmwareList)
		})
	}, [])

	const handleDelete = (id) => {
		const [quest, incremental] = id.split('-')
		const db = getDatabase()
		const firmwareRef = ref(db, `firmwareQuest/${quest}/${incremental}`)
		remove(firmwareRef)
			.then(() => {
				setFirmwareData((prevData) =>
					prevData.filter((firmware) => firmware.id !== id)
				)
			})
			.catch((error) => {
				console.error('Error deleting firmware:', error)
			})
	}

	const handleEdit = (id) => {
		window.location.href = `/dba/firmware-quest/update/${id}`
	}

	const filters = [
		'Semua Quest',
		'Meta Quest 1',
		'Meta Quest 2',
		'Meta Quest 3',
		'Meta Quest Pro',
	]

	const filteredFirmware =
		selectedFilter === 'Semua Quest'
			? firmwareData
			: firmwareData.filter((firmware) => firmware.quest === selectedFilter)

	const formatDate = (dateString) => {
		const date = new Date(dateString)
		return new Intl.DateTimeFormat('en-US', {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		}).format(date)
	}

	return (
		<div className={`min-h-screen w-screen p-6 ${!isMobile && 'pt-[100px]'}`}>
			<h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
				Firmware List
			</h1>

			<div className="w-full max-w-4xl mx-auto flex justify-center gap-4 mb-6">
				{filters.map((filter, index) => (
					<button
						key={index}
						onClick={() => setSelectedFilter(filter)}
						className={`px-4 py-2 rounded-lg border-2 transition duration-300 ${
							selectedFilter === filter
								? 'border-blue-600 bg-blue-100 text-blue-600'
								: 'border-zinc-100 bg-zinc-100 text-zinc-500'
						}`}
					>
						{filter}
					</button>
				))}
			</div>

			<div className="overflow-x-auto mb-5">
				<table className="min-w-full bg-white border border-gray-200">
					<thead>
						<tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
							<th className="py-3 px-6 text-left">Quest Type</th>
							<th className="py-3 px-6 text-left">Version</th>
							<th className="py-3 px-6 text-left">Runtime Version</th>
							<th className="py-3 px-6 text-left">Build Date</th>
							<th className="py-3 px-6 text-left">Download</th>
							<th className="py-3 px-6 text-left">Actions</th>{' '}
							{/* Kolom Action */}
						</tr>
					</thead>
					<tbody className="text-gray-600 text-sm font-light">
						{filteredFirmware.length > 0 ? (
							filteredFirmware.map((firmware) => (
								<tr
									key={firmware.id}
									className="border-b border-gray-200 hover:bg-gray-100"
								>
									<td className="py-3 px-6 text-left whitespace-nowrap">
										<span className="font-medium">{firmware.quest}</span>
									</td>
									<td className="py-3 px-6 text-left">{firmware.version}</td>
									<td className="py-3 px-6 text-left">
										{firmware.runtimeVersion}
									</td>
									<td className="py-3 px-6 text-left">
										{formatDate(firmware.buildDate)}
									</td>
									<td className="py-3 px-6 text-left">
										<a
											href={firmware.downloadLink}
											target="_blank"
											rel="noopener noreferrer"
											className="text-blue-500 hover:underline"
										>
											Download
										</a>
									</td>
									<td className="py-3 px-6 text-left">
										<button
											onClick={() => handleEdit(firmware.id)}
											className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
										>
											Edit
										</button>
										<button
											onClick={() => handleDelete(firmware.id)}
											className="bg-red-500 text-white px-4 py-2 rounded-md"
										>
											Delete
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="6" className="text-center py-6">
									No firmware data available
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<a
				href="/dba/firmware-quest/add"
				className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 w-full flex flex-row items-center justify-center gap-2"
			>
				<p>Add Firmware</p>
				<FaPlus />
			</a>
		</div>
	)
}

export default FirmwareListPage
