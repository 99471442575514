import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import fireDb from '../../firebase'
import toast, { Toaster } from 'react-hot-toast'
import { FaPencil, FaTrash } from 'react-icons/fa6'
import { Helmet } from 'react-helmet'
import { FaArrowLeft } from 'react-icons/fa'
import { useDarkModeContext } from '../../DarkModeContext'

const initialState_MPStatus = {
	gameName: '',
	multiplayer: 'no',
	maxPlayers: '',
	notes: '',
	lastUpdated: '',
}

const storedLanguage = localStorage.getItem('language') || 'id'

const AddEdit_MultiplayerStatus = () => {
	const { id } = useParams()
	const [language, setLanguage] = useState(storedLanguage)
	const [state_MPStatus, setState_MPStatus] = useState(initialState_MPStatus)
	const { isDarkMode } = useDarkModeContext()
	const navigate = useNavigate()

	useEffect(() => {
		if (id) {
			fireDb.ref(`vrGames/multiplayerStatus/${id}`).on('value', (snapshot) => {
				const existingData = snapshot.val()
				if (existingData) {
					setState_MPStatus({
						gameName: existingData.gameName || '',
						multiplayer: existingData.multiplayer || '',
						maxPlayers: existingData.maxPlayers || '',
						notes: existingData.notes || '',
						lastUpdated: existingData.lastUpdated || '',
					})
				} else {
					setState_MPStatus(initialState_MPStatus)
				}
			})
		}

		return () => {
			fireDb.ref(`vrGames/multiplayerStatus/${id}`).off()
		}
	}, [id])

	const handleInputChange_MPStatus = (e) => {
		const { name, value, type, checked } = e.target
		setState_MPStatus({
			...state_MPStatus,
			[name]: type === 'checkbox' ? checked : value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!state_MPStatus.gameName) {
			toast.error('Please provide a game name')
		} else {
			const MPData = {
				gameName: state_MPStatus.gameName,
				multiplayer: state_MPStatus.multiplayer,
				maxPlayers: state_MPStatus.maxPlayers,
				notes: state_MPStatus.notes,
				lastUpdated: state_MPStatus.lastUpdated,
			}

			if (!id) {
				fireDb
					.ref(`vrGames/multiplayerStatus/${state_MPStatus.gameName}`)
					.set(MPData, (err) => {
						if (err) {
							toast.error(err)
						} else {
							toast.success('Multiplayer Status Added Successfully!')
						}
					})
			} else {
				fireDb.ref(`vrGames/multiplayerStatus/${id}`).update(MPData, (err) => {
					if (err) {
						toast.error(err)
					} else {
						toast.success('Multiplayer Status Updated Successfully!')
					}
				})
			}

			setTimeout(
				() => navigate('/vr-games/multiplayer-status', { replace: true }),
				500
			)
		}
	}

	return (
		<div className="mx-5 pt-6 mb-10 w-full">
			<Toaster />
			<div className="flex flex-col items-center justify-center">
				<form
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'}`}
					onSubmit={handleSubmit}
				>
					<Link
						to="/vr-games/multiplayer-status"
						className="flex items-center w-full justify-center py-2 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
					>
						<FaArrowLeft className="mr-2" />
						Back to List Multiplayer Game Status
					</Link>

					<hr className="border-[1px] border-gray-300 rounded" />
					<>
						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="gameName">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Game Name
								</span>
							</label>
							<input
								type="text"
								id="gameName"
								name="gameName"
								placeholder="Fill Here"
								value={state_MPStatus.gameName || ''}
								onChange={handleInputChange_MPStatus}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64">Multiplayer</label>
							<div className="flex gap-2 items-center w-full">
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_MPStatus.multiplayer === 'yes' ? 'bg-gradient-to-br from-green-300 to-green-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="multiplayer"
										value="yes"
										checked={state_MPStatus.multiplayer === 'yes'}
										onChange={handleInputChange_MPStatus}
										className="hidden"
										id="newStatusRadio"
									/>
									<label htmlFor="newStatusRadio" className="cursor-pointer">
										Yes
									</label>
								</div>
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_MPStatus.multiplayer === 'no' ? 'bg-gradient-to-br from-rose-300 to-rose-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="multiplayer"
										value="no"
										checked={state_MPStatus.multiplayer === 'no'}
										onChange={handleInputChange_MPStatus}
										className="hidden"
										id="noStatusRadio"
									/>
									<label htmlFor="noStatusRadio" className="cursor-pointer">
										No
									</label>
								</div>
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_MPStatus.multiplayer === 'partially' ? 'bg-gradient-to-br from-yellow-300 to-yellow-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="multiplayer"
										value="partially"
										checked={state_MPStatus.multiplayer === 'partially'}
										onChange={handleInputChange_MPStatus}
										className="hidden"
										id="partiallyStatusRadio"
									/>
									<label
										htmlFor="partiallyStatusRadio"
										className="cursor-pointer"
									>
										Partially
									</label>
								</div>
							</div>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="maxPlayers">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Max Players
								</span>
							</label>
							<input
								type="text"
								id="maxPlayers"
								name="maxPlayers"
								placeholder="Fill Here"
								value={state_MPStatus.maxPlayers || ''}
								onChange={handleInputChange_MPStatus}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="notes">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Notes
								</span>
							</label>
							<input
								type="text"
								id="notes"
								name="notes"
								placeholder="Fill Here"
								value={state_MPStatus.notes || ''}
								onChange={handleInputChange_MPStatus}
								className={`rounded p-2 input-box w-full`}
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="lastUpdated">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Last Updated
								</span>
							</label>
							<input
								type="text"
								id="lastUpdated"
								name="lastUpdated"
								placeholder="Fill Here"
								value={state_MPStatus.lastUpdated || ''}
								onChange={handleInputChange_MPStatus}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<input
							className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
							type="submit"
							value={id ? 'Update' : 'Save'}
						/>
					</>
				</form>

				<Helmet>
					<title>
						{id ? 'Edit Multiplayer Status' : 'Add Multiplayer Status'} | Admin
					</title>
				</Helmet>
			</div>

			<style>
				{`
        .input-box {
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
        `}
			</style>
		</div>
	)
}

export default AddEdit_MultiplayerStatus
