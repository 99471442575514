import React from 'react';
import { useNetworkState } from 'react-use';

const Settings = () => {
  const networkState = useNetworkState();
  console.log(networkState);

  return (
    <div>
      <h2>Network Status</h2>
      <p>Online: {networkState.online ? 'Yes' : 'No'}</p>
      <p>Type: {networkState.type}</p>
    </div>
  );
};

export default Settings;
