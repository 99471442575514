import React, { useState } from 'react'
import YouTube from 'react-youtube'
import {
	FaFile,
	FaWindows,
	FaApple,
	FaUbuntu,
	FaChevronDown,
	FaChevronUp,
	FaFolder,
	FaArrowRight,
} from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import { motion, AnimatePresence } from 'framer-motion'
import { FaBookOpen } from 'react-icons/fa6'
import { RiInboxArchiveFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const TutorialCard = ({ title, videoId, content, isExpanded, onToggle }) => (
	<motion.div
		initial={{ opacity: 0, y: 20 }}
		animate={{ opacity: 1, y: 0 }}
		transition={{ duration: 0.5 }}
		className="w-full bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden"
	>
		<div
			className="flex items-center justify-between p-4 cursor-pointer hover:bg-[#f0f8ff] transition"
			onClick={onToggle}
		>
			<h3 className="text-lg md:text-xl font-bold text-[#0081FB]">{title}</h3>
			<div className="text-[#0081FB]">
				{isExpanded ? <FaChevronUp /> : <FaChevronDown />}
			</div>
		</div>
		<AnimatePresence initial={false}>
			{isExpanded && (
				<motion.div
					initial={{ height: 0, opacity: 0 }}
					animate={{ height: 'auto', opacity: 1 }}
					exit={{ height: 0, opacity: 0 }}
					transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
					className="overflow-hidden"
				>
					<div className="p-4 border-t border-gray-200">
						{videoId && (
							<YouTube
								videoId={videoId}
								opts={{ height: '200', width: '100%' }}
								className="mb-4 rounded-lg"
							/>
						)}
						<div className="text-gray-700">{content}</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	</motion.div>
)

const FileDownloadButton = ({ href, children }) => (
	<a
		href={href}
		target="_blank"
		rel="noreferrer"
		className="flex items-center gap-2 px-4 py-2 mt-2 w-full justify-center text-[#0081FB] border border-[#0081FB] rounded-lg hover:bg-[#0081FB] hover:text-white transition"
	>
		{children}
	</a>
)

const Instructions = ({ language }) => {
	const steps = {
		en: {
			title: 'Steps to Activate Developer Mode on Meta Quest Headset',
			steps: [
				{
					title: 'Create a Meta Developer Account',
					items: [
						<>
							Visit the{' '}
							<a
								href="https://developer.oculus.com/manage/organizations/"
								className="text-blue-500 underline"
							>
								Meta Developer
							</a>{' '}
							page and log in with your Meta account.
						</>,
						'Create a developer organization:',
						[
							'Click Create New Organization and enter an organization name.',
							'Agree to the Developer Non-Disclosure Agreement.',
						],
					],
				},
				{
					title: 'Enable Developer Mode in the Meta Quest App',
					items: [
						'Open the Meta Quest app on your phone and connect the Quest headset to the app.',
						'Go to Settings > Devices and select your Quest device.',
						'Enable Developer Mode by switching it to On.',
					],
				},
				{
					title: 'Restart the Quest Headset',
					items: [
						'After enabling Developer Mode, restart your Quest headset to activate it.',
					],
				},
				{
					title: 'Install SideQuest',
					items: [
						<>
							Download the SideQuest app from the{' '}
							<a
								href="https://sidequestvr.com/"
								className="text-blue-500 underline"
							>
								official website
							</a>
							.
						</>,
						'Connect the headset to your computer using a USB cable, and allow data access on the headset.',
						'Once connected, you can use SideQuest to install additional apps.',
					],
				},
			],
		},
		id: {
			title:
				'Langkah-langkah Mengaktifkan Developer Mode di Headset Meta Quest',
			steps: [
				{
					title: 'Buat Akun Developer Meta',
					items: [
						<>
							Kunjungi halaman{' '}
							<a
								href="https://developer.oculus.com/manage/organizations/"
								className="text-blue-500 underline"
							>
								Meta Developer
							</a>{' '}
							dan login dengan akun Meta Anda.
						</>,
						'Buat organisasi developer:',
						[
							'Klik Buat Organisasi Baru dan masukkan nama organisasi.',
							'Setujui Perjanjian Non-Disclosure Developer.',
						],
					],
				},
				{
					title: 'Aktifkan Developer Mode di Aplikasi Meta Quest',
					items: [
						'Buka aplikasi Meta Quest di ponsel Anda dan hubungkan headset Quest ke aplikasi.',
						'Masuk ke Pengaturan > Perangkat dan pilih perangkat Quest Anda.',
						'Aktifkan Developer Mode dengan menggeser tombol ke posisi Aktif.',
					],
				},
				{
					title: 'Restart Headset Quest',
					items: [
						'Setelah Developer Mode diaktifkan, restart headset Quest Anda untuk mengaktifkan fitur tersebut.',
					],
				},
				{
					title: 'Install SideQuest',
					items: [
						<>
							Unduh aplikasi SideQuest dari{' '}
							<a
								href="https://sidequestvr.com/"
								className="text-blue-500 underline"
							>
								situs resmi
							</a>
							.
						</>,
						'Hubungkan headset ke komputer menggunakan kabel USB, dan izinkan akses data di headset.',
						'Setelah terhubung, Anda dapat menggunakan SideQuest untuk mengunduh aplikasi tambahan.',
					],
				},
			],
		},
	}

	const content = steps[language] || steps.en // Default to English if language is not recognized

	return (
		<div className="p-4">
			<ol className="list-decimal ml-6 space-y-4">
				{content.steps.map((step, index) => (
					<li key={index} className="mb-2">
						<strong>{step.title}</strong>
						<ol className="list-[lower-alpha] ml-6 space-y-2">
							{step.items.map((item, subIndex) =>
								Array.isArray(item) ? (
									<ol
										key={subIndex}
										className="list-[lower-roman] ml-6 space-y-1"
									>
										{item.map((subItem, subSubIndex) => (
											<li key={subSubIndex}>{subItem}</li>
										))}
									</ol>
								) : (
									<li key={subIndex}>{item}</li>
								)
							)}
						</ol>
					</li>
				))}
			</ol>
		</div>
	)
}

const Tutorials = () => {
	const language = localStorage.getItem('language') || 'id'
	const translations = {
		en: {
			tutorial: 'Tutorials',
			vrGames: 'VR Games',
			howToEnableDeveloperModeOnQuestHeadsetToUseSideQuest:
				'How to Enable Developer Mode on Meta Quest to Use SideQuest',

			// HOW TO INSTALL THE GAME
			generalGuideHowToInstallGames: 'General Guide How to Install Games',
			withoutOBBFolder: 'Without OBB Folder or only .APK File',
			withOBBFolder: 'With OBB Folder',
			step1InstallTheGameWithoutOBB:
				'Download the game file from the List Games.',
			step2InstallTheGameWithoutOBB:
				'Extract the downloaded file using WinRAR or 7zip.',
			step3InstallTheGameWithoutOBB:
				'Open SideQuest app on your PC, then connect your Quest headset to your PC using USB cable.',
			step4InstallTheGameWithoutOBBPart1:
				'Click icon box with arrow down on top corner',
			step4InstallTheGameWithoutOBBPart2:
				'(the 5th icon from left) on SideQuest app, then choose the',
			step4InstallTheGameWithoutOBBPart3: 'APK File.',
			step5InstallTheGameWithoutOBB:
				'Wait for the installation process to complete.',
			step6InstallTheGameWithoutOBBPart1:
				'Done! You can now play the game on your Quest headset. Open the Library Apps, then on top corner, choose teh first dropdown menu, then change it to',
			step6InstallTheGameWithoutOBBPart2: 'Then choose the game to play.',
			step7InstallTheGameWithoutOBB: 'Enjoy the game!',

			step6InstallTheGameWithOBB:
				'Click folder icon on top right corner of SideQuest',
			step7InstallTheGameWithOBB:
				'then go to Folder Android > OBB, then open the extracted folder, then drag and drop the OBB folder to the OBB folder.',

			stepDownloadAndInstallSideQuest:
				'Download and Install SideQuest app on your PC.',

			// HOW TO INSTALL BEAT SABER CUSTOM SONGS MOD
			howToInstallBeatSaberCustomSongsMod:
				'How to Install Beat Saber Custom Songs Mod',
			step1InstallBeatSaberCustomSongsMod:
				'Download the Beat Saber (Multi Install as Beat Paber) on List Games.',
			step2InstallBeatSaberCustomSongsMod:
				'Extract the downloaded file using WinRAR or 7zip.',
			step3InstallBeatSaberCustomSongsMod:
				'Open SideQuest app on your PC, then connect your Quest headset to your PC using USB cable.',
			step4InstallBeatSaberCustomSongsModPart1:
				'Open the extracted folder and install BeatSaber 1.28.0.apk and BMBFv1.17.0.apk',
			step4InstallBeatSaberCustomSongsModPart2:
				'to your Quest headset using SideQuest app.',
			step5InstallBeatSaberCustomSongsMod:
				'Open [Oculus Quest 2] folder, then Android, then data, copy and paste com.beatgames.beatsaber folder to your Quest headset. Do the same steps for inside OBB folder ([Oculus Quest 2] - Android - OBB).',
			step6InstallBeatSaberCustomSongsMod:
				'Then back to previous folder before data/OBB, then copy and paste BMBF folder to your Quest headset.',
			step7InstallBeatSaberCustomSongsMod:
				'Open BMBF app on your Quest headset, then click "Sync to Beat Saber" button.',
			step8InstallBeatSaberCustomSongsMod:
				'Done! You can now play Beat Saber with custom songs on your Quest headset.',

			haveManyRARPartsWithOBB:
				'Have Many RAR Parts (.part1, .part2, etc.) - With OBB Folder',
			step2InstallManyParts:
				'Extract only one part of the downloaded file using WinRAR or 7zip. The other parts will be automatically merged. So no need to extract one by one.',

			onlyForQuest2: 'Quest 2',
			onlyForQuest3: 'Quest 3 & Quest 3S',

			neededFiles:
				'Please download the additional files needed below (REQUIRED):',
		},
		id: {
			tutorial: 'Tutorial',
			vrGames: 'Game VR',
			howToEnableDeveloperModeOnQuestHeadsetToUseSideQuest:
				'Cara Mengaktifkan Developer Mode di Meta Quest untuk Menggunakan SideQuest',

			// HOW TO INSTALL THE GAME
			generalGuideHowToInstallGames: 'Panduan Umum Cara Instal Game',
			withoutOBBFolder: 'Tanpa Folder OBB atau Hanya File .APK',
			withOBBFolder: 'Dengan Folder OBB',
			step1InstallTheGameWithoutOBB: 'Download file game dari Daftar Game.',
			step2InstallTheGameWithoutOBB:
				'Ekstrak file yang sudah di download menggunakan WinRAR atau 7zip.',
			step3InstallTheGameWithoutOBB:
				'Buka aplikasi SideQuest di PC, lalu hubungkan headset Quest ke PC menggunakan kabel USB.',
			step4InstallTheGameWithoutOBBPart1:
				'Klik icon kotak dengan panah kebawah di pojok atas',
			step4InstallTheGameWithoutOBBPart2:
				'(icon ke-5 dari kiri) pada aplikasi SideQuest, lalu pilih',
			step4InstallTheGameWithoutOBBPart3: 'File .APK',
			step5InstallTheGameWithoutOBB: 'Tunggu proses instalasi selesai.',
			step6InstallTheGameWithoutOBBPart1:
				'Selesai! Kamu sudah bisa memainkan game di headset Quest. Buka Library Apps, lalu di pojok atas, pilih dropdown menu pertama, lalu ganti ke',
			step6InstallTheGameWithoutOBBPart2:
				'Lalu pilih game yang ingin dimainkan.',
			step7InstallTheGameWithoutOBB: 'Selamat bermain!',

			step6InstallTheGameWithOBB:
				'Klik icon folder di pojok kanan atas SideQuest',
			step7InstallTheGameWithOBB:
				'kemudian ke Folder Android > OBB, kemudian buka folder yang sudah diekstrak, lalu seret dan lepas folder OBB tadi di folder OBB tersebut.',

			stepDownloadAndInstallSideQuest:
				'Download dan Install aplikasi SideQuest di PC.',

			// HOW TO INSTALL BEAT SABER CUSTOM SONGS MOD
			howToInstallBeatSaberCustomSongsMod:
				'Cara Install Beat Saber Custom Songs Mod',
			step1InstallBeatSaberCustomSongsMod:
				'Download Beat Saber (Multi Install as Beat Paber) di List Games.',
			step2InstallBeatSaberCustomSongsMod:
				'Ekstrak file yang sudah di download menggunakan WinRAR atau 7zip.',
			step3InstallBeatSaberCustomSongsMod:
				'Buka aplikasi SideQuest di PC, lalu hubungkan headset Quest ke PC menggunakan kabel USB.',
			step4InstallBeatSaberCustomSongsModPart1:
				'Buka folder yang sudah di ekstrak, lalu install BeatSaber 1.28.0.apk dan BMBFv1.17.0.apk',
			step4InstallBeatSaberCustomSongsModPart2:
				'ke headset Quest menggunakan aplikasi SideQuest.',
			step5InstallBeatSaberCustomSongsMod:
				'Buka folder [Oculus Quest 2], lalu Android, lalu data, copy dan paste folder com.beatgames.beatsaber ke headset Quest. Lakukan hal yang sama untuk folder OBB di dalamnya ([Oculus Quest 2] - Android - OBB).',
			step6InstallBeatSaberCustomSongsMod:
				'Lalu kembali ke folder sebelum data/OBB, lalu copy dan paste folder BMBF ke headset Quest.',
			step7InstallBeatSaberCustomSongsMod:
				'Buka aplikasi BMBF di headset Quest, lalu klik tombol "Sync to Beat Saber".',
			step8InstallBeatSaberCustomSongsMod:
				'Selesai! Kamu sudah bisa memainkan Beat Saber dengan lagu-lagu custom di headset Quest.',

			haveManyRARPartsWithOBB:
				'Punya Banyak Part RAR (.part1, .part2, dll) - Dengan Folder OBB',
			step2InstallManyParts:
				'Ekstrak salah satu part saja dari yang sudah di download menggunakan WinRAR atau 7zip. Part lainnya akan secara otomatis tergabung. Jadi tidak perlu ekstrak satu per satu.',

			onlyForQuest2: 'Quest 2',
			onlyForQuest3: 'Quest 3 & Quest 3S',

			neededFiles:
				'Silahkan unduh file tambahan yang dibutuhkan berikut (WAJIB):',
		},
	}

	const tutorialData = [
		{
			title:
				translations[language]
					.howToEnableDeveloperModeOnQuestHeadsetToUseSideQuest,
			videoId: 'TWHrvQ3VTJQ',
			content: <Instructions language={language} />,
		},
		{
			title: translations[language].generalGuideHowToInstallGames,
			videoId: 'xhbEG2e1_LE',
			content: (
				<div className="p-3">
					<h4 className="font-semibold text-white my-2 bg-[#0081FB] px-3 py-1 rounded-lg w-fit">
						{translations[language].withoutOBBFolder}
					</h4>
					<ul className="list-none list-inside mb-4 text-gray-700">
						<li>
							<span className="font-semibold">Step 1:</span>{' '}
							{translations[language].step1InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 2:</span>{' '}
							{translations[language].step2InstallTheGameWithoutOBB}
						</li>
						<li className="flex flex-row gap-2 items-center">
							<span className="font-semibold">Step 3:</span>{' '}
							{translations[language].stepDownloadAndInstallSideQuest}
							<Link to="https://hypertopia.shop/vr-games/software-helper">
								<button className="px-2 py-1 rounded-lg bg-[#0081FB] text-white flex flex-row gap-2 items-center justify-center hover:bg-sky-600 transition-all duration-300">
									<FaFile />
									<p>SideQuest</p>
								</button>
							</Link>
						</li>
						<li>
							<span className="font-semibold">Step 4:</span>{' '}
							{translations[language].step3InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 5:</span>{' '}
							{translations[language].step4InstallTheGameWithoutOBBPart1}{' '}
							<RiInboxArchiveFill className={`inline-block text-3xl`} />{' '}
							{translations[language].step4InstallTheGameWithoutOBBPart2}{' '}
							<span className={`font-semibold text-[#0081FB]`}>
								{translations[language].step4InstallTheGameWithoutOBBPart3}
							</span>
							.
						</li>
						<li>
							<span className="font-semibold">Step 6:</span>{' '}
							{translations[language].step5InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 7:</span>{' '}
							{translations[language].step6InstallTheGameWithoutOBBPart1}{' '}
							<span className={`font-semibold text-[#0081FB]`}>
								Unknown Sources
							</span>
							. {translations[language].step6InstallTheGameWithoutOBBPart2}
						</li>
						<li>
							<span className="font-semibold">Step 8:</span>{' '}
							{translations[language].step7InstallTheGameWithoutOBB}
						</li>
					</ul>
					<h4 className="font-semibold text-white my-2 bg-[#0081FB] px-3 py-1 rounded-lg w-fit">
						{translations[language].withOBBFolder}
					</h4>
					<ul className="list-none list-inside text-gray-700">
						<li>
							<span className="font-semibold">Step 1:</span>{' '}
							{translations[language].step1InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 2:</span>{' '}
							{translations[language].step2InstallTheGameWithoutOBB}
						</li>
						<li className="flex flex-row gap-2 items-center">
							<span className="font-semibold">Step 3:</span>{' '}
							{translations[language].stepDownloadAndInstallSideQuest}
							<Link to="https://hypertopia.shop/vr-games/software-helper">
								<button className="px-2 py-1 rounded-lg bg-[#0081FB] text-white flex flex-row gap-2 items-center justify-center hover:bg-sky-600 transition-all duration-300">
									<FaFile />
									<p>SideQuest</p>
								</button>
							</Link>
						</li>
						<li>
							<span className="font-semibold">Step 4:</span>{' '}
							{translations[language].step3InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 5:</span>{' '}
							{translations[language].step4InstallTheGameWithoutOBBPart1}{' '}
							<RiInboxArchiveFill className={`inline-block text-xl`} />{' '}
							{translations[language].step4InstallTheGameWithoutOBBPart2}{' '}
							<span className={`font-semibold text-[#0081FB]`}>
								{translations[language].step4InstallTheGameWithoutOBBPart3}
							</span>
							.
						</li>
						<li>
							<span className="font-semibold">Step 6:</span>{' '}
							{translations[language].step5InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 7:</span>{' '}
							{translations[language].step6InstallTheGameWithOBB}{' '}
							<FaFolder className={`inline-block text-xl`} />,{' '}
							{translations[language].step7InstallTheGameWithOBB}{' '}
							<span className={`font-semibold text-[#0081FB]`}>
								(Ex: Resident Evil 4 VR{' '}
								<FaArrowRight className={`inline-block`} /> com.Armature.VR4)
							</span>
						</li>
						<li>
							<span className="font-semibold">Step 8:</span>{' '}
							{translations[language].step7InstallTheGameWithoutOBB}
						</li>
					</ul>
					<h4 className="font-semibold text-white my-2 bg-[#0081FB] px-3 py-1 rounded-lg w-fit">
						{translations[language].haveManyRARPartsWithOBB}
					</h4>
					<ul className="list-disc list-inside text-gray-700">
						<li>
							<span className="font-semibold">Step 1:</span>{' '}
							{translations[language].step1InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 2:</span>{' '}
							{translations[language].step2InstallManyParts}
						</li>
						<li className="flex flex-row gap-2 items-center">
							<span className="font-semibold">Step 3:</span>{' '}
							{translations[language].stepDownloadAndInstallSideQuest}
							<Link to="https://hypertopia.shop/vr-games/software-helper">
								<button className="px-2 py-1 rounded-lg bg-[#0081FB] text-white flex flex-row gap-2 items-center justify-center hover:bg-sky-600 transition-all duration-300">
									<FaFile />
									<p>SideQuest</p>
								</button>
							</Link>
						</li>
						<li>
							<span className="font-semibold">Step 4:</span>{' '}
							{translations[language].step3InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 5:</span>{' '}
							{translations[language].step4InstallTheGameWithoutOBBPart1}{' '}
							<RiInboxArchiveFill className={`inline-block text-xl`} />{' '}
							{translations[language].step4InstallTheGameWithoutOBBPart2}{' '}
							<span className={`font-semibold text-[#0081FB]`}>
								{translations[language].step4InstallTheGameWithoutOBBPart3}
							</span>
							.
						</li>
						<li>
							<span className="font-semibold">Step 6:</span>{' '}
							{translations[language].step5InstallTheGameWithoutOBB}
						</li>
						<li>
							<span className="font-semibold">Step 7:</span>{' '}
							{translations[language].step6InstallTheGameWithOBB}{' '}
							<FaFolder className={`inline-block text-xl`} />,{' '}
							{translations[language].step7InstallTheGameWithOBB}{' '}
							<span className={`font-semibold text-[#0081FB]`}>
								(Ex: Resident Evil 4 VR{' '}
								<FaArrowRight className={`inline-block`} /> com.Armature.VR4)
							</span>
						</li>
						<li>
							<span className="font-semibold">Step 8:</span>{' '}
							{translations[language].step7InstallTheGameWithoutOBB}
						</li>
					</ul>
				</div>
			),
		},
		{
			title: `${translations[language].howToInstallBeatSaberCustomSongsMod} (${translations[language].onlyForQuest2})`,
			videoId: '',
			content: (
				<div className="p-3">
					<ul className="list-none list-inside mb-4 text-gray-700">
						<li className="flex flex-row gap-2 items-center">
							<span className="font-semibold">Step 1:</span>{' '}
							{translations[language].step1InstallBeatSaberCustomSongsMod}
							<Link to="https://hypertopia.shop/vr-games/list-games/standalone?keyword=Beat%20Saber%20(Multi%20Install%20as%20Beat%20Paber)">
								<button className="px-2 py-1 rounded-lg bg-[#0081FB] text-white flex flex-row gap-2 items-center justify-center hover:bg-sky-600 transition-all duration-300">
									<FaFile />
									<p>Beat Saber (Multi Install as Beat Paber)</p>
								</button>
							</Link>
						</li>
						<li>
							<span className="font-semibold">Step 2:</span>{' '}
							{translations[language].step2InstallBeatSaberCustomSongsMod}
						</li>
						<li>
							<span className="font-semibold">Step 3:</span>{' '}
							{translations[language].step3InstallBeatSaberCustomSongsMod}
						</li>
						<li>
							<span className="font-semibold">Step 4:</span>{' '}
							{translations[language].step4InstallBeatSaberCustomSongsModPart1}{' '}
							{translations[language].step4InstallBeatSaberCustomSongsModPart2}
						</li>
						<li>
							<span className="font-semibold">Step 5:</span>{' '}
							{translations[language].step5InstallBeatSaberCustomSongsMod}
						</li>
						<li>
							<span className="font-semibold">Step 6:</span>{' '}
							{translations[language].step6InstallBeatSaberCustomSongsMod}
						</li>
						<li>
							<span className="font-semibold">Step 7:</span>{' '}
							{translations[language].step7InstallBeatSaberCustomSongsMod}
						</li>
						<li>
							<span className="font-semibold">Step 8:</span>{' '}
							{translations[language].step8InstallBeatSaberCustomSongsMod}
						</li>
					</ul>
				</div>
			),
		},
		{
			title: `${translations[language].howToInstallBeatSaberCustomSongsMod} (${translations[language].onlyForQuest3})`,
			videoId: 'TIdsH3BtazY',
			content: (
				<div className="flex flex-col gap-2">
					<p className="text-gray-700">{translations[language].neededFiles}</p>
					<FileDownloadButton href="https://drive.google.com/file/d/1EyaaebRDqhVJ4TIRnNGN5wWRDar-nl1A/view?usp=drive_link">
						<FaFile /> OculusDB_CoreMods.qmod
					</FileDownloadButton>
					<p className="font-bold mt-4 text-gray-700">Quest Patcher:</p>
					<div className="flex gap-2">
						<FileDownloadButton href="https://drive.google.com/file/d/1hVOvoV-b0oo3Cnu0RDb8JltGXmzcPMJS/view?usp=drive_link">
							<FaWindows /> Windows
						</FileDownloadButton>
						<FileDownloadButton href="https://drive.google.com/file/d/1hDfHS3EW1LF9nHpeMyOnr7dgctWCLfLV/view?usp=drive_link">
							<FaApple /> MacOS
						</FileDownloadButton>
						<FileDownloadButton href="https://drive.google.com/file/d/1xrD9-pJhpnRkanxX12K279S2DgNKkQ_t/view?usp=drive_link">
							<FaUbuntu /> Ubuntu
						</FileDownloadButton>
					</div>
				</div>
			),
		},
	]

	const [expandedIndex, setExpandedIndex] = useState(null)

	const toggleExpand = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index)
	}

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`w-full min-h-screen pt-10 pb-10 ${!isMobile && 'pt-[100px]'}`}
		>
			<div className="container mx-auto max-w-full px-5">
				<Helmet>
					<title>{`${translations[language].tutorial} | ${translations[language].vrGames} - HyperTopia`}</title>
				</Helmet>

				<h1 className="flex flex-row gap-4 items-center justify-center text-3xl font-bold mb-4 text-center text-[#0081FB]">
					<FaBookOpen className="text-3xl md:text-4xl" />
					<p>{translations[language].tutorial}</p>
				</h1>

				<div className="space-y-6">
					{tutorialData.map((tutorial, index) => (
						<TutorialCard
							key={index}
							title={tutorial.title}
							videoId={tutorial.videoId}
							content={tutorial.content}
							isExpanded={expandedIndex === index}
							onToggle={() => toggleExpand(index)}
						/>
					))}
				</div>

				{/* Decorative Circles */}
				<div className="absolute top-10 left-5 w-32 h-32 bg-blue-100 opacity-30 rounded-full"></div>
				<div className="absolute bottom-10 right-5 w-24 h-24 bg-blue-100 opacity-30 rounded-full"></div>
			</div>
		</div>
	)
}

export default Tutorials
