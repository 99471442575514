import React, { useState, useEffect, useRef, useCallback } from 'react'
import ProductsCard from './ProductsCard'
import { useDarkModeContext } from '../../DarkModeContext'
import { Helmet } from 'react-helmet'
import fireDb from '../../firebase'
import {
	FaSortAlphaDown,
	FaSortAlphaDownAlt,
	FaSortNumericDown,
	FaSortNumericDownAlt,
} from 'react-icons/fa'

import { auth } from '../../firebase'
import { FiSearch, FiX } from 'react-icons/fi'
import { FaRegClock } from 'react-icons/fa6'
import Swal from 'sweetalert2'

const Products = () => {
	const { isDarkMode } = useDarkModeContext()
	const [products, setProducts] = useState([])
	const [lastUpdated, setLastUpdated] = useState('')
	const language = localStorage.getItem('language') || 'id'
	const [sortOrder, setSortOrder] = useState('asc')
	const [sortBy, setSortBy] = useState('name')
	const showFiltersRef = useRef(null)
	const [user, setUser] = useState(null)
	const [loadingProducts, setLoadingProducts] = useState(true)
	const [searchTerm, setSearchTerm] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [searchHistory, setSearchHistory] = useState([])
	const [showSearchHistory, setShowSearchHistory] = useState(false)

	const handleSearchBoxClick = () => {
		if (searchHistory.length > 0 && searchTerm === '') {
			setShowSearchHistory(true)
		}
	}

	const addToSearchHistory = useCallback(
		(term) => {
			if (term.trim() !== '') {
				const updatedHistory = [
					...searchHistory.filter((item) => item !== term),
				]
				updatedHistory.unshift(term)
				setSearchHistory(updatedHistory.slice(0, 5))
				localStorage.setItem('searchHistory', JSON.stringify(updatedHistory))
			}
		},
		[searchHistory]
	)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const translations = {
		en: {
			info: 'Info',
			productsInfo:
				'This page contains a list of products available for purchase. Please use the search box to find the product you are looking for.',
			lastSearches: 'Last Searches:',
			lastUpdated: 'Last Updated:',
			hideEmptyDownload: 'Hide Empty Download',
			unknownTime: 'Unknown Time',
			sortBy: 'Sort By:',
			name: 'Name',
			size: 'Size',
			options: 'Options',
			supportedQuests: 'Supported Quests:',

			searchProducts: 'Search Products',

			accessDownloadAnnouncement: 'Please login first to access download link!',

			listProducts: 'List Products',
		},
		id: {
			info: 'Info',
			productsInfo:
				'Halaman ini berisi daftar produk yang tersedia untuk dibeli. Silakan gunakan kotak pencarian untuk menemukan produk yang Anda cari.',
			lastSearches: 'Pencarian Terakhir:',
			lastUpdated: 'Terakhir Diperbarui:',
			hideEmptyDownload: 'Sembunyikan Tanpa Link Download',
			unknownTime: 'Waktu Tidak Diketahui',
			sortBy: 'Urutkan:',
			name: 'Nama',
			size: 'Ukuran',
			options: 'Opsi',
			supportedQuests: 'Quest yang Didukung:',

			searchProducts: 'Cari Produk',

			accessDownloadAnnouncement:
				'Silahkan login terlebih dahulu untuk mengakses link download!',

			listProducts: 'Daftar Produk',
		},
	}

	useEffect(() => {
		const productsRef = fireDb.ref('products')
		productsRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const productsData = Object.values(snapshot.val())

				const sortedProductsData = productsData.sort((a, b) => {
					return sortBy === 'name'
						? a.productName.localeCompare(
								b.productName,
								language === 'en' ? 'en-US' : 'id-ID'
							)
						: 0
				})

				setProducts(
					sortOrder === 'asc'
						? sortedProductsData
						: sortedProductsData.reverse()
				)
				setLoadingProducts(false)

				const latestTimeAdded = new Date(
					Math.max(
						...sortedProductsData.map((product) => new Date(product.timeAdded))
					)
				)

				if (!isNaN(latestTimeAdded.getTime())) {
					const formattedDate = latestTimeAdded.toLocaleString(
						language === 'en' ? 'en-US' : 'id-ID',
						{
							year: 'numeric',
							month: 'long',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
							hour23: true,
							timeZoneName: 'short',
						}
					)
					setLastUpdated(`${formattedDate}`)
				}
			} else {
				setProducts([])
			}
		})

		return () => {
			productsRef.off('value')
		}
	}, [language, sortOrder, sortBy])

	const [searchTimeout, setSearchTimeout] = useState(null)

	useEffect(() => {
		const timeout = setTimeout(() => {
			const results = products.filter((product) =>
				product.productName.toLowerCase().includes(searchTerm.toLowerCase())
			)
			setSearchResults(results)
			if (searchTerm.trim() !== '') {
				addToSearchHistory(searchTerm)
			}
		}, 1000)

		return () => clearTimeout(timeout)
	}, [searchTerm, products, addToSearchHistory])

	useEffect(() => {
		const storedHistory = localStorage.getItem('searchHistory')
		if (storedHistory) {
			setSearchHistory(JSON.parse(storedHistory))
		}
	}, [])

	const toggleSortOrder = () => {
		setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
	}

	const handleSortByChange = (event) => {
		setSortBy(event.target.value)
	}

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				showSearchHistory &&
				event.target !== showFiltersRef.current &&
				!showFiltersRef.current.contains(event.target)
			) {
				setShowSearchHistory(false)
			}
		}

		document.addEventListener('mousedown', handleOutsideClick)

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showSearchHistory])

	const handleDeleteSearchHistory = (index) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				const updatedHistory = [...searchHistory]
				updatedHistory.splice(index, 1)
				setSearchHistory(updatedHistory)
				localStorage.setItem('searchHistory', JSON.stringify(updatedHistory))
				Swal.fire(
					'Deleted!',
					'Your search history has been deleted.',
					'success'
				)
			}
		})
	}

	const handleDeleteAllSearchHistory = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete all!',
		}).then((result) => {
			if (result.isConfirmed) {
				setSearchHistory([])
				localStorage.removeItem('searchHistory')
				Swal.fire(
					'Deleted!',
					'Your search history has been deleted.',
					'success'
				)
			}
		})
	}

	const isMobile = window.innerWidth < 1200

	return (
		<div className={`m-6 w-full ${!isMobile && 'pt-[80px]'} min-h-screen`}>
			{loadingProducts ? (
				<div className="loading-overlay">
					<div className="spinner"></div>
				</div>
			) : (
				<>
					<span
						className={`flex items-center gap-1 justify-center text-center bg-[#0081fb] py-2 text-white rounded-xl ${user ? 'mb-4' : 'mb-2'}`}
					>
						<span className="font-semibold">
							{translations[language].lastUpdated}
						</span>
						<span className="animate-pulse">
							{lastUpdated ? lastUpdated : translations[language].unknownTime}
						</span>
					</span>
					<div className="flex items-center gap-2 justify-between">
						<div className="relative w-full" ref={showFiltersRef}>
							<input
								type="text"
								placeholder={translations[language].searchProducts}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								onClick={handleSearchBoxClick}
								className="p-2 pl-4 border text-sm border-gray-300 rounded-xl outline-[#0081FB] w-full h-10 pr-10 placeholder:text-zinc-800"
							/>
							{/* {showSearchHistory && (
                <div className="my-2 w-full" ref={showFiltersRef}>
                  <p className="text-gray-500">{translations[language].lastSearches}</p>
                  <ul className="list-none w-full">
                    {searchHistory.map((item, index) => (
                      <li key={index} className="border rounded-lg px-4 py-2 bg-white hover:bg-gray-100 cursor-pointer w-full flex flex-row justify-between gap-2 my-1 items-center">
                        <span className="flex flex-row gap-2 items-center">
                          <FaClock />
                          <p onClick={() => setSearchTerm(item)}>{item}</p>
                        </span>
                        <button className="text-red-500 hover:text-red-600" onClick={() => handleDeleteSearchHistory(index)}><FiX /></button>
                      </li>
                    ))}
                  </ul>
                  <button className="w-full px-4 py-2 mt-2 bg-red-500 hover:bg-red-600 rounded-lg text-white" onClick={handleDeleteAllSearchHistory}>Delete All</button>
                </div>
              )} */}

							{showSearchHistory && searchHistory.length > 0 && (
								<div
									ref={showFiltersRef}
									className="search-history p-4 bg-white rounded-b-xl shadow-2xl"
								>
									<p>{translations[language].lastSearches}</p>
									<ul>
										{searchHistory.map((item, index) => (
											<li
												key={index}
												className="border rounded-lg px-4 py-2 bg-white hover:bg-gray-100 cursor-pointer w-full flex flex-row justify-between gap-2 my-1 items-center"
											>
												<span className="flex flex-row gap-2 items-center">
													<FaRegClock className="text-zinc-400 mx-2 size-5" />
													<p onClick={() => setSearchTerm(item)}>{item}</p>
												</span>
												<button
													className="text-red-500 hover:text-red-600"
													onClick={() => handleDeleteSearchHistory(index)}
												>
													<FiX className="size-6" />
												</button>
											</li>
										))}
									</ul>
									<button
										className="w-full px-4 py-2 mt-2 bg-red-500 hover:bg-red-600 rounded-lg text-white"
										onClick={handleDeleteAllSearchHistory}
									>
										Delete All
									</button>
								</div>
							)}
							{searchTerm && (
								<button
									onClick={() => setSearchTerm('')}
									className="absolute top-0 right-0 mr-3 mt-2 text-red-500 hover:text-red-700 cursor-pointer"
								>
									<FiX size={26} />
								</button>
							)}
							{!searchTerm && (
								<span className="absolute top-0 right-0 mr-3 mt-2 text-gray-500">
									<FiSearch size={23} />
								</span>
							)}
						</div>
						<div className="flex items-center gap-2">
							<span
								className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'} text-nowrap`}
							>
								{translations[language].sortBy}
							</span>
							<select
								onChange={handleSortByChange}
								value={sortBy}
								className="bg-gray-200 rounded px-2 py-1 w-fit outline-none"
							>
								<option value="name">{translations[language].name}</option>
							</select>
							<button
								onClick={toggleSortOrder}
								className="bg-gray-200 p-2 rounded"
							>
								{sortBy === 'name' ? (
									sortOrder === 'asc' ? (
										<FaSortAlphaDown />
									) : (
										<FaSortAlphaDownAlt />
									)
								) : sortOrder === 'asc' ? (
									<FaSortNumericDown />
								) : (
									<FaSortNumericDownAlt />
								)}
							</button>
						</div>
					</div>

					<hr className="my-4" />

					<div className={`py-0 w-full flex flex-wrap gap-5 justify-start`}>
						{searchResults.map((product, index) => {
							return <ProductsCard key={index} product={product} />
						})}
					</div>
				</>
			)}
			<style>
				{`
            .loading-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5); /* warna gelap */
              display: flex;
              justify-content: center;
              align-items: center;
            }
            
            .spinner {
              border: 4px solid rgba(255, 255, 255, 0.3); /* warna putih dengan transparansi */
              border-top: 4px solid #ffffff; /* warna putih */
              border-radius: 50%;
              width: 40px;
              height: 40px;
              animation: spin 1s linear infinite;
            }
            
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
            
            @keyframes scroll {
              0% {
                transform: translateX(250%);
              }
              100% {
                transform: translateX(-250%);
              }
            }

            .animate-scroll-wrapper {
              overflow: hidden;
              display: inline-block;
            }

            .animate-scroll {
              display: inline-block;
              white-space: nowrap;
              animation: scroll 15s linear infinite;
            }

            .search-history {
              position: absolute;
              top: calc(100%);
              left: 0;
              width: calc(100%);
              z-index: 1000;
              max-height: 650px;
              overflow-y: auto;
            }
            
            .search-history ul {
              list-style-type: none;
              padding: 0;
              margin: 0;
            }
            
            .search-history li {
              padding: 10px;
              cursor: pointer;
            }
            
            .search-history li:hover {
              background-color: #f0f0f0;
            }            

          `}
			</style>
			<Helmet>
				<title>{translations[language].listProducts} | Hypertopia</title>
			</Helmet>
		</div>
	)
}

export default Products
