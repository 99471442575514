import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import fireDb from '../../firebase'
import { auth } from '../../firebase'
import { FaBook, FaMessage, FaRankingStar } from 'react-icons/fa6'
import { FaTools } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { IoGameController } from 'react-icons/io5'
import { MdGroups, MdOutlineSystemUpdateAlt } from 'react-icons/md'
import { motion } from 'framer-motion'

const VRGames = () => {
	const language = localStorage.getItem('language') || 'id'

	const eligibleUsersRef = fireDb.ref('eligibleUsers/standalone')
	const [user, setUser] = useState(null)

	const [showAnnouncement, setShowAnnouncement] = useState(false)
	const [showWhatsNew, setShowWhatsNew] = useState(false)

	const whatsNewRef = useRef(null)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					setShowAnnouncement(!eligibleUsers)
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligibleUsersRef])

	useEffect(() => {
		setShowWhatsNew(true)
	}, [])

	const translations = {
		en: {
			info: 'Info',
			hintInfo: 'Click one of the menus below to access the desired page!',
			vrGames: 'VR Games',
			firmwareQuest: 'Firmware Quest',
			descFirmwareQuest: 'Check the latest firmware update for Quest headset.',
			softwareHelper: 'Software Helper',
			descSoftwareHelper:
				'Useful tools for extracting RAR/ZIP and installing games to Quest.',
			listGames: 'List Games',
			descListGames:
				'Browse games available for download and play on Quest headset or PCVR.',
			tutorial: 'Tutorials',
			descTutorial: 'Guides to help install games or apps on Quest.',
			requestGame: 'Request Game',
			descRequestGame:
				'Request the game you want to be uploaded to this website.',
			leaderboard: 'Leaderboard',
			descLeaderboard:
				'Check the leaderboard of the most downloaded games on this website.',
			multiplayerStatus: 'Multiplayer Status',
			descMultiplayerStatus:
				'Check the status of multiplayer games on this website.',
		},
		id: {
			info: 'Info',
			hintInfo: 'Klik salah satu menu di bawah untuk mengakses halaman!',
			vrGames: 'Game VR',
			firmwareQuest: 'Firmware Quest',
			descFirmwareQuest: 'Cek pembaruan firmware terbaru untuk Quest.',
			softwareHelper: 'Software Pembantu',
			descSoftwareHelper:
				'Alat-alat untuk mengekstrak RAR/ZIP dan menginstal game di Quest.',
			listGames: 'Daftar Game',
			descListGames: 'Daftar game yang bisa diunduh dan dimainkan di Quest.',
			tutorial: 'Tutorial',
			descTutorial: 'Panduan untuk menginstal game atau aplikasi di Quest.',
			requestGame: 'Request Game',
			descRequestGame: 'Ajukan game yang ingin diupload di website ini.',
			leaderboard: 'Papan Peringkat',
			descLeaderboard: 'Cek peringkat game paling banyak diunduh di website.',
			multiplayerStatus: 'Status Multiplayer',
			descMultiplayerStatus: 'Cek status game multiplayer di website ini.',
		},
	}

	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			setGridColsVRGamesMenu(
				window.innerWidth >= 800 ? 'grid-cols-2' : 'grid-cols-1'
			)
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const isMobile = window.innerWidth < 1200

	const menuItems = [
		{
			icon: <MdOutlineSystemUpdateAlt size={50} />,
			label: translations[language].firmwareQuest,
			description: translations[language].descFirmwareQuest,
			link: './firmware-quest',
		},
		{
			icon: <FaTools size={50} />,
			label: translations[language].softwareHelper,
			description: translations[language].descSoftwareHelper,
			link: './software-helper',
		},
		{
			icon: <IoGameController size={50} />,
			label: translations[language].listGames,
			description: translations[language].descListGames,
			link: './list-games',
		},
		{
			icon: <FaBook size={50} />,
			label: translations[language].tutorial,
			description: translations[language].descTutorial,
			link: './tutorials',
		},
		{
			icon: <FaMessage size={50} />,
			label: translations[language].requestGame,
			description: translations[language].descRequestGame,
			link: './request-games',
		},
		{
			icon: <FaRankingStar size={50} />,
			label: translations[language].leaderboard,
			description: translations[language].descLeaderboard,
			link: './leaderboard',
		},
		{
			icon: <MdGroups size={50} />,
			label: translations[language].multiplayerStatus,
			description: translations[language].descMultiplayerStatus,
			link: './multiplayer-status',
		},
	]

	return (
		<div className={`pt-7 mx-7 pb-7 w-full ${!isMobile && 'pt-[110px]'} min-h-screen`}>
			<Helmet>
				<title>{translations[language].vrGames} | HyperTopia</title>
			</Helmet>
			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.5 }}
				className="mb-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 border border-blue-300 rounded-xl text-center shadow-md"
			>
				<p className="text-blue-600 font-semibold">
					<span className="bg-blue-500 text-white px-3 py-1 rounded-full">
						{translations[language].info}
					</span>
					<span className="ml-2">{translations[language].hintInfo}</span>
				</p>
			</motion.div>

			<div className={`grid ${gridColsVRGamesMenu} gap-7`}>
				{menuItems.map((item, index) => (
					<Link to={item.link} key={index}>
						<motion.div
							whileHover={{ scale: 1.03 }}
							whileTap={{ scale: 0.95 }}
							className="hover:bg-gradient-to-br from-blue-400 to-blue-600 bg-white text-gray-700 hover:text-white transition-all rounded-3xl border-2 p-6 flex gap-4 items-center shadow-md"
						>
							<div className="flex gap-4 items-center">
								<div className="text-3xl">{item.icon}</div>
								<div>
									<h3 className="text-xl font-semibold">{item.label}</h3>
									<p className="text-sm">{item.description}</p>
								</div>
							</div>
						</motion.div>
					</Link>
				))}
			</div>

			<style>
				{`
					.search-box {
						width: 100px;
					}
				`}
			</style>
		</div>
	)
}

export default VRGames
