import React from 'react'
import { motion } from 'framer-motion'
import { FaWindows, FaApple, FaLinux } from 'react-icons/fa'
import { MdVerified } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SideQuestLogo from '../../assets/images/sidequest-logo.png'
import WinRARLogo from '../../assets/images/winrar-logo.png'

const SoftwareCard = ({
	logo,
	title,
	description,
	officialLink,
	downloads,
	language,
}) => {
	return (
		<motion.div
			whileHover={{ scale: 1.02 }}
			whileTap={{ scale: 0.98 }}
			className="border border-gray-200 bg-white rounded-xl p-8 shadow-md w-full mx-auto"
		>
			<div className="flex flex-col items-center gap-4">
				<img src={logo} alt={`${title} Logo`} className="h-16" />
				<h3 className="text-xl font-semibold text-gray-800 text-center">
					{title}
				</h3>
				<p className="text-sm text-blue-600 font-semibold text-center">
					{description}
				</p>

				<Link
					to={officialLink}
					target="_blank"
					rel="noopener noreferrer"
					className="mt-2"
				>
					<motion.span
						whileHover={{ scale: 1.05 }}
						className="bg-blue-600 text-white py-2 px-6 rounded-lg flex items-center gap-2"
					>
						<MdVerified size={20} />
						{language.officialLinkText}
					</motion.span>
				</Link>

				<div className="grid grid-cols-2 gap-3 mt-4 w-full">
					{downloads.map((download) => (
						<Link
							key={download.link}
							to={download.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							<motion.button
								whileHover={{
									backgroundColor: '#2563eb',
									color: '#fff',
									scale: 1.05,
								}}
								className="flex items-center justify-center p-3 border rounded-lg bg-gray-100 w-full transition-all"
							>
								{download.icon}
								<span className="ml-2 text-sm">{download.text}</span>
							</motion.button>
						</Link>
					))}
				</div>
			</div>
		</motion.div>
	)
}

const SoftwareHelper = () => {
	const language = localStorage.getItem('language') || 'id'
	const translations = {
		en: {
			info: 'Info',
			softwareHelperInfo:
				'This page contains essential software to manage files and applications for your VR Quest device.',
			officialFromSideQuestWebsite: 'Official from SideQuest Website',
			officialFromWinRARWebsite: 'Official from WinRAR Website',
			forInstallerHelper: 'FOR INSTALLING APPS/GAMES TO QUEST',
			forExtracterHelper: 'FOR EXTRACTING .RAR/.ZIP FILES',
			softwareHelper: 'Software Helper',
			vrGames: 'VR Games',
		},
		id: {
			info: 'Info',
			softwareHelperInfo:
				'Halaman ini berisi Software yang penting untuk mengelola file dan aplikasi untuk perangkat VR Quest Anda.',
			officialFromSideQuestWebsite: 'Resmi dari Situs Web SideQuest',
			officialFromWinRARWebsite: 'Resmi dari Situs Web WinRAR',
			forInstallerHelper: 'UNTUK MENGINSTAL APLIKASI/GAME KE QUEST',
			forExtracterHelper: 'UNTUK MENGEKSTRAK FILE .RAR/.ZIP',
			softwareHelper: 'Software Pembantu',
			vrGames: 'Game VR',
		},
	}

	const sideQuestDownloads = [
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-Setup-0.10.42-x64-win.exe',
			icon: <FaWindows size={20} />,
			text: 'Windows 10 x64 - v0.10.42 / 100.45MB',
		},
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-0.10.42.dmg',
			icon: <FaApple size={20} />,
			text: 'OS X / MacOS 10.12+ - v0.10.42 / 126.82MB',
		},
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-0.10.42-arm64.dmg',
			icon: <FaApple size={20} />,
			text: 'OS X / MacOS 10.12+ (M1/ARM) - v0.10.42 / 120.48MB',
		},
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-0.10.42.tar.xz',
			icon: <FaLinux size={20} />,
			text: 'Linux - v0.10.42 / 93.65MB',
		},
	]

	const winRARDownloads = [
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/winrar/winrar-x64-701.exe',
			icon: <FaWindows size={20} />,
			text: 'Windows - 64 Bit',
		},
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/winrar/winrar-x32-701.exe',
			icon: <FaWindows size={20} />,
			text: 'Windows - 32 Bit',
		},
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/rarmacos-arm-701.tar.gz',
			icon: <FaApple size={20} />,
			text: 'macOS - ARM',
		},
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/rarmacos-x64-701.tar.gz',
			icon: <FaApple size={20} />,
			text: 'macOS - x64 64 Bit',
		},
	]

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`px-2 sm:px-4 lg:px-6 py-8 w-full ${!isMobile && 'pt-[90px]'} min-h-screen antialiased`}
		>
			<div className="mb-5">
				<div className="bg-blue-50 border border-blue-200 rounded-lg py-5 px-6 text-center shadow-md max-w-full mx-auto">
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.8 }}
					>
						<p>
							<span className="px-3 py-1 rounded-full bg-gradient-to-r from-blue-300 to-blue-500 text-white font-semibold text-center">
								{translations[language].info}
							</span>
							<span className="text-blue-500 font-semibold ml-2">
								{translations[language].softwareHelperInfo}
							</span>
						</p>
					</motion.div>
				</div>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-full mx-auto">
				<SoftwareCard
					logo={SideQuestLogo}
					title="SideQuest (Advanced Installer)"
					description={translations[language].forInstallerHelper}
					officialLink="https://sidequestvr.com/setup-howto"
					downloads={sideQuestDownloads}
					language={{
						officialLinkText:
							translations[language].officialFromSideQuestWebsite,
					}}
				/>

				<SoftwareCard
					logo={WinRARLogo}
					title="WinRAR 7.01"
					description={translations[language].forExtracterHelper}
					officialLink="https://www.win-rar.com/download.html?&L=0"
					downloads={winRARDownloads}
					language={{
						officialLinkText: translations[language].officialFromWinRARWebsite,
					}}
				/>
			</div>

			<Helmet>
				<title>
					{translations[language].softwareHelper} |{' '}
					{translations[language].vrGames} - Hypertopia
				</title>
			</Helmet>
		</div>
	)
}

export default SoftwareHelper
