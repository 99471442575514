import React, { useState, useRef, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

import { TbDots } from 'react-icons/tb'
import {
	BsCameraVideoFill,
	BsCameraVideoOff,
	BsCameraVideoOffFill,
	BsClipboard,
	BsClipboard2Fill,
} from 'react-icons/bs'
import { BiDoorOpen } from 'react-icons/bi'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { FaSpinner } from 'react-icons/fa'
import { Call } from '@mui/icons-material'
import { FaClock, FaHome, FaMicrophoneSlash } from 'react-icons/fa'
import { FaMicrophone } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const firestore = firebase.firestore()

// Initialize WebRTC
const servers = {
	iceServers: [
		{
			urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
		},
	],
	iceCandidatePoolSize: 10,
}

const pc = new RTCPeerConnection(servers)

function App() {
	const [currentPage, setCurrentPage] = useState('home')
	const [joinCode, setJoinCode] = useState('')

	return (
		<div className="app">
			{currentPage === 'home' ? (
				<Menu
					joinCode={joinCode}
					setJoinCode={setJoinCode}
					setPage={setCurrentPage}
				/>
			) : (
				<Videos mode={currentPage} callId={joinCode} setPage={setCurrentPage} />
			)}
		</div>
	)
}
function Menu({ joinCode, setJoinCode, setPage }) {
	const [isJoining, setIsJoining] = useState(false)

	const handleJoinClick = () => {
		setIsJoining(true)
		// Simulate joining process
		setTimeout(() => {
			setIsJoining(false)
			setPage('join')
		}, 2000)
	}

	const handleCreateClick = () => {
		setPage('create')
	}

	return (
		<div
			className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center"
			style={{
				backgroundImage: `url('https://source.unsplash.com/random/800x600')`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className="bg-white p-8 rounded-xl border-2 shadow-lg flex flex-col items-center gap-4">
				<Link to="/">
					<button className="flex items-center gap-2 px-4 py-2 bg-red-500 rounded-lg text-white hover:bg-red-600">
						<FaHome />
						<p>Back to Home</p>
					</button>
				</Link>

				<button
					onClick={handleCreateClick}
					className="w-full px-4 py-2 text-white bg-blue-500 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-500"
				>
					Create Call
				</button>

				<b>OR</b>

				<div className="flex items-center space-x-4">
					<input
						className="px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring focus:border-blue-500"
						value={joinCode}
						onChange={(e) => setJoinCode(e.target.value)}
						placeholder="Enter Code"
					/>
					<button
						onClick={handleJoinClick}
						className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-500"
					>
						{isJoining ? <FaSpinner className="animate-spin" /> : 'Answer'}
					</button>
				</div>
			</div>
		</div>
	)
}

function Videos({ mode, callId, setPage }) {
	const [webcamActive, setWebcamActive] = useState(false)
	const [screenSharingActive, setScreenSharingActive] = useState(false)
	const [screenStream, setScreenStream] = useState(null)
	const [roomId, setRoomId] = useState(callId)
	const [cameraOn, setCameraOn] = useState(true)
	const [microphoneOn, setMicrophoneOn] = useState(true)
	const [callStartTime, setCallStartTime] = useState(null)
	const [callDuration, setCallDuration] = useState(0)
	const [displayMediaStream, setDisplayMediaStream] = useState(null)
	const [displayMediaError, setDisplayMediaError] = useState(null)
	const intervalRef = useRef(null)
	const localVideoRef = useRef(null)
	const remoteVideoRef = useRef(null)

	const [pc, setPc] = useState(null)

	useEffect(() => {
		if (!pc) {
			const newPc = new RTCPeerConnection(servers)
			setPc(newPc)
		}

		return () => {
			if (pc) {
				pc.close()
			}
		}
	}, [])

	useEffect(() => {
		if (webcamActive) {
			intervalRef.current = setInterval(() => {
				setCallDuration((prevDuration) => prevDuration + 1)
			}, 1000)
		} else {
			clearInterval(intervalRef.current)
			setCallStartTime(null)
			setCallDuration(0)
		}

		return () => {
			clearInterval(intervalRef.current)
		}
	}, [webcamActive])

	useEffect(() => {
		if (screenSharingActive) {
			navigator.mediaDevices
				.getDisplayMedia({ video: true })
				.then((stream) => {
					setDisplayMediaStream(stream)
					stream.getVideoTracks()[0].onended = () => {
						setScreenSharingActive(false)
					}
				})
				.catch((error) => {
					setDisplayMediaError(error.message)
				})
		} else {
			if (displayMediaStream) {
				displayMediaStream.getTracks().forEach((track) => {
					track.stop()
				})
				setDisplayMediaStream(null)
			}
		}
	}, [screenSharingActive])

	const localRef = useRef()
	const remoteRef = useRef()

	let localStream, remoteStream, screenTrack

	const setupSources = async () => {
		localStream = await navigator.mediaDevices.getUserMedia({
			video: cameraOn,
			audio: microphoneOn,
		})
		remoteStream = new MediaStream()

		localStream.getTracks().forEach((track) => {
			pc.addTrack(track, localStream)
		})

		pc.ontrack = (event) => {
			event.streams[0].getTracks().forEach((track) => {
				remoteStream.addTrack(track)
			})
		}

		localRef.current.srcObject = localStream
		remoteRef.current.srcObject = remoteStream

		setWebcamActive(true)

		if (mode === 'create') {
			const callDoc = firestore.collection('calls').doc()
			const offerCandidates = callDoc.collection('offerCandidates')
			const answerCandidates = callDoc.collection('answerCandidates')

			setRoomId(callDoc.id)

			pc.onicecandidate = (event) => {
				event.candidate && offerCandidates.add(event.candidate.toJSON())
			}

			const offerDescription = await pc.createOffer()
			await pc.setLocalDescription(offerDescription)

			const offer = {
				sdp: offerDescription.sdp,
				type: offerDescription.type,
			}

			await callDoc.set({ offer })

			callDoc.onSnapshot((snapshot) => {
				const data = snapshot.data()
				if (!pc.currentRemoteDescription && data?.answer) {
					const answerDescription = new RTCSessionDescription(data.answer)
					pc.setRemoteDescription(answerDescription)
				}
			})

			answerCandidates.onSnapshot((snapshot) => {
				snapshot.docChanges().forEach((change) => {
					if (change.type === 'added') {
						const candidate = new RTCIceCandidate(change.doc.data())
						pc.addIceCandidate(candidate)
					}
				})
			})
		} else if (mode === 'join') {
			const callDoc = firestore.collection('calls').doc(callId)
			const answerCandidates = callDoc.collection('answerCandidates')
			const offerCandidates = callDoc.collection('offerCandidates')

			pc.onicecandidate = (event) => {
				event.candidate && answerCandidates.add(event.candidate.toJSON())
			}

			const callData = (await callDoc.get()).data()

			const offerDescription = callData.offer
			await pc.setRemoteDescription(new RTCSessionDescription(offerDescription))

			const answerDescription = await pc.createAnswer()
			await pc.setLocalDescription(answerDescription)

			const answer = {
				type: answerDescription.type,
				sdp: answerDescription.sdp,
			}

			await callDoc.update({ answer })

			offerCandidates.onSnapshot((snapshot) => {
				snapshot.docChanges().forEach((change) => {
					if (change.type === 'added') {
						let data = change.doc.data()
						pc.addIceCandidate(new RTCIceCandidate(data))
					}
				})
			})
		}

		pc.onconnectionstatechange = (event) => {
			if (pc.connectionState === 'disconnected') {
				hangUp()
			}
		}
	}

	const toggleCamera = () => {
		setCameraOn(!cameraOn)
		pc.getSenders().forEach((sender) => {
			if (sender.track.kind === 'video') {
				sender.track.enabled = cameraOn
			}
		})
	}

	const toggleMicrophone = () => {
		setMicrophoneOn(!microphoneOn)
		pc.getSenders().forEach((sender) => {
			if (sender.track.kind === 'audio') {
				sender.track.enabled = microphoneOn
			}
		})
	}

	const toggleScreenSharing = async () => {
		if (!screenSharingActive) {
			const stream = await navigator.mediaDevices.getDisplayMedia({
				video: true,
			})
			pc.getSenders().forEach((sender) => {
				if (sender.track.kind === 'video') {
					sender.replaceTrack(stream.getTracks()[0])
				}
			})
			setScreenSharingActive(true)
		} else {
			const userStream = await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: true,
			})
			pc.getSenders().forEach((sender) => {
				if (sender.track.kind === 'video') {
					sender.replaceTrack(userStream.getVideoTracks()[0])
				}
			})
			setScreenSharingActive(false)
		}
	}

	const hangUp = async () => {
		pc.close()

		if (roomId) {
			let roomRef = firestore.collection('calls').doc(roomId)
			await roomRef
				.collection('answerCandidates')
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						doc.ref.delete()
					})
				})
			await roomRef
				.collection('offerCandidates')
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						doc.ref.delete()
					})
				})

			await roomRef.delete()
		}

		window.location.reload()
	}

	const formatTime = (timeInSeconds) => {
		const hours = Math.floor(timeInSeconds / 3600)
		const minutes = Math.floor((timeInSeconds % 3600) / 60)
		const seconds = timeInSeconds % 60

		const formattedHours = String(hours).padStart(2, '0')
		const formattedMinutes = String(minutes).padStart(2, '0')
		const formattedSeconds = String(seconds).padStart(2, '0')

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
	}

	return (
		<div className="flex flex-col items-center p-4 bg-white fixed top-0 left-0 w-full h-full justify-center">
			{/* Video Containers */}

			{/* Duration */}
			{webcamActive && (
				<div className="flex flex-row justify-between items-center text-center bg-[#0081FB] text-white w-full rounded-xl px-4 py-2.5 mb-2">
					<span className="flex flex-row gap-2 items-center">
						<FaClock />
						<p>Call Duration: {formatTime(callDuration)}</p>
					</span>
					<p>Meeting Code: {roomId}</p>
				</div>
			)}

			<div className="flex flex-row justify-center items-center space-x-4 w-full ">
				<video
					ref={localRef}
					autoPlay
					playsInline
					className="w-1/2 h-auto rounded-xl shadow-lg"
					muted
				/>
				<video
					ref={remoteRef}
					autoPlay
					playsInline
					className="w-1/2 h-auto rounded-xl shadow-lg"
				/>
			</div>

			{/* Buttons Container */}
			<div className="flex justify-center space-x-4 mt-4">
				<button
					onClick={toggleCamera}
					className="bg-gray-300 text-black px-6 py-3 rounded-full shadow-md flex items-center space-x-2"
				>
					{cameraOn ? <BsCameraVideoFill /> : <BsCameraVideoOffFill />}
				</button>
				<button
					onClick={toggleMicrophone}
					className="bg-gray-300 text-black px-6 py-3 rounded-full shadow-md flex items-center space-x-2"
				>
					{microphoneOn ? <FaMicrophoneSlash /> : <FaMicrophone />}
				</button>
				<button
					onClick={toggleScreenSharing}
					className="bg-blue-500 text-white px-6 py-3 rounded-full shadow-md flex items-center space-x-2"
				>
					{screenSharingActive ? <BsFillPersonCheckFill /> : <BiDoorOpen />}
					<span>
						{screenSharingActive ? 'Stop Sharing Screen' : 'Share Screen'}
					</span>
				</button>

				<button
					onClick={hangUp}
					disabled={!webcamActive}
					className="bg-red-500 text-white px-6 py-3 rounded-full shadow-md disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
				>
					<Call />
					<span>End Call</span>
				</button>
				<button
					onClick={() => {
						navigator.clipboard.writeText(roomId)
					}}
					className="bg-blue-500 text-white px-6 py-3 rounded-full shadow-md flex items-center space-x-2"
				>
					<BsClipboard2Fill />
					<span>Copy Code</span>
				</button>
			</div>

			{/* Modal for webcam activation */}
			{!webcamActive && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-80 backdrop-blur-sm">
					<div className="bg-white p-6 rounded-lg shadow-lg space-y-4 text-center">
						<h3 className="text-lg font-semibold">
							Turn on your camera and microphone to start the call
						</h3>
						<div className="flex justify-center space-x-4">
							<button
								onClick={() => setPage('home')}
								className="bg-red-500 text-white px-6 py-3 rounded-full shadow-md flex flex-row gap-2 items-center justify-center hover:bg-red-600 transition-all duration-500"
							>
								<BiDoorOpen />
								<span>Exit</span>
							</button>
							<button
								onClick={setupSources}
								className="bg-blue-500 text-white px-6 py-3 rounded-full shadow-md flex flex-row gap-2 items-center justify-center hover:bg-blue-600 transition-all duration-500"
							>
								<BsFillPersonCheckFill />
								<span>Start Call</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default App
