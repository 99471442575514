import React, { useState, useEffect } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom'
import Home from './pages/Home'
import { DarkModeProvider } from './DarkModeContext'
import DashboardAdmin from './pages/Admin/DashboardAdmin'
import AddEdit from './pages/Admin/AddEdit'
import SignIn from './pages/Account/SignIn'
import Products from './pages/Products/Products'
import ListProducts_Admin from './pages/Admin/ListProducts_Admin'
import ListVRGames_Admin from './pages/Admin/ListVRGames_Admin'
import fireDb from './firebase'

import { auth } from './firebase'
import ListUsers_Admin from './pages/Admin/ListUsers_Admin'

import bgPage from './assets/images/bg.jpg'

import { FaBoxes, FaDownload, FaHome, FaWhatsapp } from 'react-icons/fa'

import { useDarkModeContext } from './DarkModeContext'
import Profile from './pages/Account/Profile'

import RequestVRGames from './pages/VR Games/RequestVRGames'
import Cart from './pages/Account/Cart'
import ErrorPage from './pages/ErrorPage'
import VRGames from './pages/VR Games/VRGames'
import ListGames from './pages/VR Games/ListGames'

import Standalone_ListGames from './pages/VR Games/List Games/Standalone/ListGames_Standalone'
// import PCVR_ListGames from './pages/VR Games/List Games/PCVR/PCVR_ListGames';

import Tutorials from './pages/VR Games/Tutorials'
import RequestGames from './pages/VR Games/RequestGames'
import Orders from './pages/Account/Orders'
import Payment from './pages/Account/Payment'
import ListRequests_Admin from './pages/Admin/ListRequests_Admin'
import ListOrders_Admin from './pages/Admin/ListOrders_Admin'
import SoftwareHelper from './pages/VR Games/SoftwareHelper'
import UploadCover_Admin from './pages/Admin/UploadCover_Admin'
import Sidebar, { SidebarItem, SubmenuItem } from './pages/Sidebar'
import {
	BsBoxSeamFill,
	BsBoxes,
	BsHeadsetVr,
	BsNintendoSwitch,
} from 'react-icons/bs'
import {
	FaAddressCard,
	FaBook,
	FaBoxArchive,
	FaCartShopping,
	FaGamepad,
	FaGear,
	FaGoogleDrive,
	FaMessage,
	FaMeta,
	FaRankingStar,
	FaUpload,
	FaUser,
} from 'react-icons/fa6'
import {
	MdDashboard,
	MdGamepad,
	MdGroup,
	MdGroups,
	MdOutlineSystemUpdateAlt,
	MdSpaceDashboard,
} from 'react-icons/md'
import { IoBag } from 'react-icons/io5'
import Dashboard from './pages/Dashboard'
import LeaderboardGames from './pages/VR Games/LeaderboardGames'
import Testing from './pages/Testing'
import DownloadCover_Admin from './pages/Admin/DownloadGamesCover_Admin'

import { useNetworkState } from 'react-use'
import toast, { Toaster } from 'react-hot-toast'
import Settings from './pages/Settings/Settings'
import DownloadGamesCover_Admin from './pages/Admin/DownloadGamesCover_Admin'
import DownloadProductsCover_Admin from './pages/Admin/DownloadProductsCover_Admin'
import PCVR_ListGames from './pages/VR Games/List Games/PCVR/ListGames_PCVR'
import AddEdit_EligibleUsers from './pages/Admin/AddEdit_Standalone_EligibleUsers'
import AddEdit_StandaloneGames from './pages/Admin/AddEdit_StandaloneGames'
import AddEdit_PCVRGames from './pages/Admin/AddEdit_PCVRGames'
import AddEdit_Standalone_EligibleUsers from './pages/Admin/AddEdit_Standalone_EligibleUsers'
import AddEdit_PCVR_EligibleUsers from './pages/Admin/AddEdit_PCVR_EligibleUsers'
import MultiplayerStatus from './pages/VR Games/MultiplayerStatus'
import AddEdit_MultiplayerStatus from './pages/Admin/AddEdit_MultiplayerStatus'
import NintendoSwitch from './pages/Nintendo Switch/NintendoSwitch'
import NavbarMobile from './pages/SidebarMobile'
import SidebarMobile, {
	SidebarMobileItem,
	SubmenuMobileItem,
} from './pages/SidebarMobile'
import MeetingPage from './pages/VideoConference/MeetingPage'
import UploadToDrive from './pages/Admin/UploadToDrive'
import DownloadTest from './pages/Admin/DownloadTesting'
import NavBar from './pages/NavBar'
import FirmwareQuest from './pages/VR Games/FirmwareQuest'
import FirmwareInputPage from './pages/Admin/AddEdit_FirmwareQuest'
import FirmwareListPage from './pages/Admin/FirmwareListPage'

const App = () => {
	return (
		<DarkModeProvider>
			<Router>
				<Routes>
					<Route path="/*" element={<AppLayout />} />
				</Routes>
			</Router>
		</DarkModeProvider>
	)
}

const AppLayout = () => {
	const { isDarkMode } = useDarkModeContext()
	const location = useLocation()
	const allowedEmails = ['hypertopiaid@gmail.com']
	const navigate = useNavigate()

	const [user, setUser] = useState(null)

	const [isDisconnected, setIsDisconnected] = useState(false)
	const networkState = useNetworkState()

	const isMobile = window.innerWidth < 1200

	// Menyimpan status koneksi saat komponen pertama kali dimuat
	useEffect(() => {
		setIsDisconnected(!networkState.online)
	}, [])

	// Memeriksa perubahan status koneksi
	useEffect(() => {
		// Jika terputus dari internet dan sebelumnya terhubung, tampilkan toast
		if (!networkState.online && !isDisconnected) {
			toast.error('Koneksi internet bermasalah, harap periksa koneksi Anda.')
			setIsDisconnected(true) // Set isDisconnected ke true untuk menghindari spamming
		}

		// Jika terhubung kembali, set isDisconnected ke false
		if (networkState.online && isDisconnected) {
			toast.success('Koneksi internet telah terhubung kembali.')
			setIsDisconnected(false)
		}
	}, [networkState.online])

	const translations = {
		en: {
			home: 'Home',
			dashboard: 'Dashboard',

			listGames: 'List Games',
			products: 'Products',
			purchase: 'Purchase',
			account: 'Account',
			profile: 'Profile',
			settings: 'Settings',

			yourOrders: 'Orders',
			cart: 'Cart',

			listVRGames: 'VR Games',
			softwareHelper: 'Software Helper',
			listGames: 'List Games',
			tutorials: 'Tutorials',
			requestGames: 'Request Games',
			leaderboard: 'Leaderboard',
			multiplayerStatus: 'Multiplayer Status',

			chat: 'Chat with Admin',

			saveData: 'Data Saver',

			language: 'Language',
			theme: 'Theme',
			dark: 'Dark',
			light: 'Light',

			signIn: 'Sign In',
			signOut: 'Sign Out',
			adminDashboard: 'Admin Dashboard',

			tooltipDataSaver: 'Game cover images will not be displayed to save data.',
		},
		id: {
			home: 'Beranda',
			dashboard: 'Dasbor',

			listGames: 'Daftar Game',
			products: 'Produk',
			purchase: 'Beli',
			account: 'Akun',
			profile: 'Profil',
			settings: 'Pengaturan',

			yourOrders: 'Pesanan',
			cart: 'Keranjang',

			listVRGames: 'Game VR',
			softwareHelper: 'Software Pembantu',
			listGames: 'Daftar Game',
			tutorials: 'Tutorial',
			requestGames: 'Request Game',
			leaderboard: 'Papan Peringkat',
			multiplayerStatus: 'Status Multiplayer',

			chat: 'Chat dengan Admin',

			saveData: 'Hemat Data',

			language: 'Bahasa',
			theme: 'Tema',
			dark: 'Gelap',
			light: 'Terang',

			signIn: 'Masuk',
			signOut: 'Keluar',
			adminDashboard: 'Dasbor Admin',

			tooltipDataSaver:
				'Cover gambar setiap game tidak akan ditampilkan untuk menghemat data.',
		},
	}

	const language = localStorage.getItem('language') || 'id'
	const [cartItems, setCartItems] = useState([])
	const [orders, setOrders] = useState([])

	useEffect(() => {
		if (user) {
			const cartRef = fireDb.ref(`cart/${user.uid}`)

			// Listen for changes in the cart
			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const cartItemList = items ? Object.values(items) : []
				setCartItems(cartItemList)
			}

			cartRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				cartRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	useEffect(() => {
		if (user) {
			const ordersRef = fireDb.ref(`orders/${user.uid}`)

			// Listen for changes in the orders
			const handleSnapshot = (snapshot) => {
				const ordersData = snapshot.val()
				const ordersList = ordersData
					? Object.entries(ordersData).map(([orderId, orderData]) => ({
							...orderData,
							orderId,
						}))
					: []
				setOrders(ordersList)
			}

			ordersRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				ordersRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	useEffect(() => {
		if (location.pathname !== '/signin') {
			localStorage.setItem('redirectPath', window.location.pathname)
		}
	}, [window.location.pathname])

	useEffect(() => {
		if (!localStorage.getItem('dataSaver')) {
			localStorage.setItem('dataSaver', true)
		}
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		// Check if the user is authenticated and the current path is "/dba"
		if (user && location.pathname === '/dba') {
			navigate('/dba/dashboard', { replace: true })
		} else if (
			user &&
			!allowedEmails.includes(user.email) &&
			(location.pathname === '/dba' ||
				location.pathname === '/dba/add' ||
				location.pathname === '/dba/list-vr-games' ||
				location.pathname === '/dba/list-products' ||
				location.pathname === '/dba/list-users')
		) {
			navigate('/', { replace: true })
		}
	}, [user, location.pathname, navigate])

	const dropdownItems_VRGames = [
		{
			text: 'Firmware Quest',
			link: '/vr-games/firmware-quest',
			icon: <MdOutlineSystemUpdateAlt />,
			active: location.pathname === '/vr-games/firmware-quest' ? true : false,
		},
		{
			text: translations[language].softwareHelper,
			link: '/vr-games/software-helper',
			icon: <BsBoxSeamFill />,
			active: location.pathname === '/vr-games/software-helper' ? true : false,
		},
		{
			text: translations[language].listGames,
			link: '/vr-games/list-games',
			icon: <FaGamepad />,
			active: location.pathname.startsWith('/vr-games/list-games')
				? true
				: false,
		},
		{
			text: translations[language].tutorials,
			link: '/vr-games/tutorials',
			icon: <FaBook />,
			active: location.pathname === '/vr-games/tutorials' ? true : false,
		},
		{
			text: translations[language].requestGames,
			link: '/vr-games/request-games',
			icon: <FaMessage />,
			active: location.pathname === '/vr-games/request-games' ? true : false,
		},
		{
			text: translations[language].leaderboard,
			link: '/vr-games/leaderboard',
			icon: <FaRankingStar />,
			active: location.pathname === '/vr-games/leaderboard' ? true : false,
		},
		{
			text: translations[language].multiplayerStatus,
			link: '/vr-games/multiplayer-status',
			icon: <MdGroups />,
			active:
				location.pathname === '/vr-games/multiplayer-status' ? true : false,
		},
	]

	const dropdownItems_Account = [
		{
			text: translations[language].yourOrders,
			link: '/account/orders',
			icon: <FaBoxArchive />,
			active: location.pathname === '/account/orders' ? true : false,
			count: orders.length,
			user: user,
		},
		{
			text: translations[language].cart,
			link: '/account/cart',
			icon: <FaCartShopping />,
			active: location.pathname === '/account/cart' ? true : false,
			count: cartItems.length,
			user: user,
		},
		{
			text: translations[language].profile,
			link: '/account/profile',
			icon: <FaAddressCard />,
			active: location.pathname === '/account/profile' ? true : false,
		},
	]

	return (
		<div
			className={`h-full bg-repeat flex flex-row ${isMobile ? 'pt-[70px]' : ''}`}
			style={{
				background: !location.pathname.startsWith('/signin')
					? isDarkMode
						? `linear-gradient(to right, rgba(0, 0, 0, 0.95) 80%, rgba(0, 0, 0, 0.95) 80%, rgba(0, 0, 0, 0.95) 80%), url(${bgPage}) center/cover`
						: `linear-gradient(to left, rgba(255, 255, 255, 0.95) 10%, rgba(255, 255, 255, 0.95) 100%, rgba(255, 255, 255, 0.95) 100%), url(${bgPage}) center/cover`
					: undefined,
			}}
		>
			<Toaster />

			{!location.pathname.startsWith('/signin') &&
				!location.pathname.startsWith('/meeting') &&
				(isMobile ? (
					<SidebarMobile>
						{location.pathname.startsWith('/dba') ? (
							<>
								<SidebarMobileItem
									link="/dba/dashboard"
									icon={<MdSpaceDashboard />}
									text="Dashboard"
									active={
										location.pathname.startsWith('/dba/dashboard')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/list-products"
									icon={<BsBoxSeamFill />}
									text="Daftar Produk"
									active={
										location.pathname.startsWith('/dba/list-products')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/list-vr-games"
									icon={<MdGamepad />}
									text="Daftar Game VR"
									active={
										location.pathname.startsWith('/dba/list-vr-games')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/list-users"
									icon={<FaUser />}
									text="Daftar Pengguna"
									active={
										location.pathname.startsWith('/dba/list-users')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/list-requests"
									icon={<FaMessage />}
									text="Daftar Permintaan"
									active={
										location.pathname.startsWith('/dba/list-requests')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/list-orders"
									icon={<IoBag />}
									text="Daftar Pesanan"
									active={
										location.pathname.startsWith('/dba/list-orders')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/upload-cover"
									icon={<FaUpload />}
									text="Unggah Cover"
									active={
										location.pathname.startsWith('/dba/upload-cover')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/download-games-cover"
									icon={<FaDownload />}
									text="Unduh Cover Game"
									active={
										location.pathname.startsWith('/dba/download-games-cover')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/download-products-cover"
									icon={<FaDownload />}
									text="Unduh Cover Produk"
									active={
										location.pathname.startsWith('/dba/download-products-cover')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/upload-to-drive"
									icon={<FaGoogleDrive />}
									text="Unggah ke Google Drive"
									active={
										location.pathname.startsWith('/dba/upload-to-drive')
											? true
											: false
									}
								/>
								<SidebarMobileItem
									link="/dba/firmware-quest"
									icon={<FaGoogleDrive />}
									text="Daftar Firmware Quest"
									active={
										location.pathname.startsWith('/dba/firmware-quest')
											? true
											: false
									}
								/>
							</>
						) : (
							<>
								{user ? (
									<SidebarMobileItem
										link="/dashboard"
										icon={<MdDashboard />}
										text={translations[language].dashboard}
										active={location.pathname === '/dashboard' ? true : false}
									/>
								) : (
									<SidebarMobileItem
										link="/"
										icon={<FaHome />}
										text={translations[language].home}
										active={location.pathname === '/' ? true : false}
									/>
								)}
								<SidebarMobileItem
									link="/products"
									icon={<FaBoxes />}
									text={translations[language].products}
									active={location.pathname === '/products' ? true : false}
								/>
								<SidebarMobileItem
									link="/vr-games"
									icon={<BsHeadsetVr />}
									text={translations[language].listVRGames}
									active={
										location.pathname.startsWith('/vr-games') ? true : false
									}
									dropdownItems_VRGames={dropdownItems_VRGames}
								/>
								{/* <SidebarMobileItem link='/nintendo-switch' icon={<BsNintendoSwitch />} text="Nintendo Switch" active={location.pathname.startsWith('/nintendo-switch') ? true : false} dropdownItems_NintendoSwitch={dropdownItems_NintendoSwitch} /> */}
								<SidebarMobileItem
									link="/account/profile"
									icon={<FaUser />}
									text={translations[language].account}
									active={
										location.pathname.startsWith('/account') ? true : false
									}
									dropdownItems_Account={dropdownItems_Account}
								/>
								{/* <SidebarMobileItem link='/settings' icon={<FaGear />} text={translations[language].settings} active={location.pathname.startsWith('/settings') ? true : false} /> */}
							</>
						)}
					</SidebarMobile>
				) : (
					<NavBar />
				))}
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/signin" element={<SignIn />} />

				{/* ADMIN */}
				<Route path="/dba/dashboard" element={<DashboardAdmin />} />

				<Route path="/dba/list-vr-games" element={<ListVRGames_Admin />} />
				<Route
					path="/dba/list-vr-games/standalone/add"
					element={<AddEdit_StandaloneGames />}
				/>
				<Route
					path="/dba/list-vr-games/standalone/update/:id"
					element={<AddEdit_StandaloneGames />}
				/>
				<Route
					path="/dba/list-vr-games/pcvr/add"
					element={<AddEdit_PCVRGames />}
				/>
				<Route
					path="/dba/list-vr-games/pcvr/update/:id"
					element={<AddEdit_PCVRGames />}
				/>

				<Route path="/dba/list-products" element={<ListProducts_Admin />} />

				<Route path="/dba/list-users" element={<ListUsers_Admin />} />
				<Route
					path="/dba/list-users/standalone/add"
					element={<AddEdit_Standalone_EligibleUsers />}
				/>
				<Route
					path="/dba/list-users/standalone/update/:id"
					element={<AddEdit_Standalone_EligibleUsers />}
				/>
				<Route
					path="/dba/list-users/pcvr/add"
					element={<AddEdit_PCVR_EligibleUsers />}
				/>
				<Route
					path="/dba/list-users/pcvr/update/:id"
					element={<AddEdit_PCVR_EligibleUsers />}
				/>

				<Route path="/dba/list-requests" element={<ListRequests_Admin />} />
				<Route path="/dba/list-requests/add" element={<RequestVRGames />} />
				<Route
					path="/dba/list-requests/update/:id"
					element={<RequestVRGames />}
				/>
				<Route path="/dba/list-orders" element={<ListOrders_Admin />} />
				<Route path="/dba/upload-cover" element={<UploadCover_Admin />} />
				<Route
					path="/dba/download-games-cover"
					element={<DownloadGamesCover_Admin />}
				/>
				<Route
					path="/dba/download-products-cover"
					element={<DownloadProductsCover_Admin />}
				/>
				<Route path="/dba/upload-to-drive" element={<UploadToDrive />} />
				<Route path="/dba/firmware-quest" element={<FirmwareListPage />} />
				<Route path="/dba/firmware-quest/add" element={<FirmwareInputPage />} />
				<Route
					path="/dba/firmware-quest/update/:id"
					element={<FirmwareInputPage />}
				/>

				<Route path="/dba/download-test" element={<DownloadTest />} />

				<Route path="/dba/add" element={<AddEdit />} />
				<Route path="/dba/update/:id" element={<AddEdit />} />

				{/* USERS */}
				<Route path="/vr-games" element={<VRGames />} />
				<Route path="/vr-games/firmware-quest" element={<FirmwareQuest />} />
				<Route path="/vr-games/software-helper" element={<SoftwareHelper />} />
				<Route path="/vr-games/list-games" element={<ListGames />} />
				<Route
					path="/vr-games/list-games/standalone"
					element={<Standalone_ListGames />}
				/>
				<Route path="/vr-games/list-games/pcvr" element={<PCVR_ListGames />} />
				<Route path="/vr-games/tutorials" element={<Tutorials />} />
				<Route path="/vr-games/request-games" element={<RequestGames />} />
				<Route
					path="/vr-games/request-games/add"
					element={<RequestVRGames />}
				/>
				<Route
					path="/vr-games/request-games/update/:id"
					element={<RequestVRGames />}
				/>
				<Route path="/vr-games/leaderboard" element={<LeaderboardGames />} />
				<Route
					path="/vr-games/multiplayer-status"
					element={<MultiplayerStatus />}
				/>
				<Route
					path="/vr-games/multiplayer-status/add"
					element={<AddEdit_MultiplayerStatus />}
				/>
				<Route
					path="/vr-games/multiplayer-status/update/:id"
					element={<AddEdit_MultiplayerStatus />}
				/>

				<Route path="/nintendo-switch" element={<NintendoSwitch />} />

				<Route path="/products" element={<Products />} />

				<Route path="/account" element={<Profile />} />
				<Route path="/account/profile" element={<Profile />} />
				<Route path="/account/cart" element={<Cart />} />
				<Route path="/account/orders" element={<Orders />} />
				<Route path="/account/orders/:orderId" element={<Payment />} />

				<Route path="/settings" element={<Settings />} />

				<Route path="/meeting" element={<MeetingPage />} />

				<Route path="*" element={<ErrorPage />} />
			</Routes>

			<style>
				{`
        /* Style untuk scrollbar */
        ::-webkit-scrollbar {
          width: 8px; /* Lebar scrollbar */
        }

        /* Track scrollbar */
        ::-webkit-scrollbar-track {
          border-radius: 10px; /* Border radius untuk track */
        }

        /* Handle scrollbar */
        ::-webkit-scrollbar-thumb {
          background-color: #888; /* Warna handle */
          border-radius: 10px; /* Border radius untuk handle */
        }

        /* Ketika hover pada handle */
        ::-webkit-scrollbar-thumb:hover {
          background-color: #555; /* Warna handle saat hover */
        }
        `}
			</style>
		</div>
	)
}

export default App
