import React from 'react'
import { motion } from 'framer-motion'
import YouTube from 'react-youtube'
import { FaBook, FaBookOpen, FaCircleArrowRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const TutorialSection = ({ translations, language }) => {
	// Example YouTube video IDs and translations
	const tutorialVideoIds = [
		{
			id: 'TWHrvQ3VTJQ',
			title: `${translations[language].tutorial} #1`,
			desc: translations[language].tutorialDesc1,
		},
		{
			id: 'xhbEG2e1_LE',
			title: `${translations[language].tutorial} #2`,
			desc: translations[language].tutorialDesc2,
		},
	]

	return (
		<section className="relative py-12 md:py-16 bg-gradient-to-br from-blue-50 to-blue-100 overflow-hidden">
			{/* Decorative Circles */}
			<div className="absolute top-0 left-0 w-20 h-20 md:w-40 md:h-40 bg-[#0081FB] opacity-20 rounded-full -translate-x-5 -translate-y-5 md:-translate-x-10 md:-translate-y-10"></div>
			<div className="absolute bottom-0 right-0 w-20 h-20 md:w-40 md:h-40 bg-[#0081FB] opacity-20 rounded-full translate-x-5 translate-y-5 md:translate-x-10 md:translate-y-10"></div>

			{/* Title Section with Icon */}
			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6 }}
				className="text-center flex flex-col items-center mb-6 md:mb-8 px-4"
			>
				<div className="flex items-center gap-3 md:gap-4 text-[#0081FB]">
					<FaBookOpen className="text-3xl md:text-4xl" />
					<h2 className="text-3xl md:text-4xl font-bold text-[#0081FB]">
						{translations[language].tutorial}
					</h2>
				</div>
				<p className="text-gray-700 text-base md:text-lg mt-2 md:mt-3 max-w-md mx-auto">
					{translations[language].tutorialSubtitle}
				</p>
			</motion.div>

			{/* YouTube Embedded Videos with Titles */}
			<div className="flex flex-col md:flex-row gap-4 md:gap-6 justify-center items-center mt-6 md:mt-8 px-4">
				{tutorialVideoIds.map((video, index) => (
					<motion.div
						key={index}
						initial={{ opacity: 0, scale: 0.9 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ duration: 0.5, delay: index * 0.2 }}
						whileHover={{ scale: 1.05 }}
						className="bg-white rounded-2xl shadow-lg hover:shadow-xl w-64 h-80 md:w-72 md:h-80 transition duration-300 overflow-hidden flex flex-col"
					>
						{/* YouTube Video with Fixed Height */}
						<div className="w-full h-40">
							<YouTube
								videoId={video.id}
								opts={{ width: '100%', height: '100%' }}
							/>
						</div>

						{/* Tutorial Title and Description with Padding */}
						<div className="flex flex-col justify-start p-4 text-center h-20">
							<h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-1 md:mb-2">
								{video.title}
							</h3>
							<p className="text-gray-600 text-xs md:text-sm">{video.desc}</p>
						</div>
					</motion.div>
				))}
			</div>

			{/* CTA Button */}
			<div className="flex justify-center mt-8 md:mt-10 px-4">
				<Link to="/vr-games/tutorials">
					<motion.button
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.95 }}
						className="bg-[#0081FB] text-white px-4 md:px-6 py-2 md:py-3 rounded-full font-semibold shadow-md hover:bg-[#006bbd] transition duration-300 text-sm md:text-base"
					>
						{translations[language].exploreTutorials}
						<FaCircleArrowRight className="inline-block ml-2" />
					</motion.button>
				</Link>
			</div>
		</section>
	)
}

export default TutorialSection
