import React from 'react'
import { motion } from 'framer-motion'
import { FaRegSmileWink } from 'react-icons/fa'
import { FaCircleArrowUp } from 'react-icons/fa6'

const ClosingSection = ({ translations, language }) => {
	// Fungsi untuk scroll ke atas dengan efek smooth
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<section className="closing-section py-12 md:py-16 bg-gradient-to-br from-blue-700 to-blue-800 text-white text-center relative overflow-hidden">
			{/* Decorative Circles */}
			<div className="absolute top-0 left-0 w-16 h-16 md:w-24 md:h-24 lg:w-40 lg:h-40 bg-blue-500 opacity-20 rounded-full -translate-x-5 -translate-y-5 md:-translate-x-8 md:-translate-y-8"></div>
			<div className="absolute bottom-0 right-0 w-16 h-16 md:w-24 md:h-24 lg:w-40 lg:h-40 bg-blue-500 opacity-20 rounded-full translate-x-5 translate-y-5 md:translate-x-8 md:translate-y-8"></div>

			{/* Thank You Icon and Title */}
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6 }}
				className="flex flex-col items-center mb-4 md:mb-5 px-4"
			>
				<FaRegSmileWink className="text-3xl md:text-4xl lg:text-5xl text-yellow-300 mb-2 md:mb-3" />
				<h2 className="text-xl md:text-2xl lg:text-3xl font-bold">
					{translations[language].thankyou}
				</h2>
			</motion.div>

			{/* Subtitle */}
			<motion.p
				initial={{ opacity: 0, y: 10 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6, delay: 0.3 }}
				className="text-sm md:text-base lg:text-lg max-w-sm md:max-w-md mx-auto px-4"
			>
				{translations[language].thankyouSubtitle}
			</motion.p>

			{/* CTA Button */}
			<div className="flex justify-center mt-6 md:mt-8">
				<motion.button
					onClick={scrollToTop}
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					className="flex flex-row gap-2 items-center justify-center bg-yellow-400 hover:bg-yellow-500 text-black font-semibold px-4 md:px-6 py-2 md:py-3 rounded-full shadow-lg transition duration-300 text-sm md:text-base"
				>
					<p>{translations[language].backToTop}</p>
					<FaCircleArrowUp />
				</motion.button>
			</div>
		</section>
	)
}

export default ClosingSection
