import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logoHypertopia from '../assets/images/New_HyperTopia_Logo.png'
import {
	FaHome,
	FaSignInAlt,
	FaSignOutAlt,
	FaTools,
	FaWhatsapp,
} from 'react-icons/fa'
import { FaGamepad } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { RiAdminFill } from 'react-icons/ri'
import fireDb from '../firebase'
import { set } from 'firebase/database'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import {
	FaBook,
	FaBoxArchive,
	FaCartShopping,
	FaMessage,
	FaRankingStar,
} from 'react-icons/fa6'
import { MdDashboard, MdGroups, MdOutlineSystemUpdateAlt } from 'react-icons/md'
import Swal from 'sweetalert2'

const cleanDisplayName = (displayName) => {
	return displayName.replace(/[^\w\s]/gi, '')
}

const NavBar = () => {
	const dropdownRef = useRef(null)
	const location = useLocation()
	const navigate = useNavigate()
	const [isDropdownOpen, setDropdownOpen] = useState(false)
	const [isDropdownVRGamesOpen, setDropdownVRGamesOpen] = useState(false)
	const language = localStorage.getItem('language') || 'id'
	const [user, setUser] = useState(null)
	const allowedEmails = ['hypertopiaid@gmail.com']
	const translations = {
		en: {
			admin: 'Chat Admin',
			dashboard: 'Dashboard',
			home: 'Home',
			products: 'Products',
			purchase: 'Purchase',
			profile: 'Profile',
			account: 'Account',
			yourOrders: 'Orders',

			listVRGames: 'VR Games',
			software: 'Software Helper',
			listGames: 'List Games',
			tutorials: 'Tutorials',
			requestGames: 'Request Games',
			leaderboard: 'Leaderboard',
			multiplayerStatus: 'Multiplayer Status',
			firmwareQuest: 'Firmware Quest',

			chat: 'Chat with Admin',

			saveData: 'Data Saver',

			language: 'Language',
			theme: 'Theme',
			dark: 'Dark',
			light: 'Light',

			signIn: 'Sign In',
			signOut: 'Sign Out',
			adminDashboard: 'Admin Dashboard',

			tooltipDataSaver: 'Game cover images will not be displayed to save data.',

			titleSignOut: 'Are you sure?',
			textSignOut: 'Do you really want to sign out?',
			confirmSignOut: 'Yes, sign out!',
			cancelSignOut: 'No, stay logged in',
			successSignOut: 'Signed out!',
			successSignOutText: 'You have been successfully signed out.',
			errorSignOut: 'Oops...',
			errorSignOutText: 'Something went wrong!',

			new: 'NEW',

			listOrders: 'Orders',
			listRequests: 'Requests',
			uploadCover: 'Upload Cover',
			downloadGamesCover: 'Games Cover',
			downloadProductsCover: 'Products Cover',
			listProducts: 'List Products',
			listUsers: 'Users',
			uploadtoDrive: 'Upload to Drive',
		},
		id: {
			admin: 'Chat Admin',
			dashboard: 'Dasbor',
			home: 'Beranda',
			products: 'Produk',
			purchase: 'Beli',
			profile: 'Profil',
			account: 'Akun',
			yourOrders: 'Pesanan',

			listVRGames: 'Game VR',
			software: 'Software Pembantu',
			listGames: 'Daftar Game',
			tutorials: 'Tutorial',
			requestGames: 'Request Game',
			leaderboard: 'Papan Peringkat',
			multiplayerStatus: 'Status Multiplayer',
			firmwareQuest: 'Firmware Quest',

			chat: 'Chat dengan Admin',

			saveData: 'Hemat Data',

			language: 'Bahasa',
			theme: 'Tema',
			dark: 'Gelap',
			light: 'Terang',

			signIn: 'Masuk',
			signOut: 'Keluar',
			adminDashboard: 'Dasbor Admin',

			tooltipDataSaver:
				'Cover gambar setiap game tidak akan ditampilkan untuk menghemat data.',

			titleSignOut: 'Apakah kamu yakin?',
			textSignOut: 'Apakah kamu benar-benar ingin keluar?',
			confirmSignOut: 'Ya, keluar!',
			cancelSignOut: 'Tidak, tetap masuk',
			successSignOut: 'Keluar!',
			successSignOutText: 'Anda telah berhasil keluar.',
			errorSignOut: 'Oops...',
			errorSignOutText: 'Ada yang salah!',

			new: 'BARU',

			listOrders: 'Pesanan',
			listRequests: 'Permintaan',
			uploadCover: 'Unggah Cover',
			downloadGamesCover: 'Cover Game',
			downloadProductsCover: 'Cover Produk',
			listProducts: 'Daftar Produk',
			listUsers: 'Pengguna',
			uploadtoDrive: 'Unggah ke Drive',
		},
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				const cleanedDisplayName = cleanDisplayName(user.displayName)

				const userStatusDatabaseRef = fireDb.ref(
					`/usersMessages/${cleanedDisplayName}/status`
				)

				set(userStatusDatabaseRef, { online: true })
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const handleSignOut = () => {
		Swal.fire({
			title: translations[language].titleSignOut,
			text: translations[language].textSignOut,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: translations[language].confirmSignOut,
			cancelButtonText: translations[language].cancelSignOut,
		}).then((result) => {
			if (result.isConfirmed) {
				signOut(auth)
					.then(() => {
						setUser(null)
						Swal.fire(
							translations[language].successSignOut,
							translations[language].successSignOutText,
							'success'
						)
					})
					.catch((error) => {
						Swal.fire({
							icon: 'error',
							title: translations[language].errorSignOut,
							text: translations[language].errorSignOutText,
							footer: error.message,
						})
					})
			}
		})
	}

	const handleLanguageChange = (event) => {
		const selectedLanguage = event.target.value
		localStorage.setItem('language', selectedLanguage)
		window.location.reload()
	}

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				isDropdownOpen &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setDropdownOpen(false)
			}
		}

		document.addEventListener('mousedown', handleOutsideClick)

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [isDropdownOpen])

	const handleToDashboardAdmin = () => {
		navigate('/dba/dashboard', { replace: true })
	}

	const [cartItems, setCartItems] = useState([])

	useEffect(() => {
		if (user) {
			const cartRef = fireDb.ref(`cart/${user.uid}`)

			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const cartItemList = items ? Object.values(items) : []
				setCartItems(cartItemList)
			}

			cartRef.on('value', handleSnapshot)

			return () => {
				cartRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	const [orders, setOrders] = useState([])

	useEffect(() => {
		if (user) {
			const ordersRef = fireDb.ref(`orders/${user.uid}`)

			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const ordersList = items ? Object.values(items) : []
				setOrders(ordersList)
			}

			ordersRef.on('value', handleSnapshot)

			return () => {
				ordersRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	const handleToChatAdmin = () => {
		const currentHour = new Date().getHours()
		let greeting

		if (currentHour >= 4 && currentHour < 12) {
			greeting = 'pagi'
		} else if (currentHour >= 12 && currentHour < 15) {
			greeting = 'siang'
		} else if (currentHour >= 15 && currentHour < 18) {
			greeting = 'sore'
		} else {
			greeting = 'malam'
		}

		window.open(
			`https://api.whatsapp.com/send/?phone=6285141175754&text=Halo%2C+Kak.+Selamat+${greeting}!&type=phone_number&app_absent=0`,
			'_blank'
		)
	}

	return (
		<>
			<nav
				className={`bg-opacity-90 backdrop-blur-md p-2 py-3 justify-between flex items-center fixed w-full z-50 shadow-lg bg-white`}
			>
				<div className="ml-5 cursor-pointer z-50 flex flex-row items-center justify-center gap-0">
					{!window.location.pathname.startsWith('/dba') && (
						<ul className="flex lg:space-x-2 md:space-x-0 items-center justify-center">
							{user && (
								<li
									className={`px-4 rounded-full bg-gradient-to-r from-[#0081fb] to-blue-900 text-white font-semibold transition-all duration-300 py-2`}
								>
									<span className="flex flex-row gap-2 items-center">
										<MdDashboard />
										<Link to="/dashboard">
											{translations[language].dashboard}
										</Link>
									</span>
								</li>
							)}
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/' ? (
										translations[language].home
									) : (
										<Link to="/">{translations[language].home}</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/products' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/products' ? (
										translations[language].products
									) : (
										<Link to="/products">
											{translations[language].products}
										</Link>
									)}
								</span>
							</li>
							<li
								onMouseEnter={() => setDropdownVRGamesOpen(true)}
								onMouseLeave={() => setDropdownVRGamesOpen(false)}
								className={`px-4 py-1 rounded-full ${location.pathname.startsWith('/vr-games') ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/vr-games' ? (
										translations[language].listVRGames
									) : (
										<Link to="/vr-games">
											{translations[language].listVRGames}
										</Link>
									)}
									<div
										className={`${
											isDropdownVRGamesOpen
												? 'opacity-100 scale-100 visible'
												: 'opacity-0 scale-95 invisible'
										} flex flex-col transform transition-all duration-300 origin-top-left absolute top-16 -ml-[4rem] border-zinc-200 border-2 p-3 w-fit rounded-xl shadow-2xl bg-white`}
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="userMenuButton"
									>
										{location.pathname === '/vr-games/firmware-quest' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<MdOutlineSystemUpdateAlt />
												<span className="font-semibold">
													{translations[language].firmwareQuest}
												</span>
											</span>
										) : (
											<Link to="/vr-games/firmware-quest">
												<button
													className={`w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg text-black hover:bg-zinc-200`}
												>
													<MdOutlineSystemUpdateAlt />
													<span className="font-semibold">
														{translations[language].firmwareQuest}
													</span>
												</button>
											</Link>
										)}
										{location.pathname === '/vr-games/software-helper' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 mb-1 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<FaTools />
												<span className="font-semibold">
													{translations[language].software}
												</span>
											</span>
										) : (
											<Link to="/vr-games/software-helper">
												<button
													className={`w-full flex flex-row gap-4 px-4 mb-1 items-center transition-all duration-300 py-2 rounded-lg text-black hover:bg-zinc-200`}
												>
													<FaTools />
													<span className="font-semibold">
														{translations[language].software}
													</span>
												</button>
											</Link>
										)}

										{location.pathname === '/vr-games/list-games' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 mb-1 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<FaGamepad />
												<span className="font-semibold">
													{translations[language].listGames}
												</span>
											</span>
										) : (
											<Link to="/vr-games/list-games">
												<button
													className={`w-full flex flex-row gap-4 px-4 mb-1 items-center transition-all duration-300 py-2 rounded-lg text-black hover:bg-zinc-200`}
												>
													<FaGamepad />
													<span className="font-semibold">
														{translations[language].listGames}
													</span>
												</button>
											</Link>
										)}

										{location.pathname === '/vr-games/tutorials' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 mb-1 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<FaBook />
												<span className="font-semibold">
													{translations[language].tutorials}
												</span>
											</span>
										) : (
											<Link to="/vr-games/tutorials">
												<button
													className={`w-full flex flex-row gap-4 px-4 mb-1 items-center transition-all duration-300 py-2 rounded-lg text-black hover:bg-zinc-200`}
												>
													<FaBook />
													<span className="font-semibold">
														{translations[language].tutorials}
													</span>
												</button>
											</Link>
										)}

										{location.pathname === '/vr-games/request-games' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<FaMessage />
												<span className="font-semibold">
													{translations[language].requestGames}
												</span>
											</span>
										) : (
											<Link to="/vr-games/request-games">
												<button
													className={`w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg hover:bg-zinc-200 text-black`}
												>
													<FaMessage />
													<span className="font-semibold">
														{translations[language].requestGames}
													</span>
												</button>
											</Link>
										)}

										{location.pathname === '/vr-games/leaderboard' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<FaRankingStar />
												<span className="font-semibold">
													{translations[language].leaderboard}
												</span>
											</span>
										) : (
											<Link to="/vr-games/leaderboard">
												<button
													className={`w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg hover:bg-zinc-200 text-black`}
												>
													<FaRankingStar />
													<span className="font-semibold">
														{translations[language].leaderboard}
													</span>
												</button>
											</Link>
										)}

										{location.pathname === '/vr-games/multiplayer-status' ? (
											<span
												className={`bg-[#0081FB] w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg text-white`}
											>
												<MdGroups />
												<span className="font-semibold">
													{translations[language].multiplayerStatus}
												</span>
											</span>
										) : (
											<Link to="/vr-games/multiplayer-status">
												<button
													className={`w-full flex flex-row gap-4 px-4 items-center transition-all duration-300 py-2 rounded-lg hover:bg-zinc-200 text-black`}
												>
													<MdGroups />
													<span className="font-semibold">
														{translations[language].multiplayerStatus}
													</span>
												</button>
											</Link>
										)}
									</div>
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname.startsWith('/account') ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname.startsWith('/account') ? (
										translations[language].profile
									) : (
										<Link to="/account/profile">
											{translations[language].profile}
										</Link>
									)}
								</span>
							</li>
						</ul>
					)}

					{window.location.pathname.startsWith('/dba') && (
						<ul className="flex lg:space-x-2 md:space-x-0 items-center justify-center">
							<li
								className={`px-4 rounded-full bg-gradient-to-r from-[#0081fb] to-blue-900 text-white font-semibold transition-all duration-300 py-2`}
							>
								<span className="flex flex-row gap-2 items-center">
									<FaHome />
									<Link to="/">{translations[language].home}</Link>
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/dashboard' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/dashboard' ? (
										translations[language].dashboard
									) : (
										<Link to="/dba/dashboard">
											{translations[language].dashboard}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/list-products' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/list-products' ? (
										translations[language].products
									) : (
										<Link to="/dba/list-products">
											{translations[language].products}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/list-vr-games' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/list-vr-games' ? (
										translations[language].listVRGames
									) : (
										<Link to="/dba/list-vr-games">
											{translations[language].listVRGames}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/list-users' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/list-users' ? (
										translations[language].listUsers
									) : (
										<Link to="/dba/list-users">
											{translations[language].listUsers}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/list-requests' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/list-requests' ? (
										translations[language].listRequests
									) : (
										<Link to="/dba/list-requests">
											{translations[language].listRequests}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/list-orders' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/list-orders' ? (
										translations[language].listOrders
									) : (
										<Link to="/dba/list-orders">
											{translations[language].listOrders}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/upload-cover' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/upload-cover' ? (
										translations[language].uploadCover
									) : (
										<Link to="/dba/upload-cover">
											{translations[language].uploadCover}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/download-games-cover' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/download-games-cover' ? (
										translations[language].downloadGamesCover
									) : (
										<Link to="/dba/download-games-cover">
											{translations[language].downloadGamesCover}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/download-products-cover' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/download-products-cover' ? (
										translations[language].downloadProductsCover
									) : (
										<Link to="/dba/download-products-cover">
											{translations[language].downloadProductsCover}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/upload-to-drive' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/upload-to-drive' ? (
										translations[language].uploadtoDrive
									) : (
										<Link to="/dba/upload-to-drive">
											{translations[language].uploadtoDrive}
										</Link>
									)}
								</span>
							</li>
							<li
								className={`px-4 py-1 rounded-full ${location.pathname === '/dba/firmware-quest' ? 'bg-[#0081fb] text-white font-semibold py-2' : `text-black hover:bg-gray-300  transition-all duration-300 py-2`}`}
							>
								<span className="flex gap-2 items-center">
									{location.pathname === '/dba/firmware-quest' ? (
										translations[language].firmwareQuest
									) : (
										<Link to="/dba/firmware-quest">
											{translations[language].firmwareQuest}
										</Link>
									)}
								</span>
							</li>
						</ul>
					)}
				</div>

				{!window.location.pathname.startsWith('/dba') && (
					<div className="lg:flex absolute lg:visible invisible inset-0 flex items-center justify-center">
						<Link to="/">
							<LazyLoadImage
								src={logoHypertopia}
								alt="logo-hypertopia"
								className="h-10 cursor-pointer"
							/>
						</Link>
					</div>
				)}
				<div className="mr-5 z-50 flex flex-row items-center justify-end gap-2">
					<div className="flex items-center gap-6">
						<Link to="/account/cart">
							<button
								className={`relative hover:bg-zinc-300 transition-all duration-300 p-2 rounded-full`}
							>
								<FaCartShopping className={`text-2xl text-black`} />
								<span className="badge text-sm font-semibold bg-[#0081FB] px-1.5 rounded-full">
									{cartItems.length}
								</span>
							</button>
						</Link>
						<div
							className="relative inline-block text-left"
							ref={dropdownRef}
							onMouseEnter={() => setDropdownOpen(true)}
							onMouseLeave={() => setDropdownOpen(false)}
						>
							<button type="button" className="cursor-pointer outline-none">
								{user ? (
									<img
										src={user.photoURL}
										alt=""
										className={`size-11 rounded-full border-2 border-zinc-300 hover:border-[#0081FB] transition-all duration-300 outline-none`}
									/>
								) : (
									<button className="flex flex-row gap-2 items-center bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl">
										<FaSignInAlt />
										<p className="font-semibold">
											{translations[language].signIn}
										</p>
									</button>
								)}
							</button>
							<div
								className={`${
									isDropdownOpen
										? 'opacity-100 scale-100 visible'
										: 'opacity-0 scale-95 invisible'
								} flex flex-col transform transition-all duration-300 origin-top-right absolute right-0 border-zinc-200 border-2 p-3 w-56 rounded-xl shadow-2xl bg-white`}
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="userMenuButton"
							>
								<div className="flex px-2 gap-6 flex-row mb-2 items-center justify-between">
									<span className={`font-semibold text-black`}>
										{translations[language].language}
									</span>
									<select
										onChange={handleLanguageChange}
										value={language}
										className={`bg-white hover:bg-zinc-200 text-black outline-none px-2 py-2 w-fit cursor-pointer border-none text-right rounded-xl`}
										role="menuitem"
									>
										<option value="en">EN</option>
										<option value="id">ID</option>
									</select>
								</div>

								<hr className={`border-zinc-200 border my-2`} />

								<button
									className={`bg-gradient-to-br from-green-400 to-green-600 rounded-xl w-full h-full flex px-3 py-2 mb-2 flex-row gap-3 items-center text-white`}
									onClick={handleToChatAdmin}
								>
									<FaWhatsapp />
									<span>{translations[language].admin}</span>
								</button>
								{user && allowedEmails.includes(user.email) && (
									<button
										className={`hover:bg-zinc-200 rounded-lg w-full flex px-2 py-2 mb-2 flex-row gap-4 items-center text-[#0081FB]`}
										onClick={handleToDashboardAdmin}
									>
										<RiAdminFill />
										<span className="font-semibold">
											{translations[language].adminDashboard}
										</span>
									</button>
								)}
								{user ? (
									<span className="flex flex-col">
										<Link to="/account/orders">
											<button
												className={`mb-2 hover:bg-zinc-200 w-full flex flex-row gap-4 px-2 items-center py-2 rounded-lg`}
												onClick={() => setDropdownOpen(false)}
											>
												<FaBoxArchive />
												<span className="font-semibold flex flex-row gap-2">
													<p className="">
														{translations[language].yourOrders}
													</p>
													<p className="bg-[#0081FB] px-2 text-white rounded-full">
														{orders.length}
													</p>
												</span>
											</button>
										</Link>
										<button
											className={`hover:bg-zinc-200 w-full flex flex-row gap-4 px-2 items-center py-2 rounded-lg text-red-500`}
											onClick={handleSignOut}
										>
											<FaSignOutAlt />
											<span className="font-semibold">
												{translations[language].signOut}
											</span>
										</button>
									</span>
								) : (
									<Link to="/signin">
										<button
											className={`hover:bg-zinc-200 w-full flex flex-row gap-4 px-2 items-center py-2 rounded-lg text-[#0081FB]`}
										>
											<FaSignInAlt />
											<span className="font-semibold">
												{translations[language].signIn}
											</span>
										</button>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</nav>

			<style>
				{`
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
          .focus {
            width: 250px;
          }

          .font-bebas-neue {
            font-family: 'Bebas Neue', sans-serif;
            font-size: 30px;
            letter-spacing: 0.1rem;
          }

          .badge {
            position: absolute;
            top: 0;
            right: 0;
            color: white;
          }
        `}
			</style>
		</>
	)
}

export default NavBar
