import React, { useState } from "react";

export const ToolTip = ({ children, text }) => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      {children}
      {showToolTip && <div className="tooltip-text text-center" >{text}</div>}
      <style>
        {`
        .tooltip-container {
          position: relative;
          display: inline-block;
          /* border-bottom: 1px dotted black; */
        }
        
        .tooltip-text {
          position: absolute;
          top: calc(-100% + 40px);
          transform: translateX(-50%);
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          padding: 10px;
          border-radius: 10px;
          z-index: 1;
          min-width: 200px;
        }
        `}
      </style>
    </div>
  );
}

export const ToolTip_Apps = ({ children, text }) => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <div
      className="tooltip-container mt-2 z-50"
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      {children}
      {showToolTip && <div className="tooltip-text-app text-center" >{text}</div>}
      <style> 
        {`
        .tooltip-container {
          position: relative;
          display: inline-block;
          /* border-bottom: 1px dotted black; */
        }
        
        .tooltip-text-app {
          position: absolute;
          top: calc(-100%);
          left: calc(500%);
          transform: translateX(-50%);
          background-color: rgba(0, 0, 0, 1.0);
          color: #fff;
          padding: 10px;
          border-radius: 10px;
          z-index: 50;
          width: 170px;
        }
        `}
      </style>
    </div>
  );
}