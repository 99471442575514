import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getDatabase, ref, set, get } from 'firebase/database'
import { FaArrowLeft } from 'react-icons/fa'

const FirmwareFormPage = () => {
	const { id } = useParams()
	const [formData, setFormData] = useState({
		quest: '',
		version: '',
		runtimeVersion: '',
		buildDate: '',
		downloadLink: '',
		incremental: '', // Added incremental field
	})

	useEffect(() => {
		if (id) {
			const [quest, incremental] = id.split('-')
			const db = getDatabase()
			const firmwareRef = ref(db, `firmwareQuest/${quest}/${incremental}`)

			get(firmwareRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const data = snapshot.val()
						setFormData(data)
					} else {
						console.error('No data available')
					}
				})
				.catch((error) => {
					console.error('Error fetching firmware data:', error)
				})
		}
	}, [id])

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const db = getDatabase()
		const firmwareRef = ref(
			db,
			`firmwareQuest/${formData.quest}/${formData.incremental || (id ? id.split('-')[1] : Date.now())}`
		)

		set(firmwareRef, formData)
			.then(() => {
				window.location.href = '/dba/firmware-quest'
			})
			.catch((error) => {
				console.error('Error saving firmware data:', error)
			})
	}

	return (
		<div className="min-h-screen w-screen p-6">
			<button className='w-full px-4 py-2 rounded-xl bg-red-500 text-white flex flex-row gap-2 justify-center items-center'>
				<a href="/dba/firmware-quest">
					Back to Firmware List
				</a>
				<FaArrowLeft />
			</button>
			<h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
				{id ? 'Edit Firmware' : 'Add Firmware'}
			</h1>
			<form
				onSubmit={handleSubmit}
				className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg"
			>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Quest Type
					</label>
					<select
						name="quest"
						value={formData.quest}
						onChange={handleChange}
						className="w-full px-4 py-2 border rounded-lg"
						required
					>
						<option value="">Select a Quest Type</option>
						<option value="Meta Quest 1">Meta Quest 1</option>
						<option value="Meta Quest 2">Meta Quest 2</option>
						<option value="Meta Quest 3">Meta Quest 3</option>
						<option value="Meta Quest Pro">Meta Quest Pro</option>
					</select>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Version
					</label>
					<input
						type="text"
						name="version"
						value={formData.version}
						onChange={handleChange}
						className="w-full px-4 py-2 border rounded-lg"
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Runtime Version
					</label>
					<input
						type="text"
						name="runtimeVersion"
						value={formData.runtimeVersion}
						onChange={handleChange}
						className="w-full px-4 py-2 border rounded-lg"
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Build Date
					</label>
					<input
						type="text"
						name="buildDate"
						value={formData.buildDate}
						onChange={handleChange}
						className="w-full px-4 py-2 border rounded-lg"
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Download Link
					</label>
					<input
						type="text"
						name="downloadLink"
						value={formData.downloadLink}
						onChange={handleChange}
						className="w-full px-4 py-2 border rounded-lg"
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Incremental
					</label>
					<input
						type="text"
						name="incremental"
						value={formData.incremental}
						onChange={handleChange}
						className="w-full px-4 py-2 border rounded-lg"
						required={!id} // only required when adding new firmware
					/>
				</div>
				<button
					type="submit"
					className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
				>
					{id ? 'Update Firmware' : 'Add Firmware'}
				</button>
			</form>
		</div>
	)
}

export default FirmwareFormPage
