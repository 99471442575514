import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import fireDb from '../../firebase'
import { FaAngleRight, FaCheckCircle, FaTimes } from 'react-icons/fa'
import { auth } from '../../firebase'
import { Link } from 'react-router-dom'
import { BsHeadsetVr, BsLaptop } from 'react-icons/bs'
import { MdGroups } from 'react-icons/md'
import { ImSpinner2 } from 'react-icons/im'
import { Icon } from '@iconify/react'

const ListGames = () => {
	const language = localStorage.getItem('language') || 'id'
	const eligibleUsersRef = fireDb.ref('eligibleUsers/standalone')
	const [user, setUser] = useState(null)
	const [standaloneGames, setStandaloneGames] = useState([])
	const [pcvrGames, setPcvrGames] = useState([])
	const [showAnnouncement, setShowAnnouncement] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setShowAnnouncement(false)
					} else {
						setShowAnnouncement(true)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [showAnnouncement, eligibleUsersRef, user])

	useEffect(() => {
		const gamesRef = fireDb.ref(`vrGames/standalone`)
		const pcvrRef = fireDb.ref(`vrGames/pcvr`)
		gamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val()).filter(
					(game) => game.gameTitle && game.gameTitle.trim() !== ''
				)
				setStandaloneGames(gamesData)
			} else {
				setStandaloneGames([])
			}
		})

		pcvrRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val()).filter(
					(game) => game.gameTitle && game.gameTitle.trim() !== ''
				)
				setPcvrGames(gamesData)
			} else {
				setPcvrGames([])
			}
		})

		return () => {
			gamesRef.off('value')
			pcvrRef.off('value')
		}
	}, [])

	const translations = {
		en: {
			new: 'New',
			info: 'Info',
			hintInfo:
				'Click one of the menus below to see the list of available games!',
			multiplayerStatusList: 'List of Multiplayer Game Status',
			listGames: 'List Games',
			vrGames: 'VR Games',
			standalone: 'Standalone',
			descStandalone:
				'Applications/Games that can be run directly on the Quest headset without the need to be connected to a PC (only when installation is needed to be connected to a PC/Laptop/Phone).',

			pcvr: 'PC VR',
			descPCVR:
				'Applications/Games that require the Quest headset to be connected to a PC to run it.',

			comingSoon: 'Coming Soon',
		},
		id: {
			new: 'Baru',
			info: 'Info',
			hintInfo:
				'Klik salah satu menu di bawah ini untuk melihat daftar game yang tersedia!',
			multiplayerStatusList: 'Daftar Status Multiplayer Game',
			listGames: 'Daftar Game',
			vrGames: 'Game VR',
			standalone: 'Standalone',
			descStandalone:
				'Aplikasi/Game yang dapat dijalankan langsung di headset Quest tanpa memerlukan terhubung terus ke PC (hanya saat untuk instalasi yang perlu terhubung ke PC/Laptop/HP).',

			pcvr: 'PC VR',
			descPCVR:
				'Aplikasi/Game yang memerlukan Headset Quest terhubung terus ke PC untuk menjalankannya.',

			comingSoon: 'Segera Hadir',
		},
	}

	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 800) {
				setGridColsVRGamesMenu('flex-row')
			} else {
				setGridColsVRGamesMenu('flex-col')
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const eligibleUsersStandaloneRef = fireDb.ref('eligibleUsers/standalone')
	const eligibleUsersPCVRRef = fireDb.ref('eligibleUsers/pcvr')

	const [eligibleStandaloneGameQuest, setEligibleStandaloneGameQuest] =
		useState(false)
	const [eligiblePCVRGameQuest, setEligiblePCVRGameQuest] = useState(false)

	const [loadingStandalone, setLoadingStandalone] = useState(true)
	const [loadingPCVR, setLoadingPCVR] = useState(true)

	useEffect(() => {
		// Single onAuthStateChanged listener
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setUser(user)

			if (user) {
				// Check Standalone Eligibility
				setLoadingStandalone(true)
				try {
					const standaloneSnapshot = await eligibleUsersStandaloneRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					setEligibleStandaloneGameQuest(!!standaloneSnapshot.val())
				} catch (error) {
					console.error('Error checking Standalone eligibility:', error)
				} finally {
					setLoadingStandalone(false)
				}

				// Check PCVR Eligibility
				setLoadingPCVR(true)
				try {
					const pcvrSnapshot = await eligibleUsersPCVRRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					setEligiblePCVRGameQuest(!!pcvrSnapshot.val())
				} catch (error) {
					console.error('Error checking PCVR eligibility:', error)
				} finally {
					setLoadingPCVR(false)
				}
			} else {
				setLoadingStandalone(false)
				setLoadingPCVR(false)
			}
		})

		// Clean up listener on unmount
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`pt-5 mx-5 w-full min-h-screen ${!isMobile && 'pt-[100px]'}`}
		>
			<Link to="/vr-games/multiplayer-status" className="w-full mb-4">
				<button className="mb-4 w-full px-4 py-3 rounded-2xl border-2 bg-blue-500 hover:bg-blue-600 text-white">
					<div className="flex flex-row justify-between items-center">
						<div className="flex flex-row gap-3 items-center">
							<MdGroups size={24} />
							<p className="font-semibold">
								{translations[language].multiplayerStatusList}
							</p>
						</div>
						<FaAngleRight size={20} />
					</div>
				</button>
			</Link>
			<div
				className={`flex ${gridColsVRGamesMenu} justify-between gap-4 w-full`}
			>
				<Link to="./standalone" className="h-96 w-full">
					<button
						className={`relative hover:bg-blue-100 bg-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-center items-center ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full shadow-lg`}
					>
						<div
							className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-center justify-center h-full`}
						>
							<BsHeadsetVr
								size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
							/>
							<span className="flex flex-col gap-1 items-center justify-center text-center">
								<span
									className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold flex flex-row gap-2 items-center`}
								>
									<p>{translations[language].standalone}</p>
								</span>
								<span
									className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
								>
									{translations[language].descStandalone}
								</span>
							</span>
							<p className="px-3 py-1 border-2 rounded-lg bg-[#0081FB] text-white text-lg font-semibold">
								{standaloneGames.length} Games
							</p>
						</div>

						<div
							className={`flex flex-row items-center gap-2 absolute top-5 right-5 ${eligibleStandaloneGameQuest ? 'bg-[#0081FB]' : 'bg-red-500'} px-3 py-2 rounded-xl`}
						>
							{loadingStandalone ? (
								<div className="flex items-center text-white">
									<Icon icon="line-md:loading-loop" fontSize={24} />
									<span className="ml-2 font-semibold">
										{language === 'en'
											? 'Checking access...'
											: 'Memeriksa akses...'}
									</span>
								</div>
							) : eligibleStandaloneGameQuest ? (
								<div className="flex items-center text-white">
									<Icon icon="line-md:check-all" fontSize={24} />
									<span className="ml-2 font-semibold">
										{language === 'en'
											? 'You have access'
											: 'Anda memiliki akses'}
									</span>
								</div>
							) : (
								<div className="flex items-center text-white">
									<Icon icon="line-md:cancel" fontSize={24} />
									<span className="ml-2 font-semibold">
										{language === 'en'
											? 'You do not have access'
											: 'Anda tidak memiliki akses'}
									</span>
								</div>
							)}
						</div>
					</button>
				</Link>

				<Link to="./pcvr" className="h-96 w-full">
					<button
						className={`relative hover:bg-blue-100 bg-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-center items-center ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full shadow-lg`}
					>
						<div
							className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-center justify-center h-full`}
						>
							<BsHeadsetVr
								size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
							/>
							<span className="flex flex-col gap-1 items-center justify-center text-center">
								<span
									className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold flex flex-row gap-2 items-center`}
								>
									<p>{translations[language].pcvr}</p>
								</span>
								<span
									className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
								>
									{translations[language].descPCVR}
								</span>
							</span>
							<p className="px-3 py-1 border-2 rounded-lg bg-[#0081FB] text-white text-lg font-semibold">
								{pcvrGames.length} Games
							</p>
						</div>

						<div
							className={`flex flex-row items-center gap-2 absolute top-5 right-5 ${eligiblePCVRGameQuest ? 'bg-[#0081FB]' : 'bg-red-500'} px-3 py-2 rounded-xl`}
						>
							{loadingPCVR ? (
								<div className="flex items-center text-white">
									<Icon icon="line-md:loading-loop" fontSize={24} />
									<span className="ml-2 font-semibold">
										{language === 'en'
											? 'Checking access...'
											: 'Memeriksa akses...'}
									</span>
								</div>
							) : eligiblePCVRGameQuest ? (
								<div className="flex items-center text-white">
									<Icon icon="line-md:check-all" fontSize={24} />
									<span className="ml-2 font-semibold">
										{language === 'en'
											? 'You have access'
											: 'Anda memiliki akses'}
									</span>
								</div>
							) : (
								<div className="flex items-center text-white">
									<Icon icon="line-md:cancel" fontSize={24} />
									<span className="ml-2 font-semibold">
										{language === 'en'
											? 'You do not have access'
											: 'Anda tidak memiliki akses'}
									</span>
								</div>
							)}
						</div>
					</button>
				</Link>
			</div>
			<Helmet>
				<title>
					{translations[language].listGames} | {translations[language].vrGames}-
					HyperTopia
				</title>
			</Helmet>
			<style>
				{`
          @keyframes scroll {
            0% {
              transform: translateX(250%);
            }
            100% {
              transform: translateX(-250%);
            }
          }

          .animate-scroll-wrapper {
            overflow: hidden;
            display: inline-block;
          }

          .animate-scroll {
            display: inline-block;
            white-space: nowrap;
            animation: scroll 15s linear infinite;
          }

          .search-box {
            width: 100px;
          }
        `}
			</style>
		</div>
	)
}

export default ListGames
