import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import fireDb from '../../firebase';
import { auth } from '../../firebase';
import QRISLogo from '../../assets/images/qris-logo.png';
import ShopeePayLogo from '../../assets/images/shopeepay-logo.png';
import GoPayLogo from '../../assets/images/gopay-logo.png';
import DANALogo from '../../assets/images/dana-logo.png';
import BankMandiriLogo from '../../assets/images/bankmandiri-logo.png';
import { FaArrowLeft, FaCircleInfo, FaWallet } from 'react-icons/fa6';
import { ToolTip } from '../../components/ToolTip';
import { BsFiletypeAac, BsFiletypeAi, BsFiletypeBmp, BsFiletypeCs, BsFiletypeCss, BsFiletypeCsv, BsFiletypeDoc, BsFiletypeDocx, BsFiletypeExe, BsFiletypeGif, BsFiletypeHeic, BsFiletypeHtml, BsFiletypeJava, BsFiletypeJpg, BsFiletypeJs, BsFiletypeJson, BsFiletypeJsx, BsFiletypeKey, BsFiletypeM4P, BsFiletypeMd, BsFiletypeMdx, BsFiletypeMov, BsFiletypeMp3, BsFiletypeMp4, BsFiletypeOtf, BsFiletypePdf, BsFiletypePhp, BsFiletypePng, BsFiletypePpt, BsFiletypePptx, BsFiletypePsd, BsFiletypePy, BsFiletypeRaw, BsFiletypeRb, BsFiletypeSass, BsFiletypeScss, BsFiletypeSh, BsFiletypeSql, BsFiletypeSvg, BsFiletypeTiff, BsFiletypeTsx, BsFiletypeTtf, BsFiletypeTxt, BsFiletypeWav, BsFiletypeWoff, BsFiletypeXls, BsFiletypeXlsx, BsFiletypeXml, BsFiletypeYml, BsSendFill, BsShieldFillCheck } from 'react-icons/bs';
import { FaCheckCircle, FaFileArchive, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import QRIS_HYPERTOPIA from '../../assets/images/qrisHT.jpeg';
import { storage } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { TbDragDrop, TbReplace, TbSend } from "react-icons/tb";
import Swal from 'sweetalert2';

import ProgressBar from '@ramonak/react-progress-bar';

const PayOrder = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [user, setUser] = useState(null);
  const storedLanguage = localStorage.getItem('language') || 'id';
  const [language, setLanguage] = useState(storedLanguage);
  const [showPopupChangePaymentMethod, setShowPopupChangePaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('QRIS');
  const [paymentProofURL, setPaymentProofURL] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [showImagePreviewPopup, setShowImagePreviewPopup] = useState(false);
  const [showUploadPaymentProof, setShowUploadPaymentProof] = useState(false);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);

  const [showPopUpPayOrder, setShowPopUpPayOrder] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);

  const [emailStatuses, setEmailStatuses] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const [currentSpeedUnit, setCurrentSpeedUnit] = useState('');

  const [remainingTimeMinutes, setRemainingTimeMinutes] = useState(0);
  const [remainingTimeSecondsRemainder, setRemainingTimeSecondsRemainder] = useState(0);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setSelectedFile(file);
    setFilePreviewUrl(URL.createObjectURL(file));
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    setFileName(file.name);
    setSelectedFile(file);
    setFilePreviewUrl(URL.createObjectURL(file));
    uploadPaymentProof(file);
  };
  

  const handleUpload = () => {
    if (selectedFile) {
      uploadPaymentProof(selectedFile);
      // Reset selected file after uploading
      setSelectedFile(null);
      setFileName('');
    }
  };

  const handleReplace = () => {
    // Clear selected file and file name
    setSelectedFile(null);
    setFileName('');
    // Revoke object URL for preview image
    if (filePreviewUrl) {
      URL.revokeObjectURL(filePreviewUrl);
      setFilePreviewUrl(null); // Clear preview URL
    }
  };
  

  useEffect(() => {
    return () => {
      if (filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
      }
    };
  }, [filePreviewUrl]);  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getStatuses = () => {
        if (!orderDetails || !orderDetails.items) {
            return; // Exit early if orderDetails or items are null
        } else {
            Promise.all(
                orderDetails.items.map((item) => {
                    // Check if productName matches the specified product
                    if (item.productName === "Games For Oculus Meta Quest 1 2 3 dan Pro") {
                        return Promise.all(
                            item.emails.map((email) => {
                                let emailStatus = 'Tidak Terdaftar';
                                const userId = email.split('@')[0];
                                const eligibleUsersRef = fireDb.ref(`eligibleUsers/${userId}`);
                                return eligibleUsersRef.once('value').then((snapshot) => {
                                    const user = snapshot.val();
                                    if (user && user.email === email) {
                                        emailStatus = 'Terdaftar';
                                    }
                                    return emailStatus;
                                });
                            })
                        );
                    } else {
                        // If productName doesn't match, return an empty array
                        return [];
                    }
                })
            ).then((statuses) => {
                setEmailStatuses(statuses.flat());
            });
        }
    };
    getStatuses();
}, [orderDetails, fireDb]);

  const translations = {
    en: {
      uploadSuccess: 'Upload Success!',
      changeFile: 'Change File',
      sendFile: 'Send File',
      dropYourFileHere: 'Drop your file here',
      dragYourPaymentProofHere: 'Drag your payment proof file here, or click to select a file',
      orderCompleted: 'Order Completed',
      status: 'Status',
      downloadQRIS: 'Download QRIS',
      orderPayment: 'Order Payment of',
      order: 'Order',
      orderDetails: 'Order Details',
      paymentDetails: 'Payment Details',
      orderId: 'Order ID: ',
      payOrder: 'Pay Order',
      productName: 'Product Name: ',
      quantity: 'Qty(s): ',
      totalPrice: 'Total Price: ',
      timeCreated: 'Time Order Created: ',
      currency: 'IDR ',
      paymentMethod: 'Payment Method',
      confirmPayment: 'Confirm Payment',
      choose: 'Choose',
      back: 'Back',

      change: 'Change',
      orderTotal: 'Order Total',
      serviceFee: 'Service Fee',
      serviceFeeInfo: 'To help improve HyperTopia website services, 0.005% of the total order',
      paymentTotal: 'Payment Total',
      securePayment: '100% Secure Payments Guaranteed',
      poweredBy: 'Powered by',

      QRISInfo: 'Supports QRIS scan from any E-Wallet app (GoPay, ShopeePay, DANA, Livin\' by Mandiri, QRIS BNI, and other mBanking QRIS)',
      ShopeePayInfo: 'Supports ShopeePay payment',
      GoPayInfo: 'Supports GoPay payment',
      DANAInfo: 'Supports DANA payment',
      BankMandiriInfo: 'Supports Bank Mandiri Transfer payment',

      adminFree: 'Free Admin',
      notAvailable: 'Not Available',

      notPaymentYet: 'Not Paid Yet',
      orderPaid: 'Order Paid',
      waitingVerification: 'Waiting Verification',
      waitingConfirmation: "Waiting Confirmation",
      orderConfirmed: 'Order Confirmed',
      orderSent: 'Order Sent',
      seePaymentProof: 'See Payment Proof',
      uploadPaymentProof: 'Upload Payment Proof',
      paymentVerified: 'Payment Verified',

      verifyingAndValidating: 'Currently Verifying and Validating Payment Proof',
      waitingConfirmationFromSeller: 'Your payment proof is currently being verified. Please wait for further confirmation from us.',

      pleaseToPay: 'Please pay',
      attentionToDigit: 'Pay attention to the nominal digits!'

    },
    id: {
      uploadSuccess: 'Upload Berhasil!',
      changeFile: 'Ganti File',
      sendFile: 'Kirim File',
      dropYourFileHere: 'Taruh file Anda di sini',
      dragYourPaymentProofHere: 'Tarik file bukti pembayaran Anda ke sini, atau klik untuk memilih file',
      orderCompleted: 'Pesanan Selesai',
      status: 'Status',
      downloadQRIS: 'Unduh QRIS',
      order: 'Pesanan',
      orderPayment: 'Pembayaran Pesanan dari',
      orderDetails: 'Detail Pesanan',
      paymentDetails: 'Detail Pembayaran',
      orderId: 'ID Pesanan: ',
      payOrder: 'Bayar Pesanan',
      productName: 'Nama Produk: ',
      quantity: 'Jumlah: ',
      totalPrice: 'Total Harga: ',
      timeCreated: 'Waktu Pesanan Dibuat: ',
      currency: 'Rp',
      paymentMethod: 'Metode Pembayaran',
      confirmPayment: 'Konfirmasi Pembayaran',
      choose: 'Pilih',
      back: 'Kembali',

      change: 'Ubah',
      orderTotal: 'Total Pesanan',
      serviceFee: 'Biaya Layanan',
      serviceFeeInfo: 'Untuk membantu meningkatkan layanan website HyperTopia, sebanyak 0.005% dari total pesanan',
      paymentTotal: 'Total Pembayaran',
      securePayment: 'Pembayaran Aman 100% Dijamin',
      poweredBy: 'oleh',

      QRISInfo: 'Mendukung Scan QRIS dari aplikasi E-Wallet apapun (GoPay, ShopeePay, DANA, Livin\' by Mandiri, QRIS BNI, dan QRIS mBanking lainnya)',
      ShopeePayInfo: 'Mendukung pembayaran ShopeePay',
      GoPayInfo: 'Mendukung pembayaran GoPay',
      DANAInfo: 'Mendukung pembayaran DANA',
      BankMandiriInfo: 'Mendukung pembayaran Transfer Bank Mandiri',

      adminFree: 'Gratis Admin',
      notAvailable: 'Tidak Tersedia',

      notPaymentYet: 'Belum Bayar',
      orderPaid: 'Pesanan Dibayar',
      waitingVerification: 'Menunggu Verifikasi',
      waitingConfirmation: "Menunggu Konfirmasi",
      orderSent: 'Pesanan Dikirim',
      orderConfirmed: 'Pesanan Dikonfirmasi',
      seePaymentProof: 'Lihat Bukti Bayar',
      uploadPaymentProof: 'Unggah Bukti Bayar',
      paymentVerified: 'Pembayaran Terverifikasi',

      verifyingAndValidating: 'Sedang Verifikasi dan Validasi Bukti Bayar',
      waitingConfirmationFromSeller: 'Bukti bayar Anda sedang dalam proses verifikasi. Mohon tunggu konfirmasi lebih lanjut dari kami.',

      pleaseToPay: 'Mohon untuk membayar',
      attentionToDigit: 'Perhatikan digit nominalnya!'

    },
  }

  useEffect(() => {
    if (user) {
      const orderRef = fireDb.ref('orders').child(user.uid).child(orderId);
      orderRef.on('value', (snapshot) => {
        setOrderDetails(snapshot.val());
      });

      return () => {
        orderRef.off('value');
      };
    }
  }, [orderId, user]);

  useEffect(() => {
    if (user) {
      const orderRef = fireDb.ref('orders').child(user.uid).child(orderId);
      orderRef.on('value', (snapshot) => {
        setOrderDetails(snapshot.val());
      });

      const selectedPaymentMethodRef = fireDb.ref(`orders/${user.uid}/${orderId}/paymentMethod`);
      selectedPaymentMethodRef.on('value', (snapshot) => {
        const paymentMethod = snapshot.val();
        if (paymentMethod) {
          setSelectedPaymentMethod(paymentMethod);
        }
      });

      const searchParams = new URLSearchParams(window.location.search);
      const paymentMethod = searchParams.get('payment');
      if (paymentMethod) {
        setSelectedPaymentMethod(paymentMethod);
      }
      window.history.replaceState({}, '', `${window.location.pathname}?payment=${selectedPaymentMethod}`);

      return () => {
        selectedPaymentMethodRef.off('value');
      };
    }
  }, [orderId, user, selectedPaymentMethod]);

  if (!orderDetails) {
    return <div>Loading...</div>;
  }


  const formatDate = (isoString, language) => {
    const date = new Date(isoString);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      timeZoneName: 'short' 
    };
    const locale = language === 'id' ? 'id-ID' : 'en-US';
    return date.toLocaleDateString(locale, options);
  };

  const stringToNumber = (string) => {
    return Number(string.replace(/[^0-9.-]+/g,""));
  }

  const adminPayment = (totalPrice) => {
    return Math.ceil(0.005 * stringToNumber(totalPrice));
  }

  const totalPayment = (totalPrice) => {
    return stringToNumber(totalPrice) + adminPayment(totalPrice);
  }

  const handleChangePaymentMethod = () => {
    setShowPopupChangePaymentMethod(true);
  }

  // Fungsi untuk mengirim metode pembayaran yang dipilih ke database
  const sendPaymentMethodToDatabase = () => {
    if (selectedPaymentMethod) {
      fireDb.ref(`orders/${user.uid}/${orderId}/paymentMethod`).set(selectedPaymentMethod)
        .then(() => {
          setShowPopupChangePaymentMethod(false);
          // Lakukan tindakan lain jika diperlukan setelah pengiriman ke database berhasil
        })
        .catch((error) => {
          // Handle error jika pengiriman ke database gagal
        });
    } else {
    }
  };

  // Fungsi untuk menangani pemilihan metode pembayaran
  const handlePaymentMethodSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);

    // Lakukan tindakan lain jika diperlukan setelah pemilihan metode pembayaran
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('payment', paymentMethod);
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
  };

  const handlePayOrder = () => {
    setShowPopUpPayOrder(true);

    const hasPaidRef = fireDb.ref(`orders/${user.uid}/${orderId}/hasPaid`);
    hasPaidRef.on('value', (snapshot) => {
      const hasPaid = snapshot.val();
      if (hasPaid) {
        setHasPaid(true);
      }
    });

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('payment', selectedPaymentMethod);
    window.history.replaceState({}, '', `${window.location.pathname}?payment=${selectedPaymentMethod}&hasPaid=${orderDetails.hasPaid}`);

    return () => {
      hasPaidRef.off('value');
    };
  }

  const calculateRemainingTime = (remainingBytes, currentSpeed) => {
    const remainingSeconds = remainingBytes / currentSpeed;
    const remainingMinutes = Math.floor(remainingSeconds / 60);
    const remainingSecondsRemainder = Math.round(remainingSeconds % 60);
    return { remainingMinutes, remainingSecondsRemainder };
  };

  const uploadPaymentProof = (file) => {
    const storageRef = ref(storage, `paymentProofs/${orderId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    let lastTimestamp = Date.now(); // Timestamp terakhir
    let lastUploadedBytes = 0; // Jumlah byte terakhir yang diunggah

    // Tambahkan listener untuk mengatur kemajuan unggahan
    uploadTask.on('state_changed', 
      (snapshot) => {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(percentage); // Update state kemajuan unggahan

        // Hitung kecepatan saat ini
        const currentTimestamp = Date.now();
        const elapsedTime = currentTimestamp - lastTimestamp;
        const uploadedBytes = snapshot.bytesTransferred - lastUploadedBytes;
        const currentSpeed = (uploadedBytes / elapsedTime) * 1000; // dalam bytes per detik

        // Konversi kecepatan ke dalam satuan yang lebih besar
        let speedFormatted = currentSpeed / 1024; // Konversi ke Kbps
        let unit = 'Kbps'; // Satuan awal

        if (speedFormatted > 1024) {
          speedFormatted /= 1024; // Konversi ke Mbps
          unit = 'Mbps';
        }

        if (speedFormatted > 1024) {
          speedFormatted /= 1024; // Konversi ke Gbps
          unit = 'Gbps';
        }

        if (speedFormatted > 1024) {
          speedFormatted /= 1024; // Konversi ke Tbps
          unit = 'Tbps';
        }


        const remainingBytes = snapshot.totalBytes - snapshot.bytesTransferred;

        // Hitung estimasi waktu yang tersisa
        const { remainingMinutes, remainingSecondsRemainder } = calculateRemainingTime(remainingBytes, currentSpeed);

        // Atur nilai remainingTimeMinutes dan remainingTimeSecondsRemainder
        setRemainingTimeMinutes(remainingMinutes);
        setRemainingTimeSecondsRemainder(remainingSecondsRemainder);

        // Simpan nilai untuk penggunaan di iterasi berikutnya
        lastUploadedBytes = snapshot.bytesTransferred;
        lastTimestamp = currentTimestamp;

        // Atur kecepatan saat ini ke dalam state
        setCurrentSpeed(speedFormatted);
        setCurrentSpeedUnit(unit);
      },
      (error) => {
        console.error('Error uploading file:', error);
        // Handle error uploading file
      },
      async () => {
        // Ketika unggahan selesai, dapatkan URL download dari referensi penyimpanan
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setSelectedImageUrl(downloadURL); // Set selected image URL
        setShowImagePreviewPopup(true); // Tampilkan popup pratinjau gambar
      }
    );
};

  const handleOrderCompleted = () => {
    const orderRef = fireDb.ref(`orders/${user.uid}/${orderId}`);
    orderRef
      .update({ status: 'Pesanan Selesai' })
      .then(() => {
        // Menampilkan sweet alert untuk memberi tahu pengguna bahwa pesanan telah selesai
        Swal.fire({
          icon: 'success',
          title: 'Pesanan Selesai!',
          text: 'Terima kasih atas pesanan Anda. Pesanan Anda telah selesai diproses.',
          confirmButtonText: 'OK',
        }).then(() => {
          // Reload halaman setelah pengguna menekan tombol OK pada sweet alert
          window.history.replaceState({}, '', `${window.location.pathname}?payment=${selectedPaymentMethod}&hasPaid=${orderDetails.hasPaid}`);
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error('Error updating order status:', error);
        // Handle error jika pembaruan status pesanan gagal
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Terjadi kesalahan saat memperbarui status pesanan. Silakan coba lagi.',
          confirmButtonText: 'OK',
        });
      });
  };

  
  const handleSendPicture = () => {
    const paymentProofRef = fireDb.ref(`orders/${user.uid}/${orderId}`);
    paymentProofRef.update({ paymentProof: selectedImageUrl, hasPaid: true, status: 'Menunggu Verifikasi'})
      .then(() => {
        window.history.replaceState({}, '', `${window.location.pathname}?payment=${selectedPaymentMethod}&hasPaid=${orderDetails.hasPaid}`);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error sending payment proof to database:', error);
        // Handle error jika pengiriman ke database gagal
      });
  };

  return (
    <div className=' pt-5 mx-5 pb-5 w-full'>
      <Link to="/account/orders">
        <button className='text-red-500 hover:text-red-700 transition-all flex flex-row gap-2 items-center justify-center mb-4'>
          <FaArrowLeft className='text-xl' />
          <p className='text-xl font-semibold'>{translations[language].back}</p>
        </button>
      </Link>
      {showPopupChangePaymentMethod && (
        <div className="fixed p-5 top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-white p-4 rounded-lg w-[400px]">
            <div className="flex justify-between items-center gap-5 mb-3">
              <p className="text-xl font-bold">{translations[language].paymentMethod}</p>
            </div>
            <hr className='my-4' />
            {/* HYPERWALLET */}
            <div>
              <span className='flex flex-row items-center gap-2 mb-3'>
                <p className='font-bold'>HyperWallet</p>
                <p className='bg-green-500 text-white w-fit px-2 rounded-md text-sm'>{translations[language].adminFree}</p>
              </span>

              <label className="flex justify-between items-center mb-2">
                <span className='flex flex-row gap-1'>
                  <FaWallet className="text-2xl mr-2 text-[#0081FB] grayscale" />
                  <span className='flex flex-row items-center gap-2 mb-3'>
                    <p className='font-semibold'>Rp0</p>
                    <p className='bg-red-500 text-white w-fit px-2 rounded-md font-semibold text-sm'>{translations[language].notAvailable}</p>
                  </span>
                </span>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="HyperWallet"
                  onChange={() => handlePaymentMethodSelect("HyperWallet")}
                  className="mr-2"
                  disabled
                />
              </label>
            </div>

            <hr className='my-4' />

            {/* E-WALLET */}
            <div className='flex flex-col gap-4'>
              <span className='flex flex-row items-center gap-2 mb-3'>
                <p className='font-bold'>E-Wallet</p>
              </span>
              <label className="flex items-start mb-2 justify-between w-full">
                <span className='w-24'>
                  <img src={QRISLogo} alt="ShopeePay" className="w-16 object-cover grayscale" />
                </span>
                <span className='flex items-start justify-between gap-4 w-full'>
                  <span>
                    <span className='flex flex-row items-center gap-2 mb-1'>
                      <p className='font-semibold'>QRIS</p>
                    </span>
                    <p className='text-zinc-400 text-sm'>{translations[language].QRISInfo}</p>
                  </span>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="QRIS"
                    onChange={() => handlePaymentMethodSelect("QRIS")}
                    className="mr-2"
                  />
                </span>
              </label>
              <label className="flex items-start mb-2 justify-between w-full">
                <span className='w-24'>
                  <img src={ShopeePayLogo} alt="ShopeePay" className="w-16 object-cover grayscale" />
                </span>
                <span className='flex items-start justify-between gap-4 w-full'>
                  <span>
                    <span className='flex flex-row items-center gap-2 mb-1'>
                      <p className='font-semibold'>ShopeePay</p>
                      <p className='bg-red-500 text-white w-fit px-2 rounded-md font-semibold text-sm'>{translations[language].notAvailable}</p>
                    </span>
                    <p className='text-zinc-400 text-sm'>{translations[language].ShopeePayInfo}</p>
                  </span>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="ShopeePay"
                    onChange={() => handlePaymentMethodSelect("ShopeePay")}
                    className="mr-2"
                    disabled
                  />
                </span>
              </label>
              <label className="flex items-start mb-2 justify-between w-full">
                <span className='w-24'>
                  <img src={GoPayLogo} alt="GoPay" className="w-16 object-cover grayscale" />
                </span>
                <span className='flex items-start justify-between gap-4 w-full'>
                  <span>
                    <span className='flex flex-row items-center gap-2 mb-1'>
                      <p className='font-semibold'>GoPay</p>
                      <p className='bg-red-500 text-white w-fit px-2 rounded-md font-semibold text-sm'>{translations[language].notAvailable}</p>
                    </span>
                    <p className='text-zinc-400 text-sm'>{translations[language].GoPayInfo}</p>
                  </span>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="GoPay"
                    onChange={() => handlePaymentMethodSelect("GoPay")}
                    className="mr-2"
                    disabled
                  />
                </span>
              </label>
              <label className="flex items-start mb-2 justify-between w-full">
                <span className='w-24'>
                  <img src={DANALogo} alt="DANA" className="w-16 object-cover grayscale" />
                </span>
                <span className='flex items-start justify-between gap-4 w-full'>
                  <span>
                    <span className='flex flex-row items-center gap-2 mb-1'>
                      <p className='font-semibold'>DANA</p>
                      <p className='bg-red-500 text-white w-fit px-2 rounded-md font-semibold text-sm'>{translations[language].notAvailable}</p>
                    </span>
                    <p className='text-zinc-400 text-sm'>{translations[language].DANAInfo}</p>
                  </span>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="DANA"
                    onChange={() => handlePaymentMethodSelect("DANA")}
                    className="mr-2"
                    disabled
                  />
                </span>
              </label>
            </div>

            <hr className='my-4' />

            {/* Transfer Bank */}
            <div>
              <span className='flex flex-row items-center gap-2 mb-3'>
                <p className='font-bold'>Transfer Bank</p>
              </span>

              <label className="flex justify-between items-center mb-2">
                <span className='w-24'>
                  <img src={BankMandiriLogo} alt="Bank Mandiri" className="w-16 object-cover grayscale" />
                </span>
                <span className='flex items-start justify-between gap-4 w-full'>
                  <span>
                    <span className='flex flex-row items-center gap-2 mb-1'>
                      <p className='font-semibold'>Bank Mandiri</p>
                      <p className='bg-red-500 text-white w-fit px-2 rounded-md font-semibold text-sm'>{translations[language].notAvailable}</p>
                    </span>
                    <p className='text-zinc-400 text-sm'>{translations[language].BankMandiriInfo}</p>
                  </span>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="Bank Mandiri"
                    onChange={() => handlePaymentMethodSelect("Bank Mandiri")}
                    className="mr-2"
                    disabled
                  />
                </span>
              </label>
            </div>
            {/* Tombol untuk mengirim metode pembayaran yang dipilih ke database */}
            <button onClick={sendPaymentMethodToDatabase} className='bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full'>
              {translations[language].choose}
            </button>
          </div>
        </div>
      )}

      {showPopUpPayOrder && (
        <div className="fixed p-5 top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-white p-4 rounded-xl w-[400px]">
            <div className="flex justify-between items-center gap-5 mb-3">
              <p className="text-xl font-bold">{translations[language].confirmPayment}</p>
              <button onClick={() => setShowPopUpPayOrder(false)} className='text-red-500 hover:text-red-700 transition-all'>
                <FaTimes className='text-xl' />
              </button>
            </div>
            <hr className='my-4' />
            <p className='text-center text-lg flex-wrap'>{translations[language].pleaseToPay} <span className='text-[#0081FB] font-semibold flex-wrap animate-pulse'>{translations[language].currency}{totalPayment(orderDetails.totalPrice).toLocaleString()}</span></p>
            <p className='text-center text-lg text-red-500 font-bold'>{translations[language].attentionToDigit}</p>
            <img src={QRIS_HYPERTOPIA} alt='QRIS HyperTopia' className='w-full h-full object-cover rounded-md' />
            <button onClick={() => {
                const link = document.createElement('a');
                link.href = QRIS_HYPERTOPIA;
                link.download = 'QRIS_HyperTopia.jpeg';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }} 
              className='bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-500 hover:to-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full'>
              {translations[language].downloadQRIS}
            </button>
            <button onClick={() => setShowUploadPaymentProof(true)} className='w-full mt-3 flex items-center justify-center'>
              <label htmlFor="uploadProofButton" className='cursor-pointer text-blue-500 font-semibold hover:text-blue-700'>
                {translations[language].uploadPaymentProof}
              </label>
            </button>

          </div>
        </div>
      )}

      {showUploadPaymentProof && (
        <div className="fixed p-5 top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-white p-4 rounded-xl w-[400px]">
            <div className="flex justify-between items-center gap-5 mb-3">
              <p className="text-xl font-bold">{translations[language].uploadPaymentProof}</p>
              <button onClick={() => setShowUploadPaymentProof(false)} className='text-red-500 hover:text-red-700 transition-all'>
                <FaTimes className='text-xl' />
              </button>
            </div>
            <hr className='my-4' />
            <div className={`border-2 border-dashed rounded-lg border-gray-400 ${isDragging ? 'border-blue-500' : ''}`}>
              <label
                htmlFor="fileInput"
                className={`text-center cursor-pointer relative `}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setIsDragging(false);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  setIsDragging(false);
                  handleDrop(e);
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={(e) => uploadPaymentProof(e.target.files[0])}
                  accept="image/*"
                />
                <span className={`flex flex-col gap-2 p-7 items-center justify-center`}>
                  {filePreviewUrl ? (
                    <img src={filePreviewUrl} alt="Uploaded Preview" className="max-h-40 max-w-full mb-2" />
                  ) : (
                    <TbDragDrop className='size-12' />
                  )}
                  {fileName
                    ? <span className='flex flex-col gap-2 items-center'>
                        <p>File: {fileName.split('.')[0]}</p>
                        <span className='uppercase px-2 py-1 rounded-lg bg-gradient-to-br from-blue-300 to-blue-600 text-white w-fit flex flex-row gap-2 items-center'>
                          {fileName.split('.')[1] === 'png' && <BsFiletypePng />}
                          {fileName.split('.')[1] === 'jpg' && <BsFiletypeJpg />}
                          {fileName.split('.')[1] === 'aac' && <BsFiletypeAac />}
                          {fileName.split('.')[1] === 'ai' && <BsFiletypeAi />}
                          {fileName.split('.')[1] === 'bmp' && <BsFiletypeBmp />}
                          {fileName.split('.')[1] === 'cs' && <BsFiletypeCs />}

                          {fileName.split('.')[1] === 'cs' && <BsFiletypeCss />}
                          {fileName.split('.')[1] === 'csv' && <BsFiletypeCsv />}
                          {fileName.split('.')[1] === 'doc' && <BsFiletypeDoc />}
                          {fileName.split('.')[1] === 'docx' && <BsFiletypeDocx />}
                          {fileName.split('.')[1] === 'exe' && <BsFiletypeExe />}
                          {fileName.split('.')[1] === 'gif' && <BsFiletypeGif />}

                          {fileName.split('.')[1] === 'heic' && <BsFiletypeHeic />}
                          {fileName.split('.')[1] === 'html' && <BsFiletypeHtml />}
                          {fileName.split('.')[1] === 'java' && <BsFiletypeJava />}
                          {fileName.split('.')[1] === 'js' && <BsFiletypeJs />}
                          {fileName.split('.')[1] === 'json' && <BsFiletypeJson />}
                          {fileName.split('.')[1] === 'jsx' && <BsFiletypeJsx />}

                          {fileName.split('.')[1] === 'key' && <BsFiletypeKey />}
                          {fileName.split('.')[1] === 'm4p' && <BsFiletypeM4P />}
                          {fileName.split('.')[1] === 'md' && <BsFiletypeMd />}
                          {fileName.split('.')[1] === 'mdx' && <BsFiletypeMdx />}
                          {fileName.split('.')[1] === 'mov' && <BsFiletypeMov />}
                          {fileName.split('.')[1] === 'mp3' && <BsFiletypeMp3 />}

                          {fileName.split('.')[1] === 'mp4' && <BsFiletypeMp4 />}
                          {fileName.split('.')[1] === 'otf' && <BsFiletypeOtf />}
                          {fileName.split('.')[1] === 'pdf' && <BsFiletypePdf />}
                          {fileName.split('.')[1] === 'php' && <BsFiletypePhp />}
                          {fileName.split('.')[1] === 'ppt' && <BsFiletypePpt />}
                          {fileName.split('.')[1] === 'pptx' && <BsFiletypePptx />}

                          {fileName.split('.')[1] === 'psd' && <BsFiletypePsd />}
                          {fileName.split('.')[1] === 'py' && <BsFiletypePy />}
                          {fileName.split('.')[1] === 'raw' && <BsFiletypeRaw />}
                          {fileName.split('.')[1] === 'rb' && <BsFiletypeRb />}
                          {fileName.split('.')[1] === 'sass' && <BsFiletypeSass />}
                          {fileName.split('.')[1] === 'scss' && <BsFiletypeScss />}

                          {fileName.split('.')[1] === 'sh' && <BsFiletypeSh />}
                          {fileName.split('.')[1] === 'sql' && <BsFiletypeSql />}
                          {fileName.split('.')[1] === 'svg' && <BsFiletypeSvg />}
                          {fileName.split('.')[1] === 'tiff' && <BsFiletypeTiff />}
                          {fileName.split('.')[1] === 'tsx' && <BsFiletypeTsx />}
                          {fileName.split('.')[1] === 'ttf' && <BsFiletypeTtf />}

                          {fileName.split('.')[1] === 'txt' && <BsFiletypeTxt />}
                          {fileName.split('.')[1] === 'wav' && <BsFiletypeWav />}
                          {fileName.split('.')[1] === 'woff' && <BsFiletypeWoff />}
                          {fileName.split('.')[1] === 'xls' && <BsFiletypeXls />}
                          {fileName.split('.')[1] === 'xlsx' && <BsFiletypeXlsx />}
                          {fileName.split('.')[1] === 'xml' && <BsFiletypeXml />}
                          {fileName.split('.')[1] === 'yml' && <BsFiletypeYml />}

                          {(fileName.split('.')[1] === 'zip' || fileName.split('.')[1] === 'rar') && <FaFileArchive />}
                          <p>{fileName.split('.')[1]}</p>
                        </span>
                      </span>
                    : ( <p>{isDragging ? translations[language].dropYourFileHere : translations[language].dragYourPaymentProofHere}</p>)
                  }
                </span>
              </label>
            </div>
            {(uploadProgress > 0 && uploadProgress <= 100) && (
              <div className={`${uploadProgress < 100 && 'p-4 border-2 border-dashed border-gray-400'} rounded-lg mt-2`}>
                {uploadProgress === 100 ? (
                  <p className='text-white bg-green-500 font-semibold text-center w-full rounded-lg px-4 py-2'>{translations[language].uploadSuccess}</p>
                ) : (
                  <>
                    <ProgressBar completed={Number(uploadProgress.toFixed(1))} maxCompleted={100} className='w-full' bgColor='#0081FB' />
                    <div className="flex justify-between text-sm text-gray-500 mt-1">
                      <span>{`${uploadProgress.toFixed(1)}% Uploaded`}</span>
                      {/* {uploadProgress > 0 && ( */}
                          <span>{`${currentSpeed.toFixed(2)} ${currentSpeedUnit} - ETA: ${remainingTimeMinutes}m ${remainingTimeSecondsRemainder}s`}</span>
                      {/* )} */}
                    </div>
                  </>
                )}
              </div>
            )}
            {fileName && (
              <span className='flex flex-row gap-2 w-full'>
                <button onClick={handleReplace} className='border-gray-500 border-2 hover:bg-gray-500 transition-all text-gray-500 hover:text-white font-semibold px-4 py-2 rounded-xl mt-4 w-full flex flex-row gap-2 items-center justify-center'>
                  <span>{translations[language].changeFile}</span>
                  <TbReplace />
                </button>
                <button onClick={handleSendPicture} className='bg-[#0081FB] hover:bg-blue-700 transition-all text-white font-semibold px-4 py-2 rounded-xl mt-4 w-full flex flex-row gap-2 items-center justify-center'>
                  <span>{translations[language].sendFile}</span>
                  <BsSendFill />
                </button>
              </span>
            )}
          </div>
        </div>
      )}

      {showVerificationPopup && (
        <div className="fixed p-5 top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-white p-4 rounded-xl w-[400px]">
            <div className="flex justify-between items-center gap-5 mb-3">
              {orderDetails.verifiedPayment ? (<p className="text-xl font-bold">{translations[language].paymentVerified}</p>) : (<p className="text-xl font-bold">{translations[language].verifyingAndValidating}</p>)}
              <button onClick={() => setShowVerificationPopup(false)} className='text-red-500 hover:text-red-700 transition-all'>
                <FaTimes className='text-xl' />
              </button>
            </div>
            <hr className='my-4' />
            {/* Tampilkan gambar bukti bayar yang diunggah */}
            <img src={orderDetails.paymentProof} alt='Payment Proof' className='w-full h-full object-cover rounded-md' />
            {orderDetails.verifiedPayment ? null : <p className='text-center text-lg mt-4'>{translations[language].waitingConfirmationFromSeller}</p>}
          </div>
        </div>
      )}

      {/* {showImagePreviewPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-white p-4 rounded-xl w-[400px] flex flex-col items-center">
            <img src={selectedImageUrl} alt="Selected Image" className='flex items-center' />
            <span className='flex flex-row gap-2 w-full'>
              <button onClick={() => setShowImagePreviewPopup(false)} className='bg-yellow-500 hover:bg-yellow-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full'>Ganti Gambar</button>
              <button onClick={handleSendPicture} className='bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full'>Kirim Gambar</button>
            </span>
          </div>
        </div>
      )} */}

      <span className='flex flex-row gap-2'>
        <h1 className='text-2xl font-semibold'>{translations[language].orderDetails}</h1>
        <p className='text-base py-1 px-2 bg-blue-500 rounded-lg text-white'>{orderId}</p>
      </span>
      <hr className='my-4' />
      <div className='flex flex-row gap-4 w-full'>
        {/* DETAIL PESANAN */}
        <div className='w-full'>
        <>
        {orderDetails && orderDetails.items ? (
            orderDetails.items.map((item, index) => (
                <div key={index} className='flex flex-row gap-4 p-4 border-2 bg-white rounded-xl mb-4'>
                    <div className="flex flex-col">
                        <p className='text-lg font-bold'>{item.productName}</p>
                        <p>{translations[language].currency}{item.productPrice.toLocaleString()} x {item.quantity}</p>
                        {item.productName === 'Games For Oculus Meta Quest 1 2 3 dan Pro' && (
                        <div>
                            {item.emails.map((email, emailIndex) => (
                                <p key={emailIndex} className='flex flex-row items-center text-zinc-400' >
                                    <p>{emailIndex + 1}. {email}</p>
                                    <p className={`text-sm mt-2 rounded-lg px-2 py-1 ml-2 ${emailStatuses && emailStatuses[index * item.emails.length + emailIndex] === 'Terdaftar' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                        {emailStatuses && emailStatuses[index * item.emails.length + emailIndex]}
                                    </p>
                                </p>
                            ))}
                        </div>
                        )}
                    </div>
                </div>
            ))
        ) : (
            <p>Loading...</p>
        )}
            <p className='text-sm mt-4 text-zinc-500'>{translations[language].timeCreated} {formatDate(orderDetails.timeCreated, language)}</p>
          </>
        </div>

        {/* DETAIL PEMBAYARAN */}
        <div className='w-[400px] p-4 rounded-xl border-2 bg-white'>
          <h2 className='text-xl font-semibold'>{translations[language].paymentDetails}</h2>
          <hr className='my-4' />
          <span className='flex justify-between items-start'>
            <span className='flex flex-col justify-start items-start'>
              <span>{translations[language].paymentMethod} <p className=' flex-wrap font-semibold'>{`(${selectedPaymentMethod})`}</p></span>
              {orderDetails.hasPaid === false && (<button onClick={handleChangePaymentMethod} className='font-semibold text-[#0081FB] text-sm hover:text-blue-700 transition-all'>{translations[language].change}</button>)}
            </span>
            {selectedPaymentMethod === 'QRIS' && (
              <img src={QRISLogo} alt='QRIS' className='w-16 pt-2 pr-2 object-cover' />
            )}
            {selectedPaymentMethod === 'ShopeePay' && (
              <img src={ShopeePayLogo} alt='ShopeePay' className='w-16 pt-2 pr-2 object-cover' />
            )}
            {selectedPaymentMethod === 'GoPay' && (
              <img src={GoPayLogo} alt='GoPay' className='w-16 pt-2 pr-2 object-cover' />
            )}
            {selectedPaymentMethod === 'DANA' && (
              <img src={DANALogo} alt='DANA' className='w-16 pt-2 pr-2 object-cover' />
            )}
            {selectedPaymentMethod === 'Bank Mandiri' && (
              <img src={BankMandiriLogo} alt='Bank Mandiri' className='w-16 pt-2 pr-2 object-cover' />
            )}
          </span>
          <hr className='my-4' />
          <span className='flex justify-between mb-1'>
            <p>{translations[language].orderTotal}</p>
            <p>{translations[language].currency}{orderDetails.totalPrice}</p>
          </span>
          <span className='flex justify-between'>
            <span className='flex flex-row gap-1 items-center'>
              <p>{translations[language].serviceFee}</p>
              <ToolTip text={translations[language].serviceFeeInfo}>
                <FaCircleInfo className='text-[#0081FB] inline-block' />
              </ToolTip>
            </span>
            <p>{translations[language].currency}{adminPayment(orderDetails.totalPrice).toLocaleString()}</p>
          </span>
          <hr className='my-4' />
          <span className='flex justify-between font-semibold'>
            <p>{translations[language].paymentTotal}</p>
            <p>{translations[language].currency}{totalPayment(orderDetails.totalPrice).toLocaleString()}</p>
          </span>
          {orderDetails.hasPaid && (
          <button onClick={() => setShowVerificationPopup(true)} className='border-blue-500 border hover:bg-blue-500 hover:text-white transition-all text-blue-500 px-4 py-2 rounded-xl mt-4 w-full'>
            {translations[language].seePaymentProof}
          </button>
          )}
          {orderDetails.hasPaid === false && orderDetails.status === "Menunggu Pembayaran" && (
            <button onClick={handlePayOrder} className='bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full'>
            {translations[language].payOrder}
          </button>
          )}
          {orderDetails.status !== "Pesanan Selesai" && (
            <button onClick={handleOrderCompleted} disabled={orderDetails.status !== "Pesanan Selesai"} className='bg-green-500 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed hover:bg-green-700 transition-all text-white px-4 py-2 rounded-xl mt-2 w-full'>
              {translations[language].orderCompleted}
            </button>
          )}
          <span className='flex flex-col mt-2'>
            <p className='flex justify-center text-center text-sm text-zinc-500'>{translations[language].securePayment}</p>
            <span className='text-center text-sm flex flex-row gap-1 justify-center items-center'>
              <p className='text-zinc-500'>{translations[language].poweredBy}</p>
              <span className='font-semibold text-[#0081FB] flex flex-row gap-1 items-center'>
                <p>HyperTopia Guard</p>
                <BsShieldFillCheck className='' />
              </span>
            </span>
          </span>

          <hr className='my-4' />

          <h2 className='text-xl font-semibold'>{translations[language].status}</h2>
          <hr className='my-4' />

          <span className='flex flex-col gap-2 items-start justify-start'>
            <span className='flex flex-row gap-2'>
              {orderDetails.hasPaid ? <FaCheckCircle className='text-2xl text-green-500' /> : <FaTimesCircle className='text-2xl text-red-500' />}
              <p className='text-zinc-500'>{translations[language].orderPaid}</p>
            </span>
            <span className='flex flex-row gap-2'>
              {orderDetails.verifiedPayment ? <FaCheckCircle className='text-2xl text-green-500' /> : <FaTimesCircle className='text-2xl text-red-500' />}
              <p className='text-zinc-500'>{translations[language].paymentVerified}</p>
            </span>
            <span className='flex flex-row gap-2'>
              {orderDetails.verifiedPayment ? <FaCheckCircle className='text-2xl text-green-500' /> : <FaTimesCircle className='text-2xl text-red-500' />}
              {orderDetails.verifiedPayment ? <p className='text-zinc-500'>{translations[language].orderConfirmed}</p> : <p className='text-zinc-500'>{translations[language].waitingConfirmation}</p>}
            </span>
            <span className='flex flex-row gap-2'>
              {orderDetails.verifiedPayment ? <FaCheckCircle className='text-2xl text-green-500' /> : <FaTimesCircle className='text-2xl text-red-500' />}
              <p className='text-zinc-500'>{translations[language].orderSent}</p>
            </span>
            <span className='flex flex-row gap-2'>
              {orderDetails.status === "Pesanan Selesai" ? <FaCheckCircle className='text-2xl text-green-500' /> : <FaTimesCircle className='text-2xl text-red-500' />}
              <p className='text-zinc-500'>{translations[language].orderCompleted}</p>
            </span>
          </span>
        </div>
      </div>

      <Helmet>
        <title>{translations[language].orderPayment} {orderId} - HyperTopia</title>
      </Helmet>
    </div>
  );
}

export default PayOrder;