import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fireDb from '../../firebase';
import { Helmet } from 'react-helmet';
import { useDarkModeContext } from '../../DarkModeContext';
import { storage } from '../../firebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import JSZip from 'jszip';

const DownloadProductsCover_Admin = () => {
  const [productTitles, setProductTitles] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const { isDarkMode } = useDarkModeContext();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await fireDb.ref(`products/`).once('value');
        const data = snapshot.val();
        if (data) {
          const titles = Object.keys(data).map((key) => ({
            id: key,
            title: data[key].productTitle,
          }));
          setProductTitles(titles);
        }
      } catch (error) {
        console.error('Error fetching product titles:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchProductImages = async () => {
      try {
        const productImageRef = ref(storage, 'productPhotos');
        const productImageList = await listAll(productImageRef);
        const images = await Promise.all(
          productImageList.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return { name: item.name, url };
          })
        );
        setProductImages(images);
      } catch (error) {
        console.error('Error fetching product images:', error);
      }
    };
    fetchProductImages();
  }, []);

  const extractProductName = (fileName) => {
    const parts = fileName.split('_');
    return parts[0];
  };

  const handleDownloadAll = () => {
    const zip = new JSZip();
    const promises = [];
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getDate()
      .toString()
      .padStart(2, '0')}_${currentDate.getHours()
      .toString()
      .padStart(2, '0')}-${currentDate.getMinutes()
      .toString()
      .padStart(2, '0')}-${currentDate.getSeconds().toString().padStart(2, '0')}`;
    
    productImages.forEach((image) => {
      const promise = fetch(image.url)
        .then((response) => response.blob())
        .then((blob) => {
          const productName = extractProductName(image.name);
          zip.file(`${productName}.png`, blob);
        });
      promises.push(promise);
    });
    
    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `All_Products_Cover_${formattedDate}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
  };

  return (
    <div className={`pt-6 mb-10 mx-5 w-full h-full ${!window.innerWidth < 1200 && 'pt-[100px]'} min-h-screen`}>
      <div className="flex flex-col items-center justify-center">
        <div className={`py-6 px-6 m-auto w-full flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} shadow-lg`}>
          <div className="flex items-center justify-between border-b pb-4">
            <h2 className="text-2xl font-bold">Download All Covers</h2>
            <button
              onClick={handleDownloadAll}
              className="bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
            >
              Download All
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {Array.isArray(productImages) &&
              productImages.map((image, index) => (
                <div key={index} className="border rounded-lg bg-gray-100 transition-transform transform hover:scale-105">
                  <div className="relative">
                    <img
                      src={image.url}
                      alt={`Cover ${image.name}`}
                      className="w-full h-full rounded-t-lg object-cover"
                      style={{ aspectRatio: '1 / 1' }} // Ensure the image is square
                    />
                    <div className="absolute top-4 right-4">
                      <a
                        href={image.url}
                        download={`cover_${extractProductName(image.name)}`}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded shadow"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                  <div className="p-4 text-center">
                    <span className="text-sm font-semibold">{extractProductName(image.name)}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <Helmet>
          <title>Download All Covers | Admin</title>
        </Helmet>
      </div>
    </div>
  );
};

export default DownloadProductsCover_Admin;
