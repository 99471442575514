import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import fireDb from '../../firebase'
import { auth } from '../../firebase'
import { FaBook, FaMessage, FaRankingStar } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { IoGameController } from 'react-icons/io5'
const NintendoSwitch = () => {
	const language = localStorage.getItem('language') || 'id'
	const eligibleUsersRef = fireDb.ref('eligibleUsers/standalone')
	const [user, setUser] = useState(null)
	const [showAnnouncement, setShowAnnouncement] = useState(false)
	const [showWhatsNew, setShowWhatsNew] = useState(false)
	const whatsNewRef = useRef(null)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setShowAnnouncement(false)
					} else {
						setShowAnnouncement(true)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [showAnnouncement, eligibleUsersRef, user])

	useEffect(() => {
		setShowWhatsNew(true)
	}, [])

	const translations = {
		en: {
			info: 'Info',
			hintInfo: 'Click one of the menus below to access the desired page!',
			gameStandaloneAndPCVR: 'Standalone & PCVR Games',
			openingOfStandaloneGamesAndPCVR:
				'We are happy to announce that you can now access Standalone games (can be played directly on Quest) and PCVR games (requires wired/wireless connection to PC to play) on this website.',
			newAdded: 'NEW',
			comingSoonGames: 'Coming Soon Games',
			updatedGames: 'Newly Updated Games',
			newGame: 'New Game Added',
			reopeningGameRequest:
				'We are happy to announce that the game request feature has been reopened. You can now request the game you want to be uploaded to this website.',
			openedAgain: 'Yeay, this feature has opened again!',
			lastUpdated: 'Last Updated:',
			hideEmptyDownload: 'Hide Unavailable Download Link',
			unknownTime: 'Unknown Time',
			sortBy: 'Sort By:',
			name: 'Name',
			size: 'Size',
			added: 'Newest',
			downloadCount: 'Download',
			filter: 'Filter',
			supportedQuests: 'Supported Quests:',
			vrGames: 'VR Games',
			whatsNewFromSevenDays: "What 's new over the past 7 days?",
			checkGameRequest: 'Check Game Request',
			gameRequestFeature: 'Game Request Feature is Back',

			accessDownloadAnnouncement:
				'Please login and purchase the related product first to access download link!',
			notEligibleUsers:
				'Sorry, your account is not registered as an eligible user. Please purchase the related product to access download!',
			eligibleUsers:
				'Congratulations, your account is registered as an eligible user to access download!',

			searchGames: 'Search Games...',

			numberOfGames: 'Number of Games: ',

			games: ' Games',

			saveData: 'Data Saver',

			uploadPendingGame:
				'THERE ARE STILL 200++ QUEST GAMES THAT ARE STILL IN THE PROCESS OF UPLOADING TO THE WEBSITE. SO PLEASE BE PATIENT 😄',

			top7MostDownloadedGames: 'Top 10 Most Downloaded Games:',

			saveDataMode: 'Data Saver Mode On',

			tooltipDataSaver:
				'The cover image of each game will not be displayed to save data. This feature can be turned off in the profile picture.',

			officialFromSideQuestWebsite: 'Official from SideQuest Website',
			officialFromWinRARWebsite: 'Official from WinRAR Website',

			forInstallerHelper: 'FOR INSTALLING APPS/GAMES TO QUEST',
			forExtracterHelper: 'FOR EXTRACTING .RAR/.ZIP FILE',

			recommended: 'Recommended',

			tutorialVideo: 'Tutorial Video',

			softwareHelper: 'Software Helper',
			descSoftwareHelper:
				'Useful tools for extracting RAR/ZIP and installing games or apps to Quest headset.',
			listGames: 'List Games',
			descListGames:
				'List of games that you can download and play on Quest headset or PCVR.',
			tutorial: 'Tutorials',
			descTutorial:
				'Tutorials that can help you in installing games or apps to Quest headset.',
			requestGame: 'Request Game',
			descRequestGame:
				'You can request the game you want to be uploaded to this website.',
			leaderboard: 'Leaderboard',
			descLeaderboard:
				'Check the leaderboard of the most downloaded games on this website.',

			temporaryDisabled:
				'This feature is temporarily disabled until an unspecified time!',

			multiplayerStatus: 'Multiplayer Status',
			descMultiplayerStatus:
				'Check the status of multiplayer games on this website.',
		},
		id: {
			info: 'Info',
			hintInfo:
				'Klik salah satu menu di bawah untuk mengakses halaman yang diinginkan!',
			gameStandaloneAndPCVR: 'Game Standalone & PCVR',
			openingOfStandaloneGamesAndPCVR:
				'Kami senang mengumumkan bahwa sekarang kamu bisa mengakses game yang Standalone (bisa dimainkan langsung di Quest) dan PCVR (memerlukan koneksi kabel/tanpa kabel ke PC untuk memainkannya) di website ini.',
			newAdded: 'BARU',
			comingSoonGames: 'Game yang Segera Hadir',
			updatedGames: 'Game Baru Diupdate',
			newGame: 'Game Baru Ditambahkan',
			gameRequestFeature: 'Fitur Request Game Kembali Dibuka',
			reopeningGameRequest:
				'Kami senang mengumumkan bahwa fitur request game telah dibuka kembali. Kamu sekarang bisa request game yang kamu inginkan untuk diupload ke website ini.',
			whatsNewFromSevenDays: 'Apa yang baru selama 7 hari terakhir?',
			openedAgain: 'Yeay, fitur ini telah dibuka kembali!',
			lastUpdated: 'Terakhir Diperbarui:',
			hideEmptyDownload: 'Sembunyikan Link Download Tidak Tersedia',
			unknownTime: 'Waktu Tidak Diketahui',
			sortBy: 'Urutkan:',
			name: 'Nama',
			size: 'Ukuran',
			added: 'Terbaru',
			downloadCount: 'Unduhan',
			filter: 'Filter',
			supportedQuests: 'Quest yang Didukung:',
			vrGames: 'Game VR',
			accessDownloadAnnouncement:
				'Silahkan login dan membeli produk terkait terlebih dahulu untuk mengakses link download!',
			notEligibleUsers:
				'Maaf, akun Anda tidak terdaftar sebagai pengguna berhak. Silahkan beli produk terkait untuk akses download!',
			eligibleUsers:
				'Selamat, akun Anda terdaftar sebagai pengguna berhak untuk mengakses link download!',
			searchGames: 'Cari Game...',
			numberOfGames: 'Total Game: ',
			games: ' Game',
			saveData: 'Hemat Data',
			uploadPendingGame:
				'MASIH TERDAPAT 200++ GAME QUEST YANG MASIH PROSES UPLOAD KE WEBSITE. JADI MOHON BERSABAR YA 😄',
			top7MostDownloadedGames: 'Top 10 Game Terbanyak Diunduh:',
			saveDataMode: 'Mode Hemat Data Aktif',
			tooltipDataSaver:
				'Cover gambar setiap game tidak akan ditampilkan untuk menghemat data. Fitur ini bisa kamu matikan di gambar profil.',
			officialFromSideQuestWebsite: 'Resmi dari Website SideQuest',
			officialFromWinRARWebsite: 'Resmi dari Website WinRAR',

			forInstallerHelper: 'UNTUK KEPERLUAN INSTALL APPS/GAMES KE QUEST',
			forExtracterHelper: 'UNTUK KEPERLUAN MENG-EXTRACT FILE .RAR/.ZIP',

			recommended: 'Direkomendasikan',

			tutorialVideo: 'Video Tutorial',

			softwareHelper: 'Software Pembantu',
			descSoftwareHelper:
				'Alat-alat yang berguna untuk mengextract RAR/ZIP dan menginstal game atau aplikasi ke headset Quest.',
			listGames: 'Daftar Game',
			descListGames:
				'Daftar game yang bisa kamu download dan mainkan di Headset Quest atau PCVR.',
			tutorial: 'Tutorial',
			descTutorial:
				'Tutorial-tutorial yang bisa membantu kamu dalam menginstal game atau aplikasi ke headset Quest.',
			requestGame: 'Request Game',
			descRequestGame:
				'Kamu bisa request game yang kamu inginkan untuk diupload ke website ini.',
			leaderboard: 'Papan Peringkat',
			descLeaderboard:
				'Cek papan peringkat game yang paling banyak diunduh di website ini.',

			temporaryDisabled:
				'Fitur ini dinonaktifkan untuk sementara sampai waktu yang tidak ditentukan!',

			checkGameRequest: 'Cek Request Game',

			multiplayerStatus: 'Status Multiplayer',
			descMultiplayerStatus: 'Cek status game multiplayer di website ini.',
		},
	}

	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 800) {
				setGridColsVRGamesMenu('grid-cols-2')
			} else {
				setGridColsVRGamesMenu('grid-cols-1')
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const monthNames = {
		en: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sept',
			'Oct',
			'Nov',
			'Dec',
		],
		id: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'Mei',
			'Jun',
			'Jul',
			'Ags',
			'Sept',
			'Okt',
			'Nov',
			'Des',
		],
	}

	const today = new Date()
	today.setHours(23, 59, 59, 999)

	const startOfWeek = new Date(today)
	startOfWeek.setDate(today.getDate() - 6) // Start of the week (Sunday) 7 days ago

	const endOfWeek = new Date(today)
	endOfWeek.setDate(today.getDate()) // End of the week (Saturday) today

	useEffect(() => {
		const today = new Date()
		today.setHours(23, 59, 59, 999)

		const startOfWeek = new Date(today)
		startOfWeek.setDate(today.getDate() - 6) // Start of the week (Sunday) 7 days ago

		const endOfWeek = new Date(today)
		endOfWeek.setDate(today.getDate()) // End of the week (Saturday) today
	}, [])

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				showWhatsNew &&
				whatsNewRef.current &&
				!whatsNewRef.current.contains(event.target)
			) {
				setShowWhatsNew(false)
			}
		}

		document.addEventListener('mousedown', handleOutsideClick)

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showWhatsNew])

	return (
		<div className={`pt-7 mx-7 pb-7 w-full`}>
			<div>
				<div className={`grid grid-cols-1 gap-7 w-full`}>
					<div className="p-4 bg-gradient-to-r from-red-50 to-red-100 border-2 border-red-300 rounded-xl flex items-center justify-center text-center">
						<p>
							<span className="px-3 py-1 rounded-full bg-gradient-to-r from-red-300 to-red-500 text-white font-semibold text-center">
								{translations[language].info}
							</span>
							<span className="text-red-500 font-semibold ml-2">
								{translations[language].hintInfo}
							</span>
						</p>
					</div>
					{/* <Link to="./software-helper">
            <button className={`hover:bg-gradient-to-r hover:from-red-500 hover:to-transparent bg-white hover:text-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-start items-start ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
              // style={{
              //   background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`
              // }}
            >
              <div className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-start justify-center`}>
                <FaTools size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60} />
                <span className="flex flex-col gap-1 items-start justify-start">
                  <span className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}>{translations[language].softwareHelper}</span>
                  <span className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}>{translations[language].descSoftwareHelper}</span>
                </span>
              </div>
            </button>
          </Link> */}

					<Link to="./list-games">
						<button
							className={`hover:bg-gradient-to-r hover:from-red-500 hover:to-transparent bg-white hover:text-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-start items-start ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							// style={{
							//   background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`
							// }}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-start justify-center`}
							>
								<IoGameController
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
								/>
								<span className="flex flex-col gap-1 items-start justify-start">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold flex justify-center gap-2`}
									>
										{translations[language].listGames}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descListGames}
									</span>
								</span>
							</div>
						</button>
					</Link>

					<Link to="./tutorials">
						<button
							className={`hover:bg-gradient-to-r hover:from-red-500 hover:to-transparent bg-white hover:text-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-start items-start ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							// style={{
							//   background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`
							// }}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-start justify-center`}
							>
								<FaBook
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
								/>
								<span className="flex flex-col gap-1 items-start justify-start">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].tutorial}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descTutorial}
									</span>
								</span>
							</div>
						</button>
					</Link>

					<Link to="./request-games">
						<button
							className={`hover:bg-gradient-to-r hover:from-red-500 hover:to-transparent bg-white hover:text-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-start items-start ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							// style={{
							//   background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`
							// }}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-start justify-center`}
							>
								<FaMessage
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
								/>
								<span className="flex flex-col gap-1 items-start justify-start">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].requestGame}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descRequestGame}
									</span>
								</span>
							</div>
						</button>
					</Link>

					<Link to="./leaderboard">
						<button
							className={`hover:bg-gradient-to-r hover:from-red-500 hover:to-transparent bg-white hover:text-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-start items-start ${gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'} gap-4 w-full`}
							// style={{
							//   background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`
							// }}
						>
							<div
								className={`flex flex-col ${gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'} items-start justify-center`}
							>
								<FaRankingStar
									size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
								/>
								<span className="flex flex-col gap-1 items-start justify-start">
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'} font-semibold`}
									>
										{translations[language].leaderboard}
									</span>
									<span
										className={`${gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'} `}
									>
										{translations[language].descLeaderboard}
									</span>
								</span>
							</div>
						</button>
					</Link>
				</div>
			</div>
			<Helmet>
				<title>{translations[language].vrGames} | HyperTopia</title>
			</Helmet>
			<style>
				{`
          @keyframes scroll {
            0% {
              transform: translateX(250%);
            }
            100% {
              transform: translateX(-250%);
            }
          }

          .animate-scroll-wrapper {
            overflow: hidden;
            display: inline-block;
          }

          .animate-scroll {
            display: inline-block;
            white-space: nowrap;
            animation: scroll 15s linear infinite;
          }

          .search-box {
            width: 100px;
          }
        `}
			</style>
		</div>
	)
}

export default NintendoSwitch
