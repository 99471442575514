import React, { useState, useEffect, useRef } from 'react'
import { useDarkModeContext } from '../../../../DarkModeContext'
import ListGamesCard_PCVR from './ListGamesCard_PCVR'
import { FiSearch, FiX } from 'react-icons/fi'
import { FaAngleRight } from 'react-icons/fa'
import { VscSettings } from 'react-icons/vsc'
import { GoTriangleDown } from 'react-icons/go'
import { MdFileDownloadOff } from 'react-icons/md'
import Switch from 'react-switch'
import { BsHeadsetVr } from 'react-icons/bs'
import { FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa'
import fireDb from '../../../../firebase'
import { auth } from '../../../../firebase'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'

const translations = {
	en: {
		filterGameStatusInfo: `This filter is based on the last 7 days with the selected status!`,
		stopAll:
			'The PCVR Game list has been completely stopped from our data center. We apologize for the inconvenience.',

		new: 'New',
		update: 'Update',
		soon: 'Coming Soon',

		pcvrGames: 'PCVR Games',
		loadMore: 'Load More',
		requestGameDesc:
			'Users who have purchased or have not purchased the related product can still request the game they want to be uploaded to this website.',
		gamePerPage: 'Games Per Page:',
		noGameFound: 'No VR game found. You can request a VR game here!',
		bugPhotoCover:
			'There is a bug in the game cover photo, sometimes the cover photo does not match the title. We are working to fix it. Thank you for your understanding.',
		lastUpdated: 'Last Updated:',
		hideEmptyDownload: 'Hide Unavailable Download Link',
		unknownTime: 'Unknown Time',
		sortBy: 'Sort By:',
		name: 'Name',
		size: 'Size',
		added: 'Newest',
		downloadCount: 'Download',
		filter: 'Filter',
		supportedQuests: 'Supported Quests:',
		vrGames: 'VR Games',
		all: 'All',

		accessDownloadAnnouncement:
			'Please login and purchase the related product first to access download link!',
		notEligibleUsers:
			'Sorry, your account is not registered as an eligible user. Please purchase the related product to access download!',
		eligibleUsers:
			'Congratulations, your account is registered as an eligible user to access the download link of VR games below! Please use the search box to find the game you are looking for!',

		searchGames: 'Search Games Here...',

		totalGames: 'Total Games: ',
		numberOfGames: 'Number of Games: ',
		isComing: 'is Coming Soon',

		games: ' Games',

		saveData: 'Data Saver',

		uploadPendingGame:
			'THERE ARE STILL 200++ QUEST GAMES THAT ARE STILL IN THE PROCESS OF UPLOADING TO THE WEBSITE. SO PLEASE BE PATIENT 😄',

		top7MostDownloadedGames: 'Top 10 Most Downloaded Games:',

		saveDataMode: 'Data Saver Mode On',

		tooltipDataSaver:
			'The cover image of each game will not be displayed to save data. This feature can be turned off in the profile picture.',

		recommended: 'Recommended',

		page: 'Page',
	},
	id: {
		stopAll:
			'Daftar Game PCVR telah dihentikan total dari pusat data kami. Mohon maaf atas ketidaknyamanan ini.',

		filterGameStatusInfo: `Filter ini berdasarkan 7 hari terakhir dengan status yang dipilih!`,
		new: 'Baru',
		update: 'Update',
		soon: 'Segera Hadir',

		pcvrGames: 'Game PCVR',
		loadMore: 'Muat Lebih Banyak',

		requestGameDesc:
			'Pengguna yang sudah membeli maupun yang belum membeli produk terkait, tetap bisa request game yang diinginkan untuk diupload ke website ini.',
		gamePerPage: 'Game Per Halaman:',
		noGameFound: 'Tidak ada game yang ditemukan. Silahkan bisa request game!',
		bugPhotoCover:
			'Ada bug di cover foto game, terkadang cover foto tidak sesuai dengan judul. Kami sedang bekerja untuk memperbaikinya. Terima kasih atas pengertiannya.',
		lastUpdated: 'Terakhir Diperbarui:',
		hideEmptyDownload: 'Sembunyikan Link Download Tidak Tersedia',
		unknownTime: 'Waktu Tidak Diketahui',
		sortBy: 'Urutkan:',
		name: 'Nama',
		size: 'Ukuran',
		added: 'Terbaru',
		downloadCount: 'Unduhan',
		filter: 'Filter',
		supportedQuests: 'Quest yang Didukung:',
		vrGames: 'Game VR',
		accessDownloadAnnouncement:
			'Silahkan login dan membeli produk terkait terlebih dahulu untuk mengakses link download!',
		notEligibleUsers:
			'Maaf, akun Anda tidak terdaftar sebagai pengguna berhak. Silahkan beli produk terkait untuk akses download!',
		eligibleUsers:
			'Selamat, akun Anda terdaftar sebagai pengguna berhak untuk mengakses link download game VR di bawah! Silakan gunakan kotak pencarian untuk menemukan game yang Anda cari!',
		searchGames: 'Cari Game Disini...',
		totalGames: 'Total Game: ',
		numberOfGames: 'Total Game: ',
		isComing: 'akan segera dimasukkan',
		games: ' Game',
		saveData: 'Hemat Data',
		all: 'Semua',
		uploadPendingGame:
			'MASIH TERDAPAT 200++ GAME QUEST YANG MASIH PROSES UPLOAD KE WEBSITE. JADI MOHON BERSABAR YA 😄',
		top7MostDownloadedGames: 'Top 10 Game Terbanyak Diunduh:',
		saveDataMode: 'Mode Hemat Data Aktif',
		tooltipDataSaver:
			'Cover gambar setiap game tidak akan ditampilkan untuk menghemat data. Fitur ini bisa kamu matikan di gambar profil.',

		userNotEligible: 'Anda tidak memiliki akses untuk download Game Quest!',
		buyNow: 'Beli Sekarang!',
		seeGamesList: 'Lihat Daftar Game Saja',
		doneBuy: 'Sudah beli produknya?',
		addToCart: 'Tambah ke Keranjang',

		page: 'Halaman',
	},
}

const Standalone_ListGames = () => {
	const { isDarkMode } = useDarkModeContext()
	const [games, setGames] = useState([])
	const [lengthGames, setLengthGames] = useState(0)
	const [perPage, setPerPage] = useState(games.length)
	const [noSearchResults, setNoSearchResults] = useState(false)
	const location = useLocation()
	const [filterGameStatus, setFilterGameStatus] = useState(null)
	const searchParams = new URLSearchParams(location.search || '')
	let perPageFromUrl = parseInt(searchParams.get('perPage')) || games.length

	useEffect(() => {
		if (perPageFromUrl) {
			setPerPage(perPageFromUrl)
		}
	}, [perPageFromUrl])

	useEffect(() => {
		const gamesRef = fireDb.ref(`vrGames/pcvr`)

		gamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())
				setGames(gamesData)
			} else {
				setGames([])
			}
		})

		return () => {
			gamesRef.off('value')
		}
	}, [])

	useEffect(() => {
		if (perPageFromUrl) {
			setPerPage(perPageFromUrl)
		}
	}, [perPageFromUrl])

	const handlePerPageChange = (event) => {
		const value = parseInt(event.target.value)
		setPerPage(value)

		const searchParams = new URLSearchParams(location.search)
		searchParams.set('perPage', value)
		window.history.replaceState({}, '', `${location.pathname}?${searchParams}`)
	}

	const [displayedGames, setDisplayedGames] = useState(50)
	const gamesPerPage = 50

	const handleLoadMore = () => {
		setDisplayedGames((prevDisplayedGames) => prevDisplayedGames + gamesPerPage)
	}

	const filteredGameStatusGame = games.filter((game) => {
		if (filterGameStatus === null) return true

		if (filterGameStatus === 'soon') {
			return game.gameStatus === filterGameStatus
		}

		if (['new', 'update'].includes(filterGameStatus)) {
			const today = new Date()
			today.setHours(23, 59, 59, 999)
			const startOfWeek = new Date(today)
			startOfWeek.setDate(today.getDate() - 6)

			const endOfWeek = new Date(today)

			const addedDate = new Date(game.timeAdded)

			return (
				addedDate >= startOfWeek &&
				addedDate <= endOfWeek &&
				game.gameStatus === filterGameStatus
			)
		}

		return false
	})

	const currentFilteredGames = filteredGameStatusGame.slice(0, displayedGames)

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const gameStatus = params.get('status')
		setFilterGameStatus(gameStatus)
	}, [location.search])

	const [lastUpdated, setLastUpdated] = useState('')
	const language = localStorage.getItem('language') || 'id'
	const [hideEmptyDownload, setHideEmptyDownload] = useState(false)
	const layout = useState(true)
	const [sortOrder, setSortOrder] = useState('asc')
	const sortBy = 'added'
	const [showFilters, setShowFilters] = useState(false)
	const showFiltersRef = useRef(null)
	const [quest1Support, setQuest1Support] = useState(true)
	const [quest2Support, setQuest2Support] = useState(true)
	const [quest3Support, setQuest3Support] = useState(true)
	const [questProSupport, setQuestProSupport] = useState(true)

	const [popularGames, setPopularGames] = useState([])

	const [gridCols, setGridCols] = useState('grid-cols-1')

	const [searchTerm, setSearchTerm] = useState('')

	const eligibleUsersRef = fireDb.ref('eligibleUsers/pcvr')
	const [user, setUser] = useState(null)

	const [isSearchBarActive, setIsSearchBarActive] = useState(false)

	const [showAnnouncement, setShowAnnouncement] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setShowAnnouncement(false)
					} else {
						setShowAnnouncement(true)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [showAnnouncement, eligibleUsersRef, user])

	const handleQuest1Change = () => {
		setQuest1Support(!quest1Support)
	}

	const handleQuest2Change = () => {
		setQuest2Support(!quest2Support)
	}

	const handleQuest3Change = () => {
		setQuest3Support(!quest3Support)
	}

	const handleQuestProChange = () => {
		setQuestProSupport(!questProSupport)
	}

	const handleFiltersClick = () => {
		setShowFilters(!showFilters)
	}

	useEffect(() => {
		const gamesRef = fireDb.ref(`vrGames/pcvr`)
		gamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())
				setGames(gamesData)
				setLengthGames(gamesData.length)
			} else {
				setGames([])
				setLengthGames(0)
			}
		})

		return () => {
			gamesRef.off('value')
		}
	}, [])

	useEffect(() => {
		const gamesRef = fireDb.ref(`vrGames/pcvr`)
		const popularGamesRef = fireDb.ref(`vrGames/pcvr`)

		popularGamesRef
			.orderByChild('downloadCount')
			.limitToLast(10)
			.on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					const popularGamesData = Object.values(snapshot.val())
					const sortedPopularGames = popularGamesData.sort(
						(a, b) => b.downloadCount - a.downloadCount
					)
					setPopularGames(sortedPopularGames)
				}
			})

		gamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())

				const sortedGamesData = gamesData.reduce((acc, gameData) => {
					const time = new Date(gameData.timeAdded).getTime()
					let inserted = false
					for (let i = 0; i < acc.length; i++) {
						if (time > new Date(acc[i].timeAdded).getTime()) {
							acc.splice(i, 0, gameData)
							inserted = true
							break
						}
					}
					if (!inserted) {
						acc.push(gameData)
					}
					return acc
				}, [])

				const filteredGames = sortedGamesData.filter((game) => {
					const isSupported =
						(quest1Support && game.supportMetaQuest1) ||
						(quest2Support && game.supportMetaQuest2) ||
						(quest3Support && game.supportMetaQuest3) ||
						(questProSupport && game.supportMetaQuestPro)

					const matchesSearchTerm = (game.gameTitle || '')
						.toLowerCase()
						.includes(searchTerm.toLowerCase())

					const hasDownloadLink = game.linkDownload !== ''

					return (
						isSupported &&
						(!hideEmptyDownload || (hideEmptyDownload && hasDownloadLink)) &&
						matchesSearchTerm
					)
				})

				setGames(sortOrder === 'asc' ? filteredGames : filteredGames.reverse())

				const latestTimeAddedDate = new Date(filteredGames[0]?.timeAdded)

				if (!isNaN(latestTimeAddedDate.getTime())) {
					const formattedDate = latestTimeAddedDate.toLocaleString(
						language === 'en' ? 'en-US' : 'id-ID',
						{
							year: 'numeric',
							month: 'long',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
							hour23: true,
							timeZoneName: 'short',
						}
					)
					setLastUpdated(`${formattedDate}`)
				}
				setNoSearchResults(filteredGames.length === 0)
			} else {
				setGames([])
			}
		})

		return () => {
			gamesRef.off('value')
			popularGamesRef.off('value')
		}
	}, [
		language,
		sortOrder,
		quest1Support,
		quest2Support,
		quest3Support,
		questProSupport,
		searchTerm,
		hideEmptyDownload,
	])

	const parseGameSize = (sizeString) => {
		if (!sizeString || sizeString === 'N/A') {
			return 0
		}

		const [size, unit] = sizeString.split(' ')

		if (unit === 'MB') {
			return parseFloat(size)
		} else if (unit === 'GB') {
			return parseFloat(size) * 1000
		}

		return 0
	}

	const toggleSortOrder = () => {
		setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
	}

	const handleSortByChange = (event) => {
		const selectedSortBy = event.target.value

		if (
			selectedSortBy === 'added' ||
			selectedSortBy === 'downloadCount' ||
			selectedSortBy === 'rating'
		) {
			const sortedGames = games.reduce((acc, game) => {
				const time = new Date(game.timeAdded).getTime()
				let inserted = false
				for (let i = 0; i < acc.length; i++) {
					if (time > new Date(acc[i].timeAdded).getTime()) {
						acc.splice(i, 0, game)
						inserted = true
						break
					}
				}
				if (!inserted) {
					acc.push(game)
				}
				return acc
			}, [])
		} else {
			const sortedGamesData = games.slice().sort((a, b) => {
				const titleA = (a && a.gameTitle) || ''
				const titleB = (b && b.gameTitle) || ''
				const aSize = parseGameSize(a.gameSize)
				const bSize = parseGameSize(b.gameSize)

				return sortOrder === 'asc'
					? titleA.localeCompare(titleB, language === 'en' ? 'en-US' : 'id-ID')
					: aSize - bSize
			})

			setGames(sortedGamesData)
		}
	}

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				showFilters &&
				showFiltersRef.current &&
				!showFiltersRef.current.contains(event.target)
			) {
				setShowFilters(false)
			}
		}

		document.addEventListener('mousedown', handleOutsideClick)

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showFilters])

	const [flexPopUpProduct, setFlexPopUpProduct] = useState('flex')

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 4000) {
				setGridCols('grid-cols-10')
			} else if (window.innerWidth >= 3675) {
				setGridCols('grid-cols-9')
			} else if (window.innerWidth >= 3275) {
				setGridCols('grid-cols-8')
			} else if (window.innerWidth >= 2875) {
				setGridCols('grid-cols-7')
			} else if (window.innerWidth >= 2475) {
				setGridCols('grid-cols-6')
			} else if (window.innerWidth >= 2075) {
				setGridCols('grid-cols-5')
			} else if (window.innerWidth >= 1675) {
				setGridCols('grid-cols-4')
			} else if (window.innerWidth >= 1275) {
				setGridCols('grid-cols-3')
			} else if (window.innerWidth >= 875) {
				setGridCols('grid-cols-2')
			} else {
				setGridCols('grid-cols-1')
			}
		}

		const handleResizePopUpProduct = () => {
			if (window.innerWidth < 1000) {
				setFlexPopUpProduct('flex-col')
			} else {
				setFlexPopUpProduct('flex-row')
			}
		}

		handleResize()
		handleResizePopUpProduct()

		window.addEventListener('resize', handleResize)
		window.addEventListener('resize', handleResizePopUpProduct)

		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('resize', handleResizePopUpProduct)
		}
	}, [])

	const handleSearchBarActiveClick = (e) => {
		setSearchTerm(e.target.value)
		setIsSearchBarActive(true)
	}

	const handleSearchBarNonActiveClick = () => {
		setSearchTerm('')
		setIsSearchBarActive(false)
		window.history.replaceState({}, '', `${location.pathname}`)
	}

	const handleSearchBarBlur = () => {
		if (searchTerm) {
			setIsSearchBarActive(true)
		} else {
			setIsSearchBarActive(false)
		}
	}

	useEffect(() => {
		if (searchTerm) {
			setIsSearchBarActive(true)
		} else {
			setIsSearchBarActive(false)
		}
	}, [searchTerm])

	const handlePopularGameSearch = (gameTitle) => {
		setSearchTerm(gameTitle)
		setIsSearchBarActive(true)
	}

	const [showWhatUserWannaDo, setShowWhatUserWannaDo] = useState(false)
	const [showNotEligibleUsers, setShowNotEligibleUsers] = useState(false)
	const [showPopupProduct, setShowPopupProduct] = useState(false)

	useEffect(() => {
		if (user) {
			setShowNotEligibleUsers(false)
		} else {
			setShowNotEligibleUsers(true)
		}
	}, [user])

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const keyword = params.get('keyword') || ''
		setSearchTerm(keyword)
	}, [location.search])

	const handleSearchBarChange = (e) => {
		const newSearchTerm = e.target.value
		setSearchTerm(newSearchTerm)

		const params = new URLSearchParams(location.search)
		params.set('keyword', newSearchTerm)
		window.history.replaceState({}, '', `${location.pathname}?${params}`)
	}

	return (
		<div className={`mx-5 pb-6 h-full w-full`}>
			{/* {loadingGames ? (
        <div className='loading-overlay'>
          <div className='spinner'></div>
        </div>
      ) : ( */}
			<div className="pb-5 w-full">
				<span className="flex items-center justify-center gap-2 px-4 py-3 rounded-b-xl bg-black text-white text-center font-semibold">
					<BsHeadsetVr size={24} />
					<p>{translations[language].pcvrGames}</p>
				</span>
				{!isSearchBarActive && (
					<>
						{!user && (
							<div className="p-4 bg-gradient-to-r from-red-50 to-red-100 border-2 border-red-300 rounded-xl flex justify-center text-center my-4">
								<p>
									<span className="px-3 py-1 rounded-full bg-gradient-to-r from-red-300 to-red-500 text-white font-semibold text-center">
										Info
									</span>
									<span className="text-red-500 font-semibold ml-2">
										{translations[language].accessDownloadAnnouncement}
									</span>
								</p>
							</div>
						)}
						{user && showAnnouncement === true && (
							<div className="p-4 bg-gradient-to-r from-orange-50 to-orange-100 border-2 border-orange-300 rounded-xl flex justify-center text-center my-4">
								<p>
									<span className="px-3 py-1 rounded-full bg-gradient-to-r from-orange-300 to-orange-500 text-white font-semibold text-center">
										Info
									</span>
									<span className="text-orange-500 font-semibold ml-2">
										{translations[language].notEligibleUsers}
									</span>
								</p>
							</div>
						)}

						{user && showAnnouncement === false && (
							<div className="p-4 bg-gradient-to-r from-green-50 to-green-100 border-2 border-green-300 rounded-xl flex justify-center text-center my-4">
								<p>
									<span className="px-3 py-1 rounded-full bg-gradient-to-r from-green-300 to-green-500 text-white font-semibold text-center">
										Info
									</span>
									<span className="text-green-500 font-semibold ml-2">
										{translations[language].eligibleUsers}
									</span>
								</p>
							</div>
						)}
						<div className="flex flex-row gap-4 w-full">
							<span
								className={`flex flex-col items-center gap-1 py-2 px-3 justify-center text-center bg-[#0081FB] text-white rounded-lg text-base w-full`}
							>
								<span className="font-semibold">
									{translations[language].lastUpdated}
								</span>
								<span className="">
									{lastUpdated
										? lastUpdated
										: translations[language].unknownTime}
								</span>
							</span>
							<span
								className={`flex flex-col items-center gap-1 py-2 px-3 justify-center text-center bg-white border-2 rounded-lg text-base w-full`}
							>
								<span className="font-bold text-black">
									{translations[language].totalGames}
								</span>
								<span className="">
									{games.length + translations[language].games}
									{/* <span className='ml-1 px-2 py-1 bg-red-500 rounded-lg animate-none text-white font-semibold'>(300++ {translations[language].isComing})</span> */}
								</span>
							</span>
						</div>
					</>
				)}
				<div className="relative w-full">
					<div className="relative">
						<input
							type="text"
							placeholder={translations[language].searchGames}
							value={searchTerm}
							onChange={handleSearchBarChange}
							onClick={handleSearchBarActiveClick}
							className="p-2 pl-4 border-2 border-blue-500 rounded-xl outline-[#0081FB] w-full h-12 my-4 pr-10 placeholder:text-zinc-800"
						/>
						{isSearchBarActive && (
							<button
								onClick={handleSearchBarNonActiveClick}
								className="absolute top-0 right-0 mr-3 mt-6 text-red-500 hover:text-red-700 cursor-pointer"
							>
								<FiX size={30} />
							</button>
						)}
						{!searchTerm && !isSearchBarActive && (
							<span className="absolute top-0 right-0 mr-3 mt-7 text-gray-500">
								<FiSearch size={24} />
							</span>
						)}
					</div>
					{isSearchBarActive && (
						<div
							className="mt-0 mb-4 text-sm"
							onMouseDown={(e) => e.preventDefault()}
						>
							<p className={`${isDarkMode ? 'text-white' : 'text-gray-500'}`}>
								{translations[language].top7MostDownloadedGames}
							</p>
							<div className="flex gap-x-2 mt-2 flex-wrap">
								{popularGames.map((game, index) => (
									<button
										key={game.id}
										onClick={() => handlePopularGameSearch(game.gameTitle)}
										className="bg-gradient-to-br from-blue-300 to-blue-600 text-white text-sm py-2 px-4 rounded-full text-left hover:from-blue-400 hover:to-blue-700 focus:outline-none focus:ring focus:border-blue-300 mb-2 flex items-center"
									>
										<span className="mr-2">{index + 1}.</span>
										{game.gameTitle}
									</button>
								))}
							</div>
						</div>
					)}
				</div>

				<div ref={showFiltersRef}>
					<div className="flex items-center gap-2 mb-4 justify-between z-[9999]">
						<span
							className={`flex items-center gap-2 font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							<button
								onClick={handleFiltersClick}
								className={`flex items-center gap-2 ${isDarkMode ? (showFilters ? 'bg-[#0081FB] text-white' : 'hover:bg-zinc-700') : showFilters ? 'bg-[#0081FB] text-white' : 'bg-white'} border-2 px-3 py-1 rounded-xl transition-all duration-300`}
							>
								<VscSettings />
								<span>{translations[language].filter}</span>
								<GoTriangleDown
									className={`${showFilters ? 'rotate-180' : 'rotate-0'} transition-all duration-300 text-xl`}
								/>
							</button>
						</span>
						<div className="flex items-center gap-2">
							<span
								className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								{translations[language].sortBy}
							</span>
							<select
								onChange={handleSortByChange}
								value={sortBy}
								className={`${isDarkMode ? 'bg-zinc-700 hover:bg-zinc-500 text-white border-zinc-500 border-2' : 'bg-white hover:bg-zinc-200 text-black border-zinc-200 border-2'} outline-none px-2 py-1 w-24 cursor-pointer rounded-xl`}
							>
								{/* <option value="name">{translations[language].name}</option> */}
								<option value="added">{translations[language].added}</option>
							</select>
							<button
								onClick={toggleSortOrder}
								className={`bg-gray-200 p-2 rounded hover:bg-gray-300 transition-all duration-300 ${sortBy === 'added' ? 'hidden' : ''}`}
							>
								{sortOrder === 'asc' ? (
									<FaSortAlphaDown />
								) : (
									<FaSortAlphaDownAlt />
								)}
							</button>
						</div>
					</div>
					<div
						className={`${
							showFilters
								? 'opacity-100 scale-100 visible'
								: 'opacity-0 scale-95 invisible'
						} flex flex-col z-30 gap-0 transform transition-all border-2 ${isDarkMode ? 'border-zinc-500' : 'border-white'} duration-300 origin-top-left absolute p-4 w-fit rounded-xl shadow-2xl ${isDarkMode ? 'bg-zinc-700' : 'bg-white'} ring-1 ring-black ring-opacity-5`}
					>
						<span className="flex flex-col gap-2">
							<span className="flex items-center gap-2 mb-2">
								<span
									className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<MdFileDownloadOff className="inline-block text-xl mr-1" />{' '}
									{translations[language].hideEmptyDownload}
								</span>
								<Switch
									onChange={() => setHideEmptyDownload(!hideEmptyDownload)}
									onColor="#0081FB"
									uncheckedIcon={false}
									checkedIcon={false}
									checked={hideEmptyDownload}
								/>
							</span>
						</span>
						<hr className="border-gray-300 my-2" />
						<span
							className={`flex items-start gap-2 ${user ? 'mt-2' : ''} flex-col`}
						>
							<span
								className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								<BsHeadsetVr className="inline-block mr-2 text-xl" />
								<span>{translations[language].supportedQuests}</span>
							</span>
							<label
								className={`font-normal flex items-center gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								<input
									type="checkbox"
									className="size-5 rounded"
									checked={quest1Support}
									onChange={handleQuest1Change}
								/>
								<span>Quest 1</span>
							</label>

							<label
								className={`font-normal flex items-center gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								<input
									type="checkbox"
									className="size-5 rounded"
									checked={quest2Support}
									onChange={handleQuest2Change}
								/>
								<span>Quest 2</span>
							</label>

							<label
								className={`font-normal flex items-center gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								<input
									type="checkbox"
									className="size-5 rounded"
									checked={quest3Support}
									onChange={handleQuest3Change}
								/>
								<span>Quest 3</span>
							</label>

							<label
								className={`font-normal flex items-center gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								<input
									type="checkbox"
									className="size-5 rounded"
									checked={questProSupport}
									onChange={handleQuestProChange}
								/>
								<span>Quest Pro</span>
							</label>
						</span>
						<hr className="border-gray-300 my-4" />
						<div className="flex flex-row gap-2 items-center justify-between">
							<p className="font-semibold">
								{translations[language].gamePerPage}
							</p>
							<select
								value={perPage}
								onChange={handlePerPageChange}
								className={`${isDarkMode ? 'bg-zinc-700 hover:bg-zinc-500 text-white border-zinc-500 border-2' : 'bg-white hover:bg-zinc-200 text-black border-zinc-200 border-2'} outline-none px-2 py-1 w-fit cursor-pointer rounded-xl`}
							>
								<option
									value={12}
								>{`12/${translations[language].page}`}</option>
								<option
									value={24}
								>{`24/${translations[language].page}`}</option>
								<option
									value={48}
								>{`48/${translations[language].page}`}</option>
								<option
									value={lengthGames}
								>{`${translations[language].all}`}</option>
							</select>
						</div>
					</div>
				</div>
				<hr className="border-gray-300 my-4" />
				{/* {filterGameStatus === 'new' && (
					<div className="p-4 bg-gradient-to-r from-blue-50 to-blue-100 border-2 border-blue-300 rounded-xl flex justify-center text-center my-4">
						<p>
							<span className="px-3 py-1 rounded-full bg-gradient-to-r from-blue-300 to-blue-500 text-white font-semibold text-center">
								Info
							</span>
							<span className="text-blue-500 font-semibold ml-2">
								{translations[language].filterGameStatusInfo}
							</span>
						</p>
					</div>
				)}

				{filterGameStatus === 'update' && (
					<div className="p-4 bg-gradient-to-r from-yellow-50 to-yellow-100 border-2 border-yellow-300 rounded-xl flex justify-center text-center my-4">
						<p>
							<span className="px-3 py-1 rounded-full bg-gradient-to-r from-yellow-300 to-yellow-500 text-white font-semibold text-center">
								Info
							</span>
							<span className="text-yellow-500 font-semibold ml-2">
								{translations[language].filterGameStatusInfo}
							</span>
						</p>
					</div>
				)} */}

				{/* {filterGameStatus === 'soon' && (
            <div className='p-4 bg-gradient-to-r from-green-50 to-green-100 border-2 border-green-300 rounded-xl flex justify-center text-center my-4'>
              <p>
                <span className='px-3 py-1 rounded-full bg-gradient-to-r from-green-300 to-green-500 text-white font-semibold text-center'>Info</span>
                <span className='text-green-500 font-semibold ml-2'>{translations[language].filterGameStatusInfo}</span>
              </p>
            </div>
          )} */}
				{/* <div className="flex flex-row gap-0 mb-5 w-full border-2 rounded-xl">
					<button
						className={`w-full py-2 px-4 rounded-lg focus:outline-none ${filterGameStatus === null ? 'bg-gradient-to-br from-zinc-300 to-zinc-500 hover:from-zinc-400 hover:to-zinc-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
						onClick={() => handleFilterGameStatus(null)}
					>
						{translations[language].all} ({games.length})
					</button>
					<button
            className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterGameStatus === 'new' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
            onClick={() => handleFilterGameStatus('new')}>
            {translations[language].new} ({filteredGameStatusGame_NoFilterGameStatusState.filter(game => game.gameStatus === 'new').length})
          </button>
          <button
            className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterGameStatus === 'update' ? 'bg-gradient-to-br from-yellow-300 to-yellow-500 hover:from-yellow-400 hover:to-yellow-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
            onClick={() => handleFilterGameStatus('update')}>
            {translations[language].update} ({filteredGameStatusGame_NoFilterGameStatusState.filter(game => game.gameStatus === 'update').length})
          </button>
          <button
            className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterGameStatus === 'soon' ? 'bg-gradient-to-br from-green-300 to-green-500 hover:from-green-400 hover:to-green-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
            onClick={() => handleFilterGameStatus('soon')}>
            {translations[language].soon} ({filteredGameStatusGame_NoFilterGameStatusState.filter(game => game.gameStatus === 'soon').length})
          </button>
				</div> */}

				<div>
					<div className={`grid ${gridCols} gap-3`}>
						{currentFilteredGames.map((game, index) => (
							<ListGamesCard_PCVR
								key={index}
								game={game}
								layout={layout}
								order={index + 1}
							/>
						))}
					</div>
					<div className="w-full flex justify-center mt-4">
						<button
							onClick={handleLoadMore}
							className={`${filteredGameStatusGame.length > displayedGames ? '' : 'hidden'} mt-4 px-4 py-2 bg-gradient-to-br from-blue-400 to-blue-600 text-white rounded-xl hover:from-blue-500 hover:to-blue-700 focus:outline-none focus:ring focus:border-blue-300`}
						>
							{translations[language].loadMore}
						</button>
					</div>
					{noSearchResults && (
						<div className="flex flex-col items-center justify-center my-4">
							<p className="text-red-500 font-semibold mb-2 text-center">
								{translations[language].noGameFound}
							</p>
							<Link to="/vr-games/request-games">
								<button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
									Request Game
									<FaAngleRight className="inline-block ml-2" />
								</button>
							</Link>
							<p className="text-gray-500 mt-2 text-center text-xs">
								{translations[language].requestGameDesc}
							</p>
						</div>
					)}
				</div>
			</div>
			{/* )} */}

			<Helmet>
				<title>
					{translations[language].pcvrGames} | {translations[language].vrGames}{' '}
					- HyperTopia
				</title>
			</Helmet>
		</div>
	)
}

export default Standalone_ListGames
