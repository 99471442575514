import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../../firebase'

import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../../DarkModeContext'
import { storage } from '../../firebase'
import {
	getDownloadURL,
	listAll,
	ref,
	uploadBytes,
	uploadBytesResumable,
} from 'firebase/storage'
import { FaArrowLeft, FaTrash, FaCheckCircle, FaCheck } from 'react-icons/fa' // Import FaCheckCircle icon
import Compressor from 'compressorjs'
import ProgressBar from '@ramonak/react-progress-bar'
import toast, { Toaster } from 'react-hot-toast'

const initialState_VRGames = {
	gameTitle: '',
	photoUrl: '',
	imageUrl: '', // Menyimpan URL gambar yang akan diunduh
	timeAdded: new Date().toISOString(),
}

const initialState_ProductsImages = {
	productName: '',
}

const UploadCover_Admin = () => {
	const [state_VRGames, setState_VRGames] = useState(initialState_VRGames)
	const [state_ProductsImages, setState_ProductsImages] = useState(
		initialState_ProductsImages
	)
	const [isImageUploaded_VRGames, setIsImageUploaded_VRGames] = useState(false)
	const [isImageUploaded_ProductImages, setIsImageUploaded_ProductImages] =
		useState(false)
	const [imageLink, setImageLink] = useState('') // State untuk menyimpan URL gambar dari input
	const [gameTitles, setGameTitles] = useState([]) // State untuk menyimpan daftar judul game
	const [productTitles, setProductTitles] = useState([]) // State untuk menyimpan daftar judul produk
	const [isGameImageAvailable, setIsGameImageAvailable] = useState(false) // State untuk menyimpan status ketersediaan gambar game
	const [isProductImageAvailable, setIsProductImageAvailable] = useState(false) // State untuk menyimpan status ketersediaan gambar produk

	const { isDarkMode } = useDarkModeContext()
	const navigate = useNavigate()
	const { id } = useParams()

	const imageListRef_VRGames = ref(storage, 'coverGamesImages/')
	const imageListRef_ProductsImages = ref(storage, 'productPhotos/')

	const language = localStorage.getItem('language') || 'id'

	const [dataType, setDataType] = useState(
		localStorage.getItem('dataTypeAddEdit')
	) // Default to 'vrGames'

	const [gameImageUrl, setGameImageUrl] = useState(null)

	const [selectedFiles_Games, setSelectedFiles_Games] = useState([])
	const [selectedFiles_Products, setSelectedFiles_Products] = useState([])

	const [selectedFiles_ProductsImages, setSelectedFiles_ProductsImages] =
		useState([])

	const [uploadProgress_Games, setUploadProgress_Games] = useState({})
	const [uploadProgress_Products, setUploadProgress_Products] = useState({})

	const [overallProgress_Games, setOverallProgress_Games] = useState(0)
	const [overallProgress_Products, setOverallProgress_Products] = useState(0)

	const [uploadProgress_ProductImages, setUploadProgress_ProductImages] =
		useState({})
	const [overallProgress_ProductImages, setOverallProgress_ProductImages] =
		useState(0)

	const handleFileChange_Games = (event) => {
		const files = Array.from(event.target.files) // Mengonversi objek FileList menjadi array
		setSelectedFiles_Games(files) // Menetapkan array file yang dipilih ke state
	}

	const handleFileChange_Products = (event) => {
		const files = Array.from(event.target.files) // Mengonversi objek FileList menjadi array
		setSelectedFiles_Products(files) // Menetapkan array file yang dipilih ke state
	}

	const handleFileChange_ProductsImages = (event) => {
		const files = Array.from(event.target.files) // Mengonversi objek FileList menjadi array
		setSelectedFiles_ProductsImages(files)
	}

	const handleUpload_Games = () => {
		if (!Array.isArray(selectedFiles_Games)) {
			console.error('Selected files is not an array')
			return
		}

		selectedFiles_Games.forEach((file) => {
			const gameTitle = file.name.split('.')[0].replace(/_/g, ': ')
			const imageRef = ref(storage, `coverGamesImages/${gameTitle}`) // Menggunakan nama file sebagai nama gambar dalam penyimpanan
			const uploadTask = uploadBytesResumable(imageRef, file)

			// Mengatur listener untuk kemajuan unggah
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Perbarui state untuk kemajuan unggah file
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					)
					setUploadProgress_Games((prevState) => ({
						...prevState,
						[gameTitle]: progress,
					}))

					// Hitung total kemajuan keseluruhan
					const totalProgress =
						Object.values(uploadProgress_Games).reduce(
							(acc, curr) => acc + curr,
							0
						) / selectedFiles_Games.length
					setOverallProgress_Games(totalProgress)
				},
				(error) => {
					// Handle upload error
					console.error('Error uploading file:', error)
				},
				() => {
					// Upload selesai, dapatkan URL unduhan
					getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
						// Handle successful upload
						console.log(`${gameTitle} uploaded successfully!`)
					})
				}
			)
		})
	}

	const handleUpload_Products = () => {
		if (!Array.isArray(selectedFiles_Products)) {
			console.error('Selected files is not an array')
			return
		}

		selectedFiles_Products.forEach((file) => {
			const productTitle = file.name.split('.')[0].replace(/_/g, ': ')
			const imageRef = ref(storage, `productPhotos/${productTitle}`) // Menggunakan nama file sebagai nama gambar dalam penyimpanan
			const uploadTask = uploadBytesResumable(imageRef, file)

			// Mengatur listener untuk kemajuan unggah
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Perbarui state untuk kemajuan unggah file
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					)
					setUploadProgress_Products((prevState) => ({
						...prevState,
						[productTitle]: progress,
					}))

					// Hitung total kemajuan keseluruhan
					const totalProgress =
						Object.values(uploadProgress_Products).reduce(
							(acc, curr) => acc + curr,
							0
						) / selectedFiles_Products.length
					setOverallProgress_Products(totalProgress)
				},
				(error) => {
					// Handle upload error
					console.error('Error uploading file:', error)
				},
				() => {
					// Upload selesai, dapatkan URL unduhan
					getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
						// Handle successful upload
						console.log(`${productTitle} uploaded successfully!`)
					})
				}
			)
		})
	}

	useEffect(() => {
		// Fetch list of game titles from database
		const fetchData = async () => {
			try {
				const snapshot = await fireDb.ref(`vrGames/standalone`).once('value')
				const data = snapshot.val()
				if (data) {
					const titles = Object.keys(data).map((key) => ({
						id: key,
						title: data[key].gameTitle,
					}))
					setGameTitles(titles)
				}
			} catch (error) {
				console.error('Error fetching game titles:', error)
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		// Fetch list of product titles from database
		const fetchData = async () => {
			try {
				const snapshot = await fireDb.ref(`products`).once('value')
				const data = snapshot.val()
				if (data) {
					const titles = Object.keys(data).map((key) => ({
						id: key,
						title: data[key].productName,
					}))
					setProductTitles(titles)
				}
			} catch (error) {
				console.error('Error fetching product titles:', error)
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		const checkGameImageAvailability = async () => {
			try {
				if (state_VRGames.gameTitle) {
					const gameImageRef = ref(storage, 'coverGamesImages')
					const gameImageList = await listAll(gameImageRef)
					const gameImageExists = gameImageList.items.some(
						(item) => item.name === state_VRGames.gameTitle
					)
					setIsGameImageAvailable(gameImageExists)
				} else {
					setIsGameImageAvailable(false)
				}
			} catch (error) {
				console.error('Error checking game image availability:', error)
				setIsGameImageAvailable(false)
			}
		}
		checkGameImageAvailability()
	}, [state_VRGames.gameTitle])

	useEffect(() => {
		const checkGameImageAvailability = async () => {
			try {
				if (state_ProductsImages.productName) {
					const productImageRef = ref(storage, 'productPhotos')
					const productImageList = await listAll(productImageRef)
					const productImageExists = productImageList.items.some(
						(item) => item.name === state_ProductsImages.productName
					)
					setIsProductImageAvailable(productImageExists)
				} else {
					setIsProductImageAvailable(false)
				}
			} catch (error) {
				console.error('Error checking product image availability:', error)
				setIsGameImageAvailable(false)
			}
		}
		checkGameImageAvailability()
	}, [state_ProductsImages.productName])

	useEffect(() => {
		if (id) {
			if (dataType === 'vrGames') {
				fireDb.ref(`vrGames/standalone/${id}`).on('value', (snapshot) => {
					const existingData = snapshot.val()
					if (existingData) {
						setState_VRGames({
							gameTitle: existingData.gameTitle || '',
							photoUrl: existingData.photoUrl || '',
						})
					} else {
						setState_VRGames(initialState_VRGames)
					}
				})
			}
		} else {
			if (dataType === 'vrGames') {
				setState_VRGames({
					...initialState_VRGames,
					downloadLinks: [''],
					timeAdded: new Date().toISOString(),
				})
			}
		}

		return () => {
			if (dataType === 'vrGames') {
				fireDb.ref(`vrGames/standalone/${id}`).off('value')
			}
		}
	}, [id, dataType])

	useEffect(() => {
		if (id) {
			fireDb.ref(`products/${id}`).on('value', (snapshot) => {
				const existingData = snapshot.val()
				if (existingData) {
					setState_ProductsImages({
						productName: existingData.productName || '',
					})
				} else {
					setState_ProductsImages(initialState_VRGames)
				}
			})
		}
		return () => {
			fireDb.ref(`products/${id}`).off('value')
		}
	}, [id, dataType])

	const handleInputChange_VRGames = (e) => {
		const { name, value, type, checked } = e.target
		const inputValue = type === 'checkbox' ? checked : value
		setState_VRGames({ ...state_VRGames, [name]: inputValue })
	}

	const handleInputChange_ProductsImages = (e) => {
		const { name, value } = e.target // Hanya memerlukan 'name' dan 'value' dari event target
		setState_ProductsImages({ ...state_ProductsImages, [name]: value })
	}

	const handleImageLinkChange = (e) => {
		const cleanedUrl = cleanImageUrl(e.target.value)
		setImageLink(cleanedUrl)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (dataType === 'vrGames') {
			if (!state_VRGames.gameTitle) {
				toast.error('Please select a game title!')
			} else {
				setTimeout(() => {
					setState_VRGames(initialState_VRGames)
					setImageLink('')
					alert('Image uploaded successfully!')
				}, 500)
			}
		}
	}

	useEffect(() => {
		listAll(imageListRef_VRGames).then((response) => {
			const gameImageName = `${state_VRGames.gameTitle}`
			const userImage = response.items.find(
				(item) => item.name === gameImageName
			)
			if (userImage) {
				getDownloadURL(userImage).then((url) => {
					setGameImageUrl(url)
					setImageLink(url)
				})
			}
		})
	}, [state_VRGames.gameTitle, imageListRef_VRGames])

	const uploadImage_VRGames = () => {
		if (imageLink) {
			const xhr = new XMLHttpRequest()
			xhr.open('GET', imageLink)
			xhr.responseType = 'blob'
			xhr.onload = () => {
				const blob = xhr.response
				const fileName = state_VRGames.gameTitle // Gunakan gameTitle tanpa mengubah apapun
				new Compressor(blob, {
					quality: 1.0,
					maxWidth: 1000,
					success(compressedBlob) {
						const imageRef_VRGames = ref(
							storage,
							`coverGamesImages/${fileName}`
						)
						uploadBytes(imageRef_VRGames, compressedBlob).then(() => {
							setIsImageUploaded_VRGames(true)
						})
						getDownloadURL(imageRef_VRGames).then((url) => {
							setState_VRGames({ ...state_VRGames, photoUrl: url })
						})
					},
					error(error) {
						console.error('Error compressing image:', error)
						toast.error('Failed to compress image.')
					},
				})
			}
			xhr.send()
		} else {
			toast.error('Please provide a valid image URL.')
		}
	}

	useEffect(() => {
		localStorage.setItem('language', language)
	}, [language])

	const cleanImageUrl = (imageUrl) => {
		return imageUrl.replace(/^url\("(.*)"\)$/, '$1')
	}

	const [previewImageUrl, setPreviewImageUrl] = useState('')

	// Buat fungsi untuk menampilkan pratinjau gambar
	const showPreview = () => {
		setPreviewImageUrl(imageLink)
	}

	// Fungsi untuk mengunggah file gambar produk ke storage
	const handleUpload_ProductsImages = () => {
		if (!Array.isArray(selectedFiles_ProductsImages)) {
			console.error('Selected files is not an array')
			return
		}

		selectedFiles_ProductsImages.forEach((file) => {
			const productName = state_ProductsImages.productName
			const newFileName = `${productName}` // Mengganti nama file dengan nama produk
			const imageRef = ref(storage, `productPhotos/${newFileName}`) // Menentukan direktori langsung ke folder productPhotos dengan nama file yang telah diubah
			const uploadTask = uploadBytesResumable(imageRef, file)

			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Perbarui state untuk kemajuan unggah file
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					)
					setUploadProgress_ProductImages((prevState) => ({
						...prevState,
						[newFileName]: progress,
					}))

					// Hitung total kemajuan keseluruhan
					const totalProgress =
						Object.values(uploadProgress_ProductImages).reduce(
							(acc, curr) => acc + curr,
							0
						) / selectedFiles_ProductsImages.length
					setOverallProgress_ProductImages(totalProgress)
				},
				(error) => {
					// Handle upload error
					console.error('Error uploading file:', error)
				},
				() => {
					// Upload selesai
					console.log(`${newFileName} uploaded successfully!`)
					setIsImageUploaded_ProductImages(true)
				}
			)
		})
	}

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`pt-6 mb-10 mx-5 w-full h-full ${!isMobile && 'pt-[100px]'} min-h-screen`}
		>
			<Toaster />
			<div className="p-4 bg-gradient-to-r from-blue-50 to-blue-100 border-2 border-blue-300 rounded-xl flex items-center justify-center text-center mb-4">
				<p>
					<span className="px-3 py-1 rounded-full bg-gradient-to-r from-blue-300 to-blue-500 text-white font-semibold text-center">
						Info
					</span>
					<span className="text-blue-500 font-semibold ml-2">
						Single File Upload
					</span>
				</p>
			</div>
			<div className="flex flex-col items-center justify-center w-full">
				<h2 className="text-2xl font-semibold mb-4">Upload Game Cover</h2>
				<form
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'} border`}
					onSubmit={handleSubmit}
				>
					<div className="flex gap-3 items-center justify-between w-full">
						<label className="w-64" htmlFor="gameTitle">
							<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
								Game Title
							</span>
						</label>
						<select
							id="gameTitle"
							name="gameTitle"
							value={state_VRGames.gameTitle}
							onChange={handleInputChange_VRGames}
							className="rounded p-2 input-box w-full"
						>
							<option value="">Select a game title</option>
							{gameTitles.map((title) => (
								<option key={title.id} value={title.title}>
									{title.title}
								</option>
							))}
						</select>
					</div>

					{isGameImageAvailable && (
						<div className="px-4 py-2 bg-green-500 text-white rounded-lg flex flex-row gap-2 items-center text-center justify-center">
							<FaCheckCircle />
							<span>Gambar Telah Tersedia!</span>
						</div>
					)}

					<div className="flex gap-3 items-center justify-between w-full">
						<label className="w-64" htmlFor="imageLink">
							<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
								Image URL
							</span>
						</label>
						<input
							type="text"
							id="imageLink"
							name="imageLink"
							placeholder="Enter Image URL"
							value={imageLink === '' ? state_VRGames.photoUrl : imageLink}
							onChange={handleImageLinkChange}
							className="rounded p-2 input-box w-full"
						/>
						<button
							className="bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-lg p-2 cursor-pointer"
							onClick={showPreview}
						>
							Preview
						</button>
					</div>

					{previewImageUrl !== '' && (
						<img
							src={previewImageUrl}
							alt="Game Cover Preview"
							className="object-cover rounded-lg"
						/>
					)}

					<input
						className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
						type="submit"
						value={id ? 'Update' : 'Upload'}
						onClick={uploadImage_VRGames}
					/>
				</form>

				<hr className="my-4" />

				<h2 className="text-2xl font-semibold mb-4">Upload Product Photos</h2>
				<form
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'} border`}
					onSubmit={handleSubmit}
				>
					<div className="flex gap-3 items-center justify-between w-full">
						<label className="w-64" htmlFor="productName">
							<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
								Product Title
							</span>
						</label>
						<select
							id="productName"
							name="productName"
							value={state_ProductsImages.productName} // Pastikan value sesuai dengan productName dalam state
							onChange={handleInputChange_ProductsImages}
							className="rounded p-2 input-box w-full"
						>
							<option value="">Select a product title</option>
							{productTitles.map((title) => (
								<option key={title.id} value={title.title}>
									{title.title}
								</option>
							))}
						</select>
					</div>

					{isProductImageAvailable && (
						<div className="px-4 py-2 bg-green-500 text-white rounded-lg flex flex-row gap-2 items-center text-center justify-center">
							<FaCheckCircle />
							<span>Gambar Telah Tersedia!</span>
						</div>
					)}

					<input
						type="file"
						multiple
						onChange={handleFileChange_ProductsImages}
					/>

					<p className="text-lg font-semibold">Upload Progress:</p>
					<ProgressBar
						completed={overallProgress_ProductImages.toFixed(1)}
						maxCompleted={100}
						bgColor="#0081FB"
						className="w-full"
					></ProgressBar>
					<p className="text-sm font-semibold">
						File Progress: {overallProgress_ProductImages.toFixed(1)}%
					</p>

					<div className="w-full">
						{Array.isArray(selectedFiles_ProductsImages) &&
							selectedFiles_ProductsImages.map((file, index) => (
								<div
									className="w-full flex flex-row justify-between my-1"
									key={index}
								>
									<span className="w-96">{file.name}</span>
									{uploadProgress_ProductImages[file.name] < 100 && (
										<ProgressBar
											completed={uploadProgress_ProductImages[file.name] || 0}
											maxCompleted={100}
											bgColor="#0081FB"
											className="w-full"
										></ProgressBar>
									)}
									<FaCheckCircle className="text-green-500 size-6" />
								</div>
							))}
					</div>

					<input
						className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
						type="submit"
						value={id ? 'Update' : 'Upload'}
						onClick={handleUpload_ProductsImages}
					/>
				</form>

				<hr className="my-4" />
				<div className="p-4 bg-gradient-to-r from-red-50 to-red-100 border-2 border-red-300 rounded-xl flex items-center justify-center text-center w-full">
					<p>
						<span className="px-3 py-1 rounded-full bg-gradient-to-r from-red-300 to-red-500 text-white font-semibold text-center">
							Info
						</span>
						<span className="text-red-500 font-semibold ml-2">
							Multiple Files Upload
						</span>
					</p>
				</div>

				<h2 className="text-2xl font-semibold mb-4 mt-10">Products</h2>
				<div
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'} border`}
				>
					<input type="file" multiple onChange={handleFileChange_Products} />
					<button
						className="bg-red-500 hover:bg-red-700 transition-all duration-300 text-white rounded-lg p-2 cursor-pointer"
						onClick={handleUpload_Products}
					>
						Upload All
					</button>
					<hr className="my-4" />
					<div className="w-full">
						{Array.isArray(selectedFiles_Products) &&
							selectedFiles_Products.map((file, index) => (
								<div
									className="w-full flex flex-row justify-between my-1"
									key={index}
								>
									<span className="w-96">
										{file.name.split('.')[0].replace(/_/g, ': ')}
									</span>
									{uploadProgress_Products[
										file.name.split('.')[0].replace(/_/g, ': ')
									] < 100 && (
										<ProgressBar
											completed={
												uploadProgress_Products[
													file.name.split('.')[0].replace(/_/g, ': ')
												] || 0
											}
											maxCompleted={100}
											bgColor="#0081FB"
											className="w-full"
										></ProgressBar>
									)}
									{uploadProgress_Products[
										file.name.split('.')[0].replace(/_/g, ': ')
									] === 100 && (
										<FaCheckCircle className="text-green-500 size-6" />
									)}
								</div>
							))}
					</div>
				</div>

				<h2 className="text-2xl font-semibold mb-4 mt-10">Games</h2>
				<div
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'} border`}
				>
					<input type="file" multiple onChange={handleFileChange_Games} />
					<button
						className="bg-red-500 hover:bg-red-700 transition-all duration-300 text-white rounded-lg p-2 cursor-pointer"
						onClick={handleUpload_Games}
					>
						Upload All
					</button>
					<hr className="my-4" />
					<div className="w-full">
						{Array.isArray(selectedFiles_Games) &&
							selectedFiles_Games.map((file, index) => (
								<div
									className="w-full flex flex-row justify-between my-1"
									key={index}
								>
									<span className="w-96">
										{file.name.split('.')[0].replace(/_/g, ': ')}
									</span>
									{uploadProgress_Games[
										file.name.split('.')[0].replace(/_/g, ': ')
									] < 100 && (
										<ProgressBar
											completed={
												uploadProgress_Games[
													file.name.split('.')[0].replace(/_/g, ': ')
												] || 0
											}
											maxCompleted={100}
											bgColor="#0081FB"
											className="w-full"
										></ProgressBar>
									)}
									{uploadProgress_Games[
										file.name.split('.')[0].replace(/_/g, ': ')
									] === 100 && (
										<FaCheckCircle className="text-green-500 size-6" />
									)}
								</div>
							))}
					</div>
				</div>

				<Helmet>
					<title>Upload Cover | Admin</title>
				</Helmet>
			</div>

			<style>
				{`
        .input-box {
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
        `}
			</style>
		</div>
	)
}

export default UploadCover_Admin
