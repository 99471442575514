import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { Link } from 'react-router-dom'
import './Home.css'

import { Helmet } from 'react-helmet'
import {
	FaCheck,
	FaEdit,
	FaExternalLinkAlt,
	FaExternalLinkSquareAlt,
	FaHistory,
	FaHourglassHalf,
	FaPlus,
	FaSortAlphaDown,
	FaSortAlphaDownAlt,
	FaSortNumericDown,
	FaSortNumericDownAlt,
	FaTimes,
	FaTrash,
	FaTrashAlt,
} from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import Swal from 'sweetalert2'
import { useDarkModeContext } from '../../DarkModeContext'
import { auth } from '../../firebase'
import { MdCancel, MdCheckCircle, MdPending } from 'react-icons/md'
import { FiSearch, FiX } from 'react-icons/fi'
import { motion } from 'framer-motion'

const translations = {
	en: {
		searchGames: 'Search Games',
		sortBy: 'Sort by',
		name: 'Name',
		newest: 'Newest',
		title: 'List Request VR Games',
		requestGame: 'Game Request',
		no: 'No',
		gameTitle: 'Game Title',
		metaStoreLink: 'Meta Store Link',
		requestedBy: 'Requested By',
		shortcutLink: 'Shortcut Link',
		viewGame: 'View Game',
		status: 'Status',
		reason: 'Reason',
		timeRequested: 'Time Requested',
		action: 'Action',
		addRequestVRGames: 'Request VR Games',
		edit: 'Edit',
		delete: 'Delete',
		areYouSure: 'Are you sure that you want to delete this requested game?',
		gameDeleted: 'Game Deleted Successfully!',
		errorSomething: 'Something went wrong! Please try again later.',

		confirmButtonText: 'Yes, delete it!',
		cancelButtonText: 'Cancel',

		titleOnDelete: 'Are you sure?',
		textOnDelete: "You won't be able to revert this!",

		noData: 'No data',
		noPermission: "You don't have permission to edit this",

		loginFirst: 'Login first to use this feature!',

		canceled: 'Canceled by Admin',
		pending: 'Pending',
		process: 'Process',
		done: 'Done',
	},
	id: {
		searchGames: 'Cari Game',
		sortBy: 'Urutkan: ',
		name: 'Nama',
		newest: 'Terbaru',
		title: 'Daftar Request VR Games',
		requestGame: 'Request Game',
		no: 'No',
		gameTitle: 'Judul Game',
		metaStoreLink: 'Link Meta Store',
		requestedBy: 'Dibuat Oleh',
		shortcutLink: 'Link Pintasan',
		viewGame: 'Lihat Game',
		status: 'Status',
		reason: 'Alasan',
		timeRequested: 'Waktu Request',
		action: 'Aksi',
		addRequestVRGames: 'Request VR Games',
		edit: 'Edit',
		delete: 'Hapus',
		areYouSure: 'Apakah kamu yakin ingin menghapus game yang direquest ini?',
		gameDeleted: 'Game Berhasil Dihapus!',
		errorSomething: 'Terjadi kesalahan! Silakan coba lagi nanti.',

		confirmButtonText: 'Ya, hapus!',
		cancelButtonText: 'Batal',

		titleOnDelete: 'Apakah kamu yakin?',
		textOnDelete: 'Kamu tidak akan bisa mengembalikan ini!',

		noData: 'Tidak ada data',
		noPermission: 'Kamu tidak bisa edit ini',

		loginFirst: 'Login terlebih dahulu untuk menggunakan fitur ini!',

		canceled: 'Batal',
		pending: 'Menunggu',
		process: 'Proses',
		done: 'Selesai',
	},
}

const Card = ({
	gameTitle,
	gameType,
	status,
	timeProcessed,
	onEdit,
	onDelete,
	requestedBy,
	reason,
}) => {
	// State untuk menyimpan informasi pengguna
	const [user, setUser] = useState(null)
	const language = localStorage.getItem('language') || 'id'

	const translations = {
		en: {
			pending: 'Pending',
			inProcess: 'In Process',
			done: 'Completed',
			canceled: 'Canceled',
			edit: 'Edit',
			delete: 'Delete',
			view: 'View',
			status: 'Status:',
			timeProcessed: 'Time Processed:',
			reason: 'Reason:',
		},
		id: {
			pending: 'Menunggu',
			inProcess: 'Proses',
			done: 'Selesai',
			canceled: 'Dibatalkan',
			edit: 'Edit',
			delete: 'Hapus',
			view: 'Lihat',
			status: 'Status:',
			timeProcessed: 'Waktu Diproses:',
			reason: 'Alasan:',
		},
	}

	useEffect(() => {
		// Cek apakah pengguna sudah login, jika iya, atur state user
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})
		return () => unsubscribe() // Cleanup listener ketika komponen di-unmount
	}, [])

	return (
		<motion.div
			className="w-full h-full mx-auto bg-white rounded-2xl shadow-md border border-gray-200 overflow-hidden flex flex-col justify-between"
			whileHover={{ scale: 1.0 }}
			initial={{ opacity: 0, y: 30 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.4, ease: 'easeOut' }}
		>
			{/* Header */}
			<div className="p-5 bg-white flex items-center justify-between border-b border-gray-200">
				<div>
					<h3 className="text-xl font-bold text-gray-800">{gameTitle}</h3>
					<p className="text-sm text-gray-500">
						{gameType === 'standalone' ? 'Standalone VR' : 'PC VR'}
					</p>
				</div>
				{/* Status Icon */}
				<div>
					{status === 'Pending' && (
						<MdPending className="text-orange-400 text-3xl" />
					)}
					{status === 'Process' && (
						<FaHourglassHalf className="text-blue-400 text-3xl" />
					)}
					{status === 'Done' && (
						<MdCheckCircle className="text-green-400 text-3xl" />
					)}
					{status === 'Canceled' && (
						<MdCancel className="text-red-400 text-3xl" />
					)}
				</div>
			</div>

			{/* Content */}
			<div className="p-5 flex flex-col space-y-3 h-full">
				<div className="flex justify-between text-gray-600">
					<p>{translations[language].status}</p>
					<p
						className={`font-medium ${status === 'Pending' ? 'text-orange-500' : status === 'Process' ? 'text-blue-500' : status === 'Done' ? 'text-green-500' : 'text-red-500'}`}
					>
						{status === 'Pending'
							? translations[language].pending
							: status === 'Process'
								? translations[language].inProcess
								: status === 'Done'
									? translations[language].done
									: translations[language].canceled}
					</p>
				</div>

				<div className="flex justify-between text-gray-600">
					<p>{translations[language].timeProcessed}</p>
					<p className="text-gray-500">
						{timeProcessed
							? new Date(timeProcessed).toLocaleDateString(
									language === 'en' ? 'en-US' : 'id-ID'
								)
							: 'N/A'}
					</p>
				</div>

				<div className="flex justify-between text-gray-600">
					<p>{translations[language].reason}</p>
					<p className="text-gray-500 max-w-64 text-right">{reason}</p>
				</div>
			</div>

			{/* Actions */}
			<div className="border-t border-gray-200 flex justify-between p-4 bg-gray-50">
				{user &&
					(user.email === requestedBy ||
						user.email === 'hypertopiaid@gmail.com') && (
						<>
							<motion.button
								className="flex items-center text-blue-500 hover:text-blue-600"
								whileHover={{ scale: 1.1 }}
								onClick={onEdit}
							>
								<FaEdit className="mr-2" />
								<span>{translations[language].edit}</span>
							</motion.button>

							<motion.button
								className="flex items-center text-red-500 hover:text-red-600"
								whileHover={{ scale: 1.1 }}
								onClick={onDelete}
							>
								<FaTrashAlt className="mr-2" />
								<span>{translations[language].delete}</span>
							</motion.button>
						</>
					)}
				{status === 'Done' && (
					<motion.a
						href={`/vr-games/list-games/${gameType}?keyword=${gameTitle}`}
						className="flex items-center text-green-500 hover:text-green-600"
						whileHover={{ scale: 1.1 }}
					>
						<FaExternalLinkAlt className="mr-2" />
						<span>{translations[language].view}</span>
					</motion.a>
				)}
			</div>
		</motion.div>
	)
}

const ListRequests_Admin = () => {
	const [data, setData] = useState({})
	const language = localStorage.getItem('language') || 'id'
	const { isDarkMode } = useDarkModeContext()
	const [user, setUser] = useState(null) // State untuk menyimpan informasi pengguna

	useEffect(() => {
		// Cek apakah pengguna sudah login, jika iya, atur state user
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})
	}, [])

	const [sortBy, setSortBy] = useState('requested')
	const [sortOrder, setSortOrder] = useState('desc')

	const handleSortByChange = (event) => {
		setSortBy(event.target.value)
	}

	const toggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
	}

	useEffect(() => {
		fireDb.ref(`requestedVRGames`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				setData({ ...snapshot.val() })
			} else {
				setData({})
			}
		})

		return () => {
			setData({})
		}
	}, [])

	const onDelete = (gameTitle) => {
		Swal.fire({
			title: translations[language].titleOnDelete,
			text: translations[language].textOnDelete,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: translations[language].confirmButtonText,
			cancelButtonText: translations[language].cancelButtonText,
		}).then((result) => {
			if (result.isConfirmed) {
				fireDb.ref(`requestedVRGames/${gameTitle}`).remove((err) => {
					if (err) {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: translations[language].errorSomething,
						})
					} else {
						Swal.fire({
							icon: 'success',
							title: 'Success!',
							text: translations[language].gameDeleted,
						})
					}
				})
			}
		})
	}

	const limitStringLength = (str, maxLength) => {
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...'
		}
		return str
	}

	const requestedBySensor = (str, maxLength) => {
		if (str && str.length > maxLength) {
			return str.substring(0, 5) + '•••'
		}
		return str
	}

	const handleAddRequestGamesButton = () => {
		window.location.href = '/dba/list-requests/add'
	}

	const handleUpdateRequestGamesButton = (gameTitle) => {
		window.location.href = `/dba/list-requests/update/${gameTitle}`
	}

	const [searchTerm, setSearchTerm] = useState('')

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value)
	}

	const handleSearchBarNonActiveClick = () => {
		setSearchTerm('')
	}

	const sortedData = Object.keys(data).sort((a, b) => {
		if (sortBy === 'name') {
			return sortOrder === 'asc'
				? data[a].gameTitle.localeCompare(data[b].gameTitle)
				: data[b].gameTitle.localeCompare(data[a].gameTitle)
		} else if (sortBy === 'requested') {
			return sortOrder === 'asc'
				? new Date(data[a].timeRequested) - new Date(data[b].timeRequested)
				: new Date(data[b].timeRequested) - new Date(data[a].timeRequested)
		}
	})

	const filteredSortedData = sortedData.filter((gameTitle) => {
		const game = data[gameTitle]
		return game.gameTitle.toLowerCase().includes(searchTerm.toLowerCase())
	})

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`mx-5 pt-5 pb-5 w-full ${!isMobile && 'pt-[100px]'} min-h-screen`}
		>
			{!user && (
				<span
					className={`${isDarkMode ? 'text-white' : 'text-black'} text-center pb-2`}
				>
					{translations[language].loginFirst}
				</span>
			)}
			<div className="flex flex-row gap-2 items-center justify-center">
				<div className="relative w-full">
					<input
						type="text"
						placeholder={translations[language].searchGames}
						value={searchTerm}
						onChange={handleSearchChange}
						className="p-2 pl-4 border text-sm border-gray-300 rounded-xl outline-[#0081FB] w-full h-10 mb-2 pr-10 placeholder:text-zinc-800"
					/>
					{searchTerm && (
						<button
							onClick={handleSearchBarNonActiveClick}
							className="absolute top-0 right-0 mr-3 mt-2 text-gray-500 hover:text-gray-700 cursor-pointer"
						>
							<FiX size={26} />
						</button>
					)}
					{!searchTerm && (
						<span className="absolute top-0 right-0 mr-3 mt-2 text-gray-500">
							<FiSearch size={23} />
						</span>
					)}
				</div>
				<div className="flex items-center gap-2 mb-2">
					<span
						className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
					>
						{translations[language].sortBy}
					</span>
					<select
						onChange={handleSortByChange}
						value={sortBy}
						className="bg-gray-200 rounded px-2 py-1 w-fit outline-none cursor-pointer hover:bg-gray-300 transition-all duration-300"
					>
						<option value="name">{translations[language].name}</option>
						<option value="requested">{translations[language].newest}</option>
					</select>
					<button
						onClick={toggleSortOrder}
						className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition-all duration-300"
					>
						{sortBy === 'name' ? (
							sortOrder === 'asc' ? (
								<FaSortAlphaDown />
							) : (
								<FaSortAlphaDownAlt />
							)
						) : sortOrder === 'asc' ? (
							<FaSortNumericDown />
						) : (
							<FaSortNumericDownAlt />
						)}
					</button>
				</div>
			</div>
			<div className="flex flex-col mt-4">
				<div className="overflow-x-auto">
					{Object.keys(data).length > 0 ? (
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
							{filteredSortedData.map((gameTitle, index) => (
								<Card
									key={gameTitle}
									gameTitle={data[gameTitle].gameTitle}
									metaStoreLink={requestedBySensor(
										data[gameTitle].metaStoreLink,
										11
									)}
									status={data[gameTitle].status}
									reason={data[gameTitle].reason}
									timeRequested={data[gameTitle].timeRequested}
									onDelete={() => onDelete(gameTitle)}
									onEdit={() => handleUpdateRequestGamesButton(gameTitle)}
								/>
							))}
						</div>
					) : (
						<p
							className={`${isDarkMode ? 'text-white' : 'text-black'} w-full text-center`}
						>
							{translations[language].noData}
						</p>
					)}
				</div>
			</div>

			<Helmet>
				<title>Daftar Permintaan - HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default ListRequests_Admin
