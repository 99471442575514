import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDarkModeContext } from '../../DarkModeContext'
import fireDb, { provider } from '../../firebase'
import { auth } from '../../firebase'
import {
	FaAngleLeft,
	FaAngleRight,
	FaArrowLeft,
	FaArrowRight,
	FaCheck,
	FaCheckCircle,
	FaExclamation,
	FaGoogle,
	FaHistory,
	FaSignInAlt,
	FaSignOutAlt,
	FaTimes,
	FaUserCircle,
} from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { set, get, onValue } from 'firebase/database'
import { signOut } from 'firebase/auth'
import { IoCloseCircle } from 'react-icons/io5'
import { Helmet } from 'react-helmet'

import bgPage from '../../assets/images/bg.jpg'

import Swal from 'sweetalert2'
import { FaDownload, FaHeart } from 'react-icons/fa6'
import { getDownloadURL, listAll } from 'firebase/storage'
import { storage } from '../../firebase'
import { ref } from 'firebase/storage'

const cleanDisplayName = (displayName) => {
	return displayName.replace(/[^\w\s]/gi, '')
}

const Profile = () => {
	const { isDarkMode } = useDarkModeContext()
	const language = localStorage.getItem('language') || 'id'
	const [user, setUser] = useState(null)
	const [margin, setMargin] = useState(false)
	const [pin, setPin] = useState('')
	const [newPin, setNewPin] = useState('')
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [isPINVisible, setIsPINVisible] = useState(false)
	const [hasPin, setHasPin] = useState(false)

	const [tabUserInfoActive, setTabUserInfoActive] = useState(true)
	const [tabSecurityActive, setTabSecurityActive] = useState(false)
	const [accountSettingsActive, setAccountSettingsActive] = useState(false)
	const [shortUserInformationActive, setShortUserInformationActive] =
		useState(true)

	const eligibleUsersStandaloneRef = fireDb.ref('eligibleUsers/standalone')
	const eligibleUsersPCVRRef = fireDb.ref('eligibleUsers/pcvr')

	const [eligibleGameQuest, setEligibleGameQuest] = useState(false)

	const [eligibleStandaloneGameQuest, setEligibleStandaloneGameQuest] =
		useState(false)
	const [eligiblePCVRGameQuest, setEligiblePCVRGameQuest] = useState(false)

	const [viewMobileProfile, setViewMobileProfile] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersStandaloneRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsersStandalone = snapshot.val()
					if (eligibleUsersStandalone) {
						setEligibleStandaloneGameQuest(true) // User is eligible, hide the announcement
					} else {
						setEligibleStandaloneGameQuest(false) // User is not eligible, show the announcement
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligibleStandaloneGameQuest, eligibleUsersStandaloneRef, user])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersPCVRRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsersPCVR = snapshot.val()
					if (eligibleUsersPCVR) {
						setEligiblePCVRGameQuest(true) // User is eligible, hide the announcement
					} else {
						setEligiblePCVRGameQuest(false) // User is not eligible, show the announcement
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligiblePCVRGameQuest, eligibleUsersPCVRRef, user])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const handleResize = () => {
		// Periksa lebar jendela dan atur state sesuai kebutuhan
		if (window.innerWidth > 1024) {
			setMargin(false)
		} else {
			setMargin(true)
		}
	}

	const handleResize_Profile = () => {
		// Periksa lebar jendela dan atur state sesuai kebutuhan
		if (window.innerWidth > 1024) {
			setViewMobileProfile(false)
		} else {
			setViewMobileProfile(true)
		}
	}

	useEffect(() => {
		// Tambahkan event listener untuk mendengar perubahan ukuran jendela
		window.addEventListener('resize', handleResize)
		window.addEventListener('resize', handleResize_Profile)

		// Panggil handleResize untuk menetapkan nilai awal
		handleResize()
		handleResize_Profile()

		// Hapus event listener ketika komponen di-unmount
		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('resize', handleResize_Profile)
		}
	}, [])

	// const modifiedPhotoURL = user?.photoURL?.replace('s96-c', 's400-c');

	const handleSignOut = () => {
		signOut(auth)
		setUser(null)
	}

	const translations = {
		en: {
			account: 'Account',
			notSignedIn: 'You are not signed in yet. Please sign in first!',
			member: 'Member',
			guest: 'Guest',
			admin: 'Admin',
			accountSettings: 'Account Settings',
			back: 'Back',
			signedInGoogle: 'Signed In with Google',
			signedInFacebook: 'Signed In with Facebook',

			signIn: 'Sign In',
			signOut: 'Sign Out',

			displayName: 'Display Name',
			email: 'Email',
			UID: 'UID',
			lastLogin: 'Last Login',

			userInformation: 'User Information',
			security: 'Security',

			createPIN: 'Create PIN',
			changePIN: 'Change PIN',
			currentPIN: 'Current PIN',
			newPIN: 'New PIN',

			show: 'Show',
			hide: 'Hide',

			devMessage: 'THIS FEATURE STILL IN DEVELOPMENT!',
			permanentDataInfo: 'The data above cannot be changed!',

			profile: 'Profile',

			accessDownloadStandaloneGame: 'Standalone',

			confirmSignOut: 'Are you sure you want to sign out?',

			yes: 'Yes',
			no: 'No',

			pinCreated: 'PIN successfully created!',
			pinChanged: 'PIN successfully changed!',
			incorrectPreviousPIN: 'Previous PIN is incorrect!',
			sorry: 'Sorry...',

			createPINFirst:
				'Please create a PIN in the Security tab before using this feature.',

			accountPIN: 'Account PIN',

			downloadHistory: 'Download History',
			likedGames: 'Liked Games',
			accountInfo: 'Account Info',
		},
		id: {
			account: 'Akun',
			notSignedIn: 'Anda belum masuk. Silakan masuk terlebih dahulu!',
			member: 'Anggota',
			guest: 'Tamu',
			admin: 'Admin',
			accountSettings: 'Pengaturan Akun',
			back: 'Kembali',
			signedInGoogle: 'Sudah Masuk dengan Google',
			signedInFacebook: 'Sudah Masuk dengan Facebook',

			signIn: 'Masuk',
			signOut: 'Keluar',

			displayName: 'Nama Tampilan',
			email: 'Email',
			UID: 'UID',
			lastLogin: 'Terakhir Login',

			userInformation: 'Informasi Pengguna',
			security: 'Keamanan',

			createPIN: 'Buat PIN',
			changePIN: 'Ubah PIN',
			currentPIN: 'PIN Sekarang',
			newPIN: 'PIN Baru',

			show: 'Tampilkan',
			hide: 'Sembunyikan',

			devMessage: 'FITUR INI MASIH DALAM TAHAP PENGEMBANGAN!',
			permanentDataInfo: 'Informasi data di atas tidak dapat diubah!',

			profile: 'Profil',

			accessDownloadGame: 'Akses Download Game Quest',

			confirmSignOut: 'Apakah Anda yakin ingin keluar?',

			yes: 'Ya',
			no: 'Tidak',

			pinCreated: 'PIN berhasil dibuat!',
			pinChanged: 'PIN berhasil diubah!',
			incorrectPreviousPIN: 'PIN sebelumnya salah!',
			sorry: 'Maaf...',

			createPINFirst:
				'Silakan buat PIN di tab Keamanan terlebih dahulu sebelum menggunakan fitur ini.',

			accountPIN: 'PIN Akun',

			downloadHistory: 'Riwayat Unduhan',
			likedGames: 'Game Disukai',
			accountInfo: 'Informasi Akun',
		},
	}

	const handleCreatePin = () => {
		const cleanedDisplayName = cleanDisplayName(user.displayName)
		const userPinRef = ref(fireDb, `/usersLogin/${cleanedDisplayName}/pin`)
		set(userPinRef, pin)

		// Reset pin after creating
		setPin('')
		setHasPin(true)

		Swal.fire({
			icon: 'success',
			title: translations[language].pinCreated,
			showConfirmButton: false,
			timer: 1500,
		})
	}

	const handleChangePin = () => {
		// Check if the entered previous PIN is correct
		const cleanedDisplayName = cleanDisplayName(user.displayName)
		const userPinRef = ref(fireDb, `/usersLogin/${cleanedDisplayName}/pin`)
		get(userPinRef).then((snapshot) => {
			const currentPin = snapshot.val()
			if (currentPin === pin) {
				// If the previous PIN is correct, update with the new PIN
				set(userPinRef, newPin)

				// Reset pins after changing
				setPin('')
				setNewPin('')
				Swal.fire({
					icon: 'success',
					text: translations[language].pinChanged,
					showConfirmButton: false,
					timer: 1500,
				})
			} else {
				// Handle incorrect previous PIN
				Swal.fire({
					icon: 'error',
					text: translations[language].sorry,
					text: translations[language].incorrectPreviousPIN,
					showConfirmButton: false,
					timer: 1500,
				})
			}
		})
	}

	const handleTabUserInfo = () => {
		setTabUserInfoActive(true)
		setTabSecurityActive(false)
	}

	const handleAccountSettings = () => {
		setAccountSettingsActive(true)
		setShortUserInformationActive(false)
	}

	const handleShortUserInformation = () => {
		setAccountSettingsActive(false)
		setShortUserInformationActive(true)
	}

	const formatLastSignInTime = (lastSignInTime, language) => {
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour23: true,
			timeZoneName: 'short',
		}

		return new Intl.DateTimeFormat(language, options).format(
			new Date(lastSignInTime)
		)
	}

	const isMobile = window.innerWidth < 1200

	const [downloadHistory, setDownloadHistory] = useState([])
	const [likedGames, setLikedGames] = useState([])
	const [loadingImages, setLoadingImages] = useState(true)
	const imageListRef = ref(storage, 'coverGamesImages/')

	useEffect(() => {
		if (user && user.uid) {
			// Ambil data dari Firebase berdasarkan user.uid
			const historyRef = fireDb.ref(
				`usersData/downloadHistory/${user.uid}/standalone`
			)
			historyRef.on('value', async (snapshot) => {
				const historyData = snapshot.val()
				if (historyData) {
					const historyList = Object.values(historyData)

					// Ambil URL gambar dari Firebase Storage untuk setiap gameTitle
					const downloadHistoryWithImages = await Promise.all(
						historyList.map(async (gameTitle) => {
							try {
								// Dapatkan URL gambar dari Storage
								const response = await listAll(imageListRef)
								const gameImageName = `${gameTitle}`
								const userImage = response.items.find(
									(item) => item.name === gameImageName
								)
								const imageUrl = await getDownloadURL(userImage)
								return { gameTitle, imageUrl } // Kembalikan objek dengan title dan URL gambar
							} catch (error) {
								console.error('Error getting image URL: ', error)
								return { gameTitle, imageUrl: null } // Jika gagal, set imageUrl ke null
							}
						})
					)

					setDownloadHistory(downloadHistoryWithImages)
				}
			})

			// Bersihkan listener saat komponen di-unmount
			return () => historyRef.off()
		}
	}, [user, storage])

	const [showDownloadHistory, setShowDownloadHistory] = useState(false)
	const [showLikedGames, setShowLikedGames] = useState(false)
	const [showAccountInfo, setShowAccountInfo] = useState(true)

	const handleShowDownloadHistory = () => {
		setShowDownloadHistory(true)
		setShowLikedGames(false)
		setShowAccountInfo(false)
	}

	const handleShowLikedGames = () => {
		setShowLikedGames(true)
		setShowDownloadHistory(false)
		setShowAccountInfo(false)
	}

	const handleShowAccountInfo = () => {
		setShowAccountInfo(true)
		setShowDownloadHistory(false)
		setShowLikedGames(false)
	}

	return (
		<div
			className={`flex flex-row gap-4 pt-5 justify-center h-svh px-8 pb-5 w-full ${!isMobile && 'pt-[100px]'}`}
		>
			{/* DESKTOP */}
			{/* USER PROFILE SECTION*/}
			{user && viewMobileProfile === false && (
				<div className={`min-w-[370px] pb-4 flex-col gap-4 flex`}>
					<div className="w-full h-full flex flex-row">
						<div
							className={`flex flex-col w-full h-full rounded-2xl items-center justify-start py-8 px-6 backdrop-blur-md border`}
						>
							<div
								className="absolute -mt-8 h-24 rounded-t-2xl z-10 w-full bg-[#0086FB]"
								style={{
									background: `linear-gradient(to right, rgba(0, 134, 251, 0.80) 10%, rgba(0, 134, 251, 0.95) 100%, rgba(0, 134, 251, 0.95) 100%), url(${bgPage}) center/cover`,
								}}
							></div>
							{user ? (
								<img
									src={user.photoURL}
									alt="user-photo"
									className={`rounded-full z-20 mt-4`}
								/>
							) : (
								<FaUserCircle
									className={`text-9xl ${isDarkMode ? 'text-white' : 'text-black'}`}
								/>
							)}
							<span className="flex flex-col gap-1 items-center">
								<span
									className={`text-2xl font-bold mt-5 text-center ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									{user?.displayName}
								</span>
								{user ? (
									<span className={`text-gray-400`}>
										{translations[language].member}
									</span>
								) : (
									<span className={`text-gray-400 text-lg`}>
										{translations[language].guest}
									</span>
								)}
							</span>
							{user && (
								<>
									<hr className="w-full my-5" />
									<div className="flex flex-col gap-3 w-full">
										<div className="flex flex-row justify-between items-center w-full">
											<span
												className={`${isDarkMode ? 'text-white' : 'text-black'}`}
											>
												Game Standalone Quest
											</span>
											{eligibleStandaloneGameQuest ? (
												<FaCheckCircle
													className={`text-blue-500 text-2xl bg-white rounded-full`}
												/>
											) : (
												<FaTimes
													className={`text-red-600 text-2xl rounded-full`}
												/>
											)}
										</div>
										<div className="flex flex-row justify-between items-center w-full">
											<span
												className={`${isDarkMode ? 'text-white' : 'text-black'}`}
											>
												Game PCVR
											</span>
											{eligiblePCVRGameQuest ? (
												<FaCheckCircle
													className={`text-blue-500 text-2xl bg-white rounded-full`}
												/>
											) : (
												<FaTimes
													className={`text-red-600 text-2xl rounded-full`}
												/>
											)}
										</div>
									</div>
								</>
							)}
							<button
								onClick={handleShowAccountInfo}
								className="w-full px-4 py-3 flex flex-row justify-between items-center border-2 rounded-xl mt-4 hover:bg-[#0086FB] hover:text-white transition-all duration-300"
							>
								<div className="flex flex-row justify-start items-center gap-3">
									<FaUserCircle />
									<p>{translations[language].accountInfo}</p>
								</div>
								<FaArrowRight />
							</button>

							<button
								onClick={handleShowLikedGames}
								className="w-full px-4 py-3 flex flex-row justify-between items-center border-2 rounded-xl mt-4 hover:bg-[#0086FB] hover:text-white transition-all duration-300"
							>
								<div className="flex flex-row justify-start items-center gap-3">
									<FaHeart />
									<p>{translations[language].likedGames}</p>
								</div>
								<FaArrowRight />
							</button>

							<button
								onClick={handleShowDownloadHistory}
								className="w-full px-4 py-3 flex flex-row justify-between items-center border-2 rounded-xl mt-4 hover:bg-[#0086FB] hover:text-white transition-all duration-300"
							>
								<div className="flex flex-row justify-start items-center gap-3">
									<FaDownload />
									<p>{translations[language].downloadHistory}</p>
								</div>
								<FaArrowRight />
							</button>
						</div>
					</div>
					{user &&
						user.providerData &&
						user.providerData.map((provider) => (
							<div
								key={provider.providerId}
								className={`bg-white border-2 w-full rounded-xl p-3 flex items-center justify-center `}
							>
								<span className={`text-white`}>
									{provider.providerId === 'google.com' && !isDarkMode && (
										<span
											className={`${isDarkMode ? 'text-white' : 'text-black'} font-semibold w-full rounded-3xl`}
										>
											{translations[language].signedInGoogle}{' '}
											<FcGoogle className="inline-block ml-1 text-2xl" />
										</span>
									)}
									{provider.providerId === 'google.com' && isDarkMode && (
										<span
											className={`${isDarkMode ? 'text-white' : 'text-black'} font-semibold w-full rounded-3xl`}
										>
											{translations[language].signedInGoogle}{' '}
											<FaGoogle className="text-white inline-block ml-1 text-2xl" />
										</span>
									)}
									{provider.providerId === 'facebook.com' && (
										<span>{translations[language].signedInFacebook}</span>
									)}
								</span>
							</div>
						))}
				</div>
			)}

			{/* INFORMATION SECTION */}
			{viewMobileProfile === false &&
				showAccountInfo &&
				!showDownloadHistory &&
				!showLikedGames && (
					<div className={`w-full pb-4 flex-col flex`}>
						<div
							className={`flex flex-col h-full w-full rounded-2xl items-center justify-start p-7 ${isDarkMode ? 'bg-zinc-700' : 'backdrop-blur-md border'} text-red-500`}
						>
							{!user && (
								<h1 className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									{translations[language].notSignedIn}
								</h1>
							)}
							{user && tabUserInfoActive && (
								<div className="w-full h-full flex flex-col justify-between">
									<div
										className={`flex flex-col items-start gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										<div className="mb-2 flex flex-col w-full gap-1">
											<label htmlFor="displayName" className="font-semibold">
												{translations[language].displayName}:
											</label>
											<input
												type="text"
												id="displayName"
												value={user.displayName}
												disabled
												className={` rounded-xl p-2 pl-5 disabled:bg-gray-200 disabled:text-gray-500 bg-white text-black border w-full outline-none`}
											/>
										</div>
										<div className="mb-2 flex flex-col w-full gap-1">
											<label htmlFor="email" className="font-semibold">
												{translations[language].email}:
											</label>
											<input
												type="text"
												id="email"
												value={user.email}
												disabled
												className={` rounded-xl p-2 pl-5 disabled:bg-gray-200 disabled:text-gray-500 bg-white text-black border w-full outline-none`}
											/>
										</div>
										<div className="mb-2 flex flex-col w-full gap-1">
											<label htmlFor="lastLogin" className="font-semibold">
												{translations[language].lastLogin}:
											</label>
											<input
												type="text"
												id="lastLogin"
												value={formatLastSignInTime(
													user.metadata.lastSignInTime,
													language
												)}
												disabled
												className={` rounded-xl p-2 pl-5 disabled:bg-gray-200 disabled:text-gray-500 bg-white text-black border w-full outline-none`}
											/>
										</div>
									</div>
									<span
										className={`text-white text-sm py-2 px-3 bg-red-500 rounded-lg w-fit`}
									>
										<FaExclamation className="inline-block mr-1 text-lg" />
										{translations[language].permanentDataInfo}
									</span>
								</div>
							)}
						</div>
					</div>
				)}

			{viewMobileProfile === false &&
				!showAccountInfo &&
				!showDownloadHistory &&
				showLikedGames && (
					<div className={`w-full pb-4 flex-col flex`}>
						<h3 className="text-lg font-bold mb-4 w-full bg-[#0086FB] px-4 py-2 rounded-xl text-white text-center">
							{translations[language].likedGames}
						</h3>
						<div
							className={`flex flex-col h-full w-full rounded-2xl items-start justify-start p-7 border bg-white`}
						>
							{likedGames.length > 0 ? (
								<ul className="flex flex-wrap gap-3">
									{downloadHistory.map((item, index) => (
										<li
											key={index}
											className="flex items-start gap-2 flex-col justify-start p-4 rounded-2xl border w-fit"
										>
											<span>{item.gameTitle}</span>
											{item.imageUrl ? (
												<img
													src={item.imageUrl}
													alt={item.gameTitle}
													className="w-64 h-32 object-cover rounded-xl"
												/>
											) : (
												<div className="w-64 h-32 bg-gray-300 flex items-center justify-center text-center rounded-xl">
													No Image
												</div>
											)}
										</li>
									))}
								</ul>
							) : (
								<p>No games are liked yet.</p>
							)}
						</div>
					</div>
				)}

			{viewMobileProfile === false &&
				!showAccountInfo &&
				showDownloadHistory &&
				!showLikedGames && (
					<div className={`w-full pb-4 flex-col flex`}>
						<h3 className="text-lg font-bold mb-4 w-full bg-[#0086FB] px-4 py-2 rounded-xl text-white text-center">
							{translations[language].downloadHistory}
						</h3>
						<div
							className={`flex flex-col h-full w-full rounded-2xl items-start justify-start p-7 border bg-white`}
						>
							{downloadHistory.length > 0 ? (
								<ul className="flex flex-wrap gap-3">
									{downloadHistory.map((item, index) => (
										<li
											key={index}
											className="flex items-start gap-2 flex-col justify-start p-4 rounded-2xl border w-fit"
										>
											<span>{item.gameTitle}</span>
											{item.imageUrl ? (
												<img
													src={item.imageUrl}
													alt={item.gameTitle}
													className="w-64 h-32 object-cover rounded-xl"
												/>
											) : (
												<div className="w-64 h-32 bg-gray-300 flex items-center justify-center text-center rounded-xl">
													No Image
												</div>
											)}
										</li>
									))}
								</ul>
							) : (
								<p>No downloads yet.</p>
							)}
						</div>
					</div>
				)}

			{/* MOBILE */}
			{/* USER PROFILE SECTION*/}
			{viewMobileProfile && (
				<div
					className={`w-full pb-4 flex-col gap-4 ${shortUserInformationActive ? 'flex' : 'hidden'}`}
					style={{ minWidth: '370px' }}
				>
					<div className="w-full h-full flex flex-row">
						<div
							className={`flex flex-col w-full h-full rounded-2xl items-center justify-start p-10 ${isDarkMode ? 'bg-zinc-700' : 'backdrop-blur-md border'}`}
						>
							{user ? (
								<img
									src={user.photoURL}
									alt="user-photo"
									className={`rounded-full border-4 `}
								/>
							) : (
								<FaUserCircle
									className={`text-9xl ${isDarkMode ? 'text-white' : 'text-black'}`}
								/>
							)}
							<span className="flex flex-col gap-1 items-center">
								<span
									className={`text-2xl font-bold mt-5 ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									{user?.displayName}
								</span>
								{user ? (
									<span className={`text-gray-400`}>
										{translations[language].member}
									</span>
								) : (
									<span className={`text-gray-400 text-lg`}>
										{translations[language].guest}
									</span>
								)}
							</span>
							{user && (
								<>
									<hr className="w-full my-5" />
									<div className="flex flex-col gap-3 w-full">
										<div className="flex flex-row justify-between items-center w-full">
											<span
												className={`${isDarkMode ? 'text-white' : 'text-black'}`}
											>
												{translations[language].accessDownloadGame}
											</span>
											{eligibleGameQuest ? (
												<FaCheckCircle
													className={`text-blue-500 text-2xl bg-white rounded-full`}
												/>
											) : (
												<FaTimes
													className={`text-red-600 text-2xl rounded-full`}
												/>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
					{user && (
						<button
							className="bg-blue-500 hover:bg-blue-600 transition-all duration-300 text-white w-full rounded-xl py-3"
							onClick={handleAccountSettings}
						>
							<span>{translations[language].accountSettings}</span>
							<FaArrowRight className="inline-block ml-3 text-xl" />
						</button>
					)}
					{user && (
						<button onClick={handleSignOut}>
							<span
								className={`w-full rounded-xl p-3 flex items-center justify-center bg-red-500 hover:bg-red-600 transition-all duration-300`}
							>
								<span className={`${isDarkMode ? 'text-white' : 'text-white'}`}>
									{translations[language].signOut}
								</span>
								<FaSignOutAlt className="inline-block ml-3 text-2xl text-white" />
							</span>
						</button>
					)}
					{!user && (
						<button>
							<Link to="/signin">
								<span
									className={`w-full rounded-xl p-3 flex items-center justify-center bg-gradient-to-br from-blue-300 to-blue-600 hover:bg-blue-700 transition-all duration-300`}
								>
									<span
										className={`${isDarkMode ? 'text-white' : 'text-white'}`}
									>
										{translations[language].signIn}
									</span>
									<FaSignInAlt className="inline-block ml-3 text-2xl text-white" />
								</span>
							</Link>
						</button>
					)}
				</div>
			)}

			{/* INFORMATION SECTION */}
			{viewMobileProfile && (
				<div
					className={`w-full pb-4 flex-col ${accountSettingsActive ? 'flex' : 'hidden'}`}
				>
					<div className="flex flex-row w-full">
						<button
							onClick={handleTabUserInfo}
							className={` w-full text-center ${tabUserInfoActive ? `${isDarkMode ? 'bg-zinc-700 text-white' : 'bg-white text-black border-x border-t'} font-semibold ` : `${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-zinc-200 text-black'} text-zinc-600`} py-4 rounded-t-2xl transition-all duration-300`}
						>
							{translations[language].userInformation}
						</button>
						{/* <button onClick={handleTabSecurity} className={` w-full text-center ${tabSecurityActive ? `${isDarkMode ? 'bg-zinc-700 text-white' : 'bg-white text-black border-x border-t'} font-semibold ` : `${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-zinc-200 text-black'} text-zinc-600`} py-4 rounded-t-2xl transition-all duration-300`}>{translations[language].security}</button> */}
					</div>
					<div
						className={`flex flex-col h-full w-full rounded-b-2xl items-center justify-start p-10 ${isDarkMode ? 'bg-zinc-700' : 'backdrop-blur-md border-b border-x'}`}
					>
						{!user && (
							<h1 className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
								{translations[language].notSignedIn}
							</h1>
						)}
						{user && tabUserInfoActive && (
							<div className="w-full h-full flex flex-col justify-between">
								{/* <h1 className={`text-2xl font-bold mb-3 text-center ${isDarkMode ? 'text-white' : 'text-black'}`}>{translations[language].userInformation}</h1> */}
								<div
									className={`flex flex-col items-start gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<div className="mb-2 flex flex-col w-full gap-1">
										<label htmlFor="displayName" className="font-semibold">
											{translations[language].displayName}:
										</label>
										<input
											type="text"
											id="displayName"
											value={user.displayName}
											readOnly
											className={` rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none`}
										/>
									</div>
									<div className="mb-2 flex flex-col w-full gap-1">
										<label htmlFor="email" className="font-semibold">
											{translations[language].email}:
										</label>
										<input
											type="text"
											id="email"
											value={user.email}
											readOnly
											className={` rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none`}
										/>
									</div>
									<div className="mb-2 flex flex-col w-full gap-1">
										<label
											htmlFor="uid"
											className="flex items-center font-semibold"
										>
											UID:
										</label>
										<div className="flex flex-row">
											<input
												type={isPasswordVisible ? 'text' : 'password'}
												id="uid"
												value={user?.uid || ''}
												readOnly
												className={` rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none`}
											/>
											<button
												onClick={() => setIsPasswordVisible((prev) => !prev)}
												className="ml-2 p-2 rounded-xl bg-blue-500 text-white hover:bg-blue-700"
											>
												{isPasswordVisible
													? translations[language].hide
													: translations[language].show}
											</button>
										</div>
									</div>
									<div className="mb-2 flex flex-col w-full gap-1">
										<label htmlFor="lastLogin" className="font-semibold">
											{translations[language].lastLogin}:
										</label>
										<input
											type="text"
											id="lastLogin"
											value={formatLastSignInTime(
												user.metadata.lastSignInTime,
												language
											)}
											readOnly
											className={` rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none`}
										/>
									</div>
								</div>
								<span
									className={`text-white text-sm py-2 px-3 bg-red-500 rounded-lg w-fit`}
								>
									<FaExclamation className="inline-block mr-1 text-lg" />
									{translations[language].permanentDataInfo}
								</span>
							</div>
						)}

						{user && tabSecurityActive && (
							<div
								className={`${isDarkMode ? 'text-white' : 'text-black'} mb-2 flex-col w-full gap-1`}
							>
								<span className="text-red-500 font-bold mb-4">
									{translations[language].devMessage}
								</span>
								{hasPin ? (
									<>
										<label
											htmlFor="currentPin"
											className="flex items-center font-semibold w-full mt-4"
										>
											{translations[language].currentPIN}
											<input
												type={isPINVisible ? 'text' : 'password'}
												id="currentPin"
												value={pin}
												onChange={(e) => setPin(e.target.value)}
												className={`rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none ml-2`}
											/>
											<button
												onClick={() => setIsPINVisible((prev) => !prev)}
												className="ml-2 p-2 rounded-xl bg-blue-500 text-white hover:bg-blue-700"
											>
												{isPINVisible
													? translations[language].hide
													: translations[language].show}
											</button>
										</label>
										<label
											htmlFor="newPin"
											className="flex items-center font-semibold w-full mb-2"
										>
											{translations[language].newPIN}
											<input
												type={isPINVisible ? 'text' : 'password'}
												id="newPin"
												value={newPin}
												onChange={(e) => setNewPin(e.target.value)}
												className={`rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none ml-2`}
											/>
										</label>
										<button
											onClick={handleChangePin}
											className="w-full p-2 rounded-xl bg-blue-500 text-white hover:bg-blue-700"
										>
											{translations[language].changePIN}
										</button>
									</>
								) : (
									<>
										<label
											htmlFor="pin"
											className="flex items-center font-semibold mb-2 mt-4"
										>
											PIN:
											<input
												type={isPasswordVisible ? 'text' : 'password'}
												id="pin"
												value={pin}
												onChange={(e) => setPin(e.target.value)}
												className={`rounded-xl p-2 ${isDarkMode ? 'bg-zinc-800 text-white' : 'bg-white text-black border'} w-full outline-none ml-2`}
											/>
											<button
												onClick={() => setIsPasswordVisible((prev) => !prev)}
												className="ml-2 p-2 rounded-xl bg-blue-500 text-white hover:bg-blue-700"
											>
												{isPasswordVisible
													? translations[language].hide
													: translations[language].show}
											</button>
										</label>
										<button
											onClick={handleCreatePin}
											className="w-full p-2 rounded-xl bg-green-500 text-white hover:bg-green-700"
										>
											{translations[language].createPIN}
										</button>
									</>
								)}
							</div>
						)}
					</div>
					<button
						className="bg-red-500 hover:bg-red-600 transition-all duration-300 text-white w-full rounded-xl py-3 mt-3"
						onClick={handleShortUserInformation}
					>
						<FaArrowLeft className="inline-block mr-3 text-xl" />
						<span>{translations[language].back}</span>
					</button>
				</div>
			)}
			<Helmet>
				<title>
					{translations[language].profile} | {translations[language].account} -
					HyperTopia
				</title>
			</Helmet>
		</div>
	)
}

export default Profile
